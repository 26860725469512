<template>
    <div class="header">
        <div class="header__user-info">
            <img
                :src="user.avatar"
                :alt="user.username"
                :title="user.username"
                class="avatar" style="object-fit:cover"/>


            <div class="header__user-info--section">
                <span>{{user.username}}</span>
                <div class="header__user-info--section--details">
                    <!--<div class="details">
                        <i class="fa-solid fa-location-dot"></i>
                        {{user.location}}
                    </div>-->
                    <!--<div class="details">
                        <i class="fa-solid fa-link"></i>
                        {{user.website}}
                    </div>-->
                    <!--<div class="details">
                        <i class="fa-solid fa-envelope"></i>
                        {{user.email}}
                    </div>-->
                </div>
            </div>
        </div>
        <div class="header__title">
            <!--<h2 class="title">{{title}}</h2>-->
            <button v-if="addButton" @click="open" class="button"><i class="fa-solid fa-plus"></i> {{addButtonText}}</button>
        </div>
    </div>

</template>

<script>

export default {

    props: {
        title: String,
        showButton: Boolean,
        addButtonText: String
    },

    data() {
        return {
          addButton: this.showButton,

        }
    },
    watch:{

    },
    methods: {
        open() {
            this.$emit('open');
        },
    },

  computed: {
    user:function(){
      return state.user
    }
  },



};
</script>

<style lang="scss">
.header {
    color: $white;
    background: $dark-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 50px;

    &__title {
        font-weight: 600;
        display: flex;
        align-items: center;

        .title {
            padding-right: 10px;
            color: $white;
        }
    }

    &__user-info {
        display: flex;
        align-items: center;

        &--section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &--details {
                display: flex;
                padding-top: 5px;

                .details {
                    padding-right: 10px;
                }
            }

        }
        .avatar {
            width: 50px;
            height: 50px;
        }


    }

}

</style>
