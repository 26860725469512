// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

import Vue from "vue";
import {get, post} from "@/scripts/samsa"
import {State} from "@/scripts/state"

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

State.prototype.verifyAuthenticated = async function(context) {
  if(this.user != null){
    console.log("User", this.user)
    return this.user;
  }
  else{
    let token = localStorage.getItem("reconn-token");
    if(token !== null){
      let user_data = parseJwt(token)
      if(!Number.isInteger(user_data.user_id)){
        localStorage.removeItem("reconn-token");
        window.location = "/"
        return false;
      }
    
    let ret = null;
    if(token != null && token.length > 0){
      window.token = token
      console.log("TOKEN: ", token)
      ret = await post('verify_authenticated');

    }
    else {
      console.log("Token was null")
      this.user = null
      return;
    }
    if(ret.data!=null){
      window.token = token
      this.user = await this.get_user();
      
      return true;
    }
    else{
      console.log("not valid")
    }
  }
  return false;

  }
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      console.log(c.substring(name.length, c.length));
      return c.substring(name.length, c.length);
    } 
  }
  
}

function checkCookie() {
  let code = getCookie("code");
  if (code) {
    post("accept_invite", {}, {code: code});
    document.cookie = 'code =; path=/; max-age = -1;';
  } 
}

State.prototype.signIn = async function(email, password) {
  let ret = await post("sign_in",{},{username:email, password:password});

  if(!ret.data){
    return false;
  }else {
    if(ret.data=="not accepted")
      return "not accepted"
    let token = ret.data;
    console.log("Setting token:", token)
    window.token = token
    localStorage.setItem("reconn-token", token);
    let user_data = parseJwt(token);
    console.log("User Data:", user_data)
    this.user = user_data
    this.token = token
    checkCookie();
    return true;
  }

}

State.prototype.acceptTerms = async function(email, password) {
  let ret = await post("accept_terms",{},{username:email, password:password});

  if(!ret.data){
    return false;
  }else {
    let token = ret.data;
    window.token = token
    localStorage.setItem("reconn-token", token);
    let user_data = parseJwt(token);
    this.user = user_data
    this.token = token
    return true;
  }

}

State.prototype.sign_out = async function(email, password) {
  console.log("Sign Out!")
  localStorage.removeItem("reconn-token");
  state.user = null;
  // window.location = "./"
}

State.prototype.signUp = async function(email, username, password) {

  let data = {'email':email, 'username':username, 'password':password}
  console.log("Sign Up!", data)
  let ret = await post("sign_up",{},data);

  if(ret.data){
    // let token = ret.data;
    localStorage.setItem("reconn-token", ret.data.token);
    window.token = ret.data.token
    // let user_data = parseJwt(token);
    // console.log("User:", user_data)
    state.user = ret.data
    return true;
  }
  return false
}

State.prototype.email_reset_password = async function(email) {
  let ret = await post("send_email_reset", email);

  return ret.data;
}

State.prototype.reset_password = async function(reset_key, newPassword) {
  let ret = await post("reset_password",{reset_key:reset_key, newPassword:newPassword});

  return ret.data;
}

  //if(token != null)
  //  res = await $samsa.verify_authenticated(token);

  //if(res){
  //  let user = res;
  //  if(user != null){
  //    $data.$set($data,"user",user)
  //    localStorage.setItem("springmatter-token", user.token);
  //  }


//
// async function signIn(loginInfo) {
//   let res = await $samsa.sign_in(loginInfo.email, loginInfo.password)
//   if(res){
//     let user = res;
//     $data.$set($data,"user",user)
//     localStorage.setItem("springmatter-token", user.token);
//   }
//   else{
//     user = null
//     localStorage.setItem("springmatter-token", "");
//   }
//   return res;
// }
//
//
//
// function signOut(state) {
//   // Sign user out of the app; remove token from local storage
//   $data.$set($data,"user",null)
//   localStorage.setItem("springmatter-token","");
//
// }
//
// async function signUp(user) {
//     let res = await $samsa.create_user(user)
//     user = res;
//     console.log("UserToken:" , user.token)
//     localStorage.setItem("springmatter-token", user.token);
// }
//
// async function saveUser(user) {
//   let res = await $samsa.save_user(user)
//   if(res){
//     user = res;
//   }
// }
