<template>
  <div class="modal-backdrop">
    <div class="modal" :class="{'modal-big': isBig, 'modal-medium': isMedium, 'modal-xl': isXL, 'set-height': hasHeight}">
      <header class="modal--header">
      <slot name="header">

      </slot>

      </header>

      <section class="modal--body">
        <slot name="body">

        </slot>
       </section>

      <footer class="modal--footer">
        <slot name="footer">
        
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/modal.scss";
  export default {
    name: 'Modal',
    props: {
      isBig: false,
      isMedium: false,
      hasHeight: false,
      isXL: false
    },
    data() {
      return {
        
      }
    },
    methods: {
      
    },
  };
</script>

