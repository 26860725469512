<template>
  <div class="row">
    <div class="row__content">
      <div class="sign-in-box">
        <form @submit.prevent="sendEmail" method="POST" v-if="mode=='email'" class="sign-in-box--form">
          <h2 v-if="!passwordSuccess">
            Enter your email to reset your password:
          </h2>
          <p v-if="error" class="error">The passwords do not match.</p>
          <div v-if="passwordSuccess" class="">
            <p>A password reset email sent.</p>
            <router-link :to="{ name: 'SignIn'}" name="SignIn" class="link">Back to sign in page.</router-link>
          </div>

          <div class="input-container" v-if="!passwordSuccess">
            <label for="email" class="input-container--label">
              Email Address
            </label>
            
              <input
                id="email"
                type="email"
                v-model="email"
                required
                class="input-container--box"
              />
            
          </div>
          <button
            type="submit" 
            v-if="!passwordSuccess"
            class="button">
            Send Email
          </button>
        </form>
     
   
        <form v-else v-on:submit.prevent="newPassword" method="POST" class="sign-in-box--form">
          <h2 v-if="!passwordSuccess">
            Create a new password.
          </h2>
          <p v-if="error" class="error">The passwords do not match.</p>
          <div v-if="passwordSuccess" class="text-center">
            <p>Password change successful!</p>
            <router-link :to="{ name: 'SignIn'}" name="SignIn" class="link">Back to sign in page.</router-link>
          </div>

          <div class="input-container" v-if="!passwordSuccess">
            <label for="password" class="input-container--label">
              New Password
            </label>
            
               
                <!-- <div class="checkmark_container" v-bind:class="{ show_checkmark: valid_password }">		
                  <svg width="50%" height="50%" viewBox="0 0 140 100">
                    <path class="checkmark" v-bind:class="{ checked: valid_password }" d="M10,50 l25,40 l95,-70" />
                  </svg>
                </div> -->
                
              <input 
                type="password" 
                @input="checkPassword" 
                v-model="password" 
                autocomplete="off" 
                placeholder="Password" 
                class="input-container--box"/>
            
              <ul>
                <li v-bind:class="{ is_valid: contains_eight_characters }">8 Characters</li>
                <li v-bind:class="{ is_valid: contains_number }">Contains Number</li>
                <li v-bind:class="{ is_valid: contains_uppercase }">Contains Uppercase</li>
                <li v-bind:class="{ is_valid: contains_special_character }">Contains Special Character</li>
              </ul>
           
          </div>
          <div v-if="!passwordSuccess" class="input-container">
            <label for="password" class="input-container--label">
              Confirm New Password
            </label>
           
            <input
              id="passwordConfirm"
              type="password"
              v-model="passwordConfirm"
              required 
              class="input-container--box"/>
          </div>

          <div v-if="!passwordSuccess">
            <button
              type="submit"
              class="button">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import passwordMeter from "vue-simple-password-meter";

export default {

  components: { passwordMeter },

  props: {},

  data() {
    return {
      mode:"email",
      email: "",
      passwordConfirm: "",
      passwordSuccess: false,
      error: false,
      passwordValue: null,
      password: null,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      resetKey: ""
    };
  },

  watch: {},

  methods: {

    async sendEmail(){
      console.log(this.email)
      this.resetKey = await state.email_reset_password(this.email);
      console.log(this.resetKey);
      this.passwordSuccess = true;
      this.$router.push({
        name: 'PasswordReset',
        params: { reset_key: this.resetKey }
      });
    },
    async newPassword() {
      if(this.password == this.passwordConfirm) {
        console.log(this.$route.params.reset_key)
        let reset = await state.reset_password(this.$route.params.reset_key, this.password);
        console.log(reset);
        this.error = false;
        this.passwordSuccess = true;
      }else {
        this.error = true;
        this.passwordSuccess = false;
      }
    },
    checkPassword() {
      this.password_length = this.password.length;
			const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			
      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
			}
			
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
			this.contains_special_character = format.test(this.password);
      
      if (this.contains_eight_characters === true &&
					this.contains_special_character === true &&
					this.contains_uppercase === true &&
					this.contains_number === true) {
						this.valid_password = true;			
      } else {
        this.valid_password = false;
      }
    }
  },

  computed: {},

  mounted() {
    console.log("KEY:", this.$route.params.reset_key);
    if(!this.$route.params.reset_key){
      this.mode = "email";
    }else {
      console.log("Other Mode")
      this.mode = "password";
    }

  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/sign-in-up-reset.scss";

ul {
  font-size: .8rem !important;
	padding-left: 2px;
  margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

li { 
	margin-bottom: 5px;
	color: #525f7f;
	position: relative;
}

li:before {
  content: "";
	width: 0%; height: 2px;
	background: #2ecc71;
	position: absolute;
	left: 0; top: 50%;
	display: block;
	transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Checkmark & Strikethrough --------- */

.is_valid { color: rgba(136, 152, 170, 0.8); }
.is_valid:before { width: 100%; }

.checkmark_container {
	border-radius: 50%;
	position: absolute;
	top: -27px; right: 2px;
	background: #2ecc71;
	width: 20px; height: 20px;
	visibility: hidden;
	opacity: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity .4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked { animation: draw 0.5s ease forwards; }

@keyframes draw {
  to { stroke-dashoffset: 0; }
}
</style>
