<template>
  <div class="local"  @click="selectFiles" @drop="drop" @dragover="dragover">
    <input type="file" @change="filesChanged" style="display:none" :ref="el=>{upload_element=el}" multiple accept="image/*"/>
    <i class="fa-solid fa-file-circle-plus"></i>
    <h2 class="local--heading">
        Select Files to Upload
    </h2>
    <p>
        or Drag and Drop, Copy and Paste Files
    </p>


  </div>

</template>

<script>

export default {
  components: {

  },

  props: {
    collection_id:undefined
  },

  data() {
    return {
      upload_element:null

    };
  },

  watch: {},

  methods: {
    callback(){
      this.$router.push({
            name: 'Annotations',
            params: { collection_id:234 }

          });
    },
    async filesChanged(e){

      let val = await state.uploadFiles(e.target.files,this.collection_id)
      if(val){
        this.$emit("limit_hit");
      }
    },
    selectFiles(e){
      this.upload_element.click()
    },
    drop(e){
      e.preventDefault()
      e.stopPropagation()

      let dt = e.dataTransfer
      let files = dt.files
      console.log(files)
      state.uploadFiles(files,this.callback)
    },
    dragover(e){
      e.preventDefault()
      e.stopPropagation()
    }

  },

  computed: {

  },

};
</script>

<style lang="scss">
  .local {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-self: center;
    margin: 0;
    padding: 50px 10px;
    color: $grey;
    border: 1px dashed $grey;
    border-radius: 5px;
    @media only screen and (max-width: 767px) {
      width: 93%;
    }
    &--heading {
      color: $almost-black;
      padding-bottom: 10px;
      font-weight: 400;
      font-size: 1.26em;
    }

    i {
      font-size: 100px;
      color: $primary-color;
      padding-bottom: 10px;
    }
    p {
      font-size: 0.88em;
    }

    &:hover {
      cursor: pointer;
    }
  }
</style>
