<template>
  <div class="">
  {{code}}
  </div>

</template>

<script>

import { get, post } from "@/scripts/samsa"

export default {
  components: {

},

  props: {
    code:undefined
  },

  data() {
    return {

    }
  },

  watch: {},
  mounted(){
    document.cookie = "code = "+ this.code + "; path = /; max-age = 86400";
    if(window.token == null){
      
      this.$router.push({name:"SignIn"});
    }
    else{
      post("accept_invite",{},{code:this.code});
      
      this.$router.push({name:"Dashboard"});
    }
    
  },
  methods: {

  },

  computed: {

  },



};
</script>
