<template>
  
    <div class="alert-modal">

      <section class="alert-modal--body">
        <slot name="body">

        </slot>
       </section>
       <footer class="alert-modal--footer">
        <slot name="footer">
        
        </slot>
      </footer>
    </div>
  
</template>

<script>
  export default {
    name: 'ContextMenu',
    props: {
        
    },
    data() {
      return {
        
      }
    },
    methods: {
      
    },
  };
</script>

<style lang="scss">
.alert-modal {
    position: fixed;
    background: $off-white;
    border-radius: 5px;
    box-shadow: 0 0 10px $black;
    width: 250px;
    height: 200px;
    display: flex;
    flex-direction: column;
    top: 10px;
    left: 410px;
    z-index: 10;

    &::after {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      top: 75px;
      left: -12.5px;
      background-color: $off-white;
      transform: rotate(45deg);    
      box-shadow: 0 0 10px $black;
      
    }
   
    &--footer {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 80%;
        align-items: center;
        
        .btn-close {
          text-decoration: underline;
          padding: 10px;
          margin: 10px;
          color: $primary-color;
          font-weight: 500;
          transition: all .3s;
          @media only screen and (max-width: 610px) {
            font-size: 6.5vw;
          }
          &:hover {
              cursor: pointer;
              color: darken($primary-color, 10%);
              transition: all .3s;
          }
        }
    }

    &--body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 250px;
        position: relative;
        z-index: 20;
        background: $off-white;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        
      .alert-body {
        display: flex;
        flex-direction: column;
        padding: 15px;
        .red-alert {
          color: $error-red;
          font-size: 2rem;
          margin-bottom: 15px;
        }
      
      }
    }
}


</style>