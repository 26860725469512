<template>
  <div class="preview" :ref="el=>{element=el}" v-if="url!=undefined">
    <img  @load="loaded" @error="error" :src="url" :class="{ selected : url == selectedItem.url, not_visible:is_loaded, not_visible:is_error}" />
    <img style="object-fit: none;" v-if="is_loaded && !is_error" src="@/assets/images/loading-waiting.gif" />
    <div class="no-image" v-if="is_error">
      <i class="fa-solid fa-otter"></i>
      <p>No image available.</p>
    </div>
    <div class="preview__actions">
      <span class="preview__actions--title" :title="name">{{realIndex}}</span>
      <div class="preview__actions--buttons">
        <i class="fa-solid fa-tags" @click.stop="openTags(selectedItem)"></i>
        <i class="fa-solid fa-trash" @click.stop="removeImage(realIndex)"></i>
      </div>
    </div>
  </div>
</template>

<script>


import { ref, onMounted, getCurrentInstance } from 'vue'


export default {

  components: {},
  props: {
    view_max:{default: 0},
    view_min:{default: 0},
    url:{default: undefined},
    name:{default: undefined},
    selectedItem: Object,
    realIndex:{default: 0}

  },
  data() {
    return {
      element: null,
      is_loaded:true,
      is_error: false,
      y:0,
      visible: false,
    };
  },
  watch:{
    url:function(a,b){
      this.is_loaded = true
    },
    view_min:function(){
      var bounding = this.element.getBoundingClientRect();
      this.y = bounding.y;
      if(bounding.y >= 74 && bounding.y < this.view_max)
        this.visible = true;
      else
        this.visible = false;
    }
  },
  computed:{

  },
  methods:{
    error:function(){
      this.is_error = true;
    },
    loaded:function(){
      this.is_error = false;
      this.is_loaded = false;

    },
    removeImage: function(index) {
      this.$emit("delete_image", index);
    },
    openTags: function(image) {
      this.$emit("open_tags", image);
    }
  },
  async mounted() {
    if(this.element){
      var bounding = this.element.getBoundingClientRect();
      this.y = bounding.y;
      if(bounding.y >= 74 && bounding.y < this.view_max)
        this.visible = true;
      else
        this.visible = false;
    }
  },
};
</script>

<style lang="scss" >

.preview{
  background-color: $white;
  position: relative;
  margin: 10px 5px;
  padding: 5px;

  img {
    object-fit: cover;
    height: 200px;
    width: 350px;
    border-radius: 5px;
  }

  .no-image {
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $grey;
      font-size: .9rem;
      align-items: center;
      .fa-otter {
          font-size: 4rem;
      }
  }

  &__actions {
    display: flex;
    position: absolute;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    top: 5px;
    left: 5px;
    width: 100%;
    padding: 5px;
    color: $white;
    font-weight: 700;
    background: rgba(108, 114, 127, 0.8);

    &--title {
      width: 83%;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--buttons {
      display: flex;
      width: 15%;
      justify-content: space-around;
      .fa-trash {
        color: $error-red;
        &:hover {
          cursor: pointer;
          color: darken($error-red, 10%);
        }
      }

      .fa-tags {
        color: $white;
        &:hover {
          cursor: pointer;
          color: darken($white, 15%)
        }
      }

      .fa-check {
        color: $green;
      }
    }

  }

  .selected {
    box-shadow: 0 0 0 5px $primary-color;
    border-radius: 5px;
  }

  .not_visible{
    height: 0px;
    box-shadow: none !important;
  }

}

</style>
