<template>
  <Carousel :items-to-show="3" :wrap-around="true">

    <Slide v-for="project in projects" :key="project.name">
      <div @click="view(project)" class="carousel__item">
        <div class="carousel__item--title">
          <span class="title">{{ project.name }}</span>
          <div class="carousel__item--title--actions">
            <i @click.stop="delete_project('deleteProject', project)" class="fa-solid fa-trash"></i>
          </div>
        </div>
        <div class="carousel__item__content">
          <img v-if="project.url && !project.error" @error="error(project)" class="carousel__item__content--img" :src="project.url" />
          <div v-else class="no-image">
            <i class="fa-solid fa-otter"></i>
            <p>No image available.</p>
          </div>
        </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>

import { Carousel, Pagination, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';


export default {

    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    props: {
        projects: Array
    },
    data() {
      return {
          is_error: false
      }
    },
    methods: {
      error: function(project) {
        console.log("PROJECT", project)
        project.error = true;
        this.is_error = true;
      },
      view: function(project) {
        console.log(project)
        this.$router.push({
          name: 'ProjectDetails',
          params: { project_id:project.project_key }

        });
      },
      delete_project: function(type, project) {
        this.$emit('delete_project', type, project);
      }
    },
    mounted(){
        this.is_error = false;
    },
}

</script>

<style lang="scss">

.carousel {

    &__item {
        font-weight: 500;
        // height: 100%;
        min-height: 250px;
        width: 325px;
        margin: 10px;
        background-color: $white;
        color: $almost-black;
        border-radius: 8px;
        position: relative;

        &__content {
          height: 250px;
          width: 100%;
          border-radius: 5px;
          overflow: hidden;

          &--img {
            object-fit: cover;
            height: 250px;
            width: 350px;
            border-radius: 5px;
          }
          .no-image {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $grey;
            font-size: .9rem;

            .fa-otter {
              font-size: 4rem;
            }
          }
        }

        &--title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          background: rgba(108, 114, 127, 0.8);
          color: $white;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          width: 100%;
          padding: 5px;

          .title {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1.1rem;
          }
          &--actions {

            .fa-trash {
              color: $error-red;

              &:hover {
                cursor: pointer;
                color: darken($error-red, 15%);
                transition: all .3s;
              }
            }
          }
        }

        &:hover {
          cursor: pointer;
          background-color: darken($white, 15%);
          transition: all .3s;
        }

        &__slide {
            padding: 10px;
        }

    }

    .carousel__pagination-button {
        background-color: $primary-color;
    }

    .carousel__pagination-button--active {
        background-color: $dark-blue;
    }

    .carousel__next {
      background-color: $primary-color;
    }

    .carousel__prev {
      background-color: $primary-color;
    }

}

</style>
