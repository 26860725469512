<template>
    <div class="sidebar">
        <ul class="sidebar__list">
            <li
                v-for="(type, index) in types" :key="type.name"
                :class="['sidebar__list--item', type.selected ? 'selected' : '', type.enabled ? '' : 'disabled' ]"
                @click="onSelected(type, index);">

                <span v-html='type.icon' :class="[type.enabled ? '' : 'disabled' ]"></span>
                <span> {{type.name}}</span>
            </li>

        </ul>
    </div>

</template>

<script>

export default {

    props: {

    },

    data() {
        return {
            types: [
                {
                    name: "Local",
                    icon: '<i class="fa-solid fa-desktop icon"></i>',
                    selected: true,
                    enabled: true,
                },
                {
                    name: "Microsoft OneDrive",
                    icon: '<i class="fa-solid fa-cloud icon"></i>',
                    selected: false,
                    enabled: true,
                },
                {
                    name: "Internal Library",
                    icon: '<i class="fa-solid fa-server icon"></i>',
                    selected: false,
                    enabled: false,
                }
            ]
        }
    },
    methods: {
        onSelected(location, index) {
            if(location.enabled){
              this.$emit('onSelected', location.name);

              this.types = this.types.map((type, item) =>  {
                  console.log(item);

                  if(index === item) {
                      type.selected = !type.selected;

                  }else {
                      type.selected = false;
                  }
                  return type;
              });
          }

        }

    },

  computed: {

  },



};
</script>

<style lang="scss">
    .sidebar {
        width: 100%;
        height: 100%;
        text-align: left;

        &__list {
            height: 100%;
            &--item {
                padding: 7px 15px;
                margin: 3px 0;

                .disabled .icon {
                  color: #AFAFAF;
                }

                .icon {
                    width: 35px;
                    color: $primary-color;
                }

                &:hover {
                    cursor: pointer;
                }

            }
            .disabled {
              color: #AFAFAF;
            }

            .selected {
                background: $light-grey;
                border-radius: 5px;
            }
        }
    }
</style>
