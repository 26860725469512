<template>
  <div class="page">
    <nav-bar></nav-bar>
    <header-bar
        @open="showModal"
        :user="user"
        :title="'Organization Details'"
        :showButton="true"
        :addButtonText="'New Project'" />

        <div class="details--section" >
            <section class="card">
                <h3 class="details--title">{{selectedOrg.name}}</h3>
                <div class="card--row">
                    <ul class="details__list">
                        <li class="details__list--item row">
                            <div class="stats-section">
                                <i class="fa-solid fa-clipboard"></i>
                                <h1>{{selectedOrg.projects.length}}</h1>
                                <p>Projects</p>
                            </div>
                            <div class="stats-section">
                                <i class="fa-solid fa-images"></i>
                                <h1>{{countImages(selectedOrg.projects)}}</h1>
                                <p>Images</p>
                            </div>
                        </li>

                        <li class="details__list--item">
                            <i class="fa-solid fa-users"></i>
                            <ul class="member-list">
                                <li v-for="(member, index) in selectedOrg.members" :key="index">
                                    <router-link :to="{ name: 'Profile', params: {user: member.name} }">
                                        <img :src="member.avatar" :alt="member.name" class="member-list--avatar" />
                                    </router-link>
                                    <div class="member_name">{{member.name}}</div>
                                </li>
                        </ul>
                        <p>Members</p>
                        </li>
                    </ul>

                    <pie-chart :chartData="orgAnimalData(selectedOrg.projects)" ></pie-chart>
                </div>


            </section>
            <section class="card">
                <h3 class="details--title">Projects</h3>
                <div class="projects">
                    <universal-card
                        v-for="project in selectedOrg.projects"
                        :key="project.name"
                        :group="project"/>
                </div>
            </section>
        </div>

        <modal
            v-show="isModalVisible">
            <template v-slot:header>
                <h2>Create New Project</h2>
                <p>Lets get started by filling in the information about the new project.</p>
            </template>

            <template v-slot:body>
                <div class="input-container">
                    <label class="input-container--label">Name of Project</label>
                    <input class="input-container--box" type="text"/>
                    <p class="input-container--subtext">**The entered name is processed to match the required format.</p>
                </div>
                <div class="input-container">
                    <label class="input-container--label">Summary</label>
                    <textarea class="input-container--area"></textarea>
                </div>
            </template>
            <template v-slot:footer>
                <button
                    type="submit"
                    class="button"
                    @click="closeModal">
                    Create
                </button>
                <a @click="cancelModal" class="btn-close">Cancel</a>
            </template>
        </modal>

    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import HeaderBar from "@/components/HeaderBar.vue";
import Modal from '@/components/Modal.vue';
import UniversalCard from "@/components/UniversalCard.vue";
import FooterBar from "@/components/FooterBar.vue";
import PieChart from  "@/components/PieChart.vue";

import "@/assets/styles/details-pages.scss";

export default {

    components: {
        NavBar,
        HeaderBar,
        Modal,
        UniversalCard,
        FooterBar,
        PieChart
    },

    props: {
    },

    data() {
        return {
            user: {
                username: "User Name",
                email: "email@email.com",
                website: "http://www.mywebsite.com",
                orgs: ["Example Corp", "Company INC"],
                bio: "",
                avatar: "https://via.placeholder.com/100x100",
                location: "Ypsilanti, MI"
            },

            selectedOrg: {
                name: "Compnay Name One LLC",
                avatar: "https://via.placeholder.com/100x100",
                projects: [
                    {
                        name: "Project One",
                        date: "04/18/2022",
                        members: [
                            {
                                name: "john smith",
                                avatar: "https://via.placeholder.com/100x100"
                            },
                            {
                                name: "mery terry",
                                avatar: "https://via.placeholder.com/100x100"
                            },
                        ],
                        summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed consequat neque. Donec euismod luctus nisl, id porttitor quam finibus eget. Donec blandit dignissim ipsum pretium faucibus. Donec bibendum quis odio fringilla porta. Pellentesque a risus eu nisi tincidunt efficitur. Mauris efficitur sapien eget pharetra scelerisque. Phasellus ac orci ut mauris interdum bibendum ac sit amet felis. Integer vestibulum mattis ligula.",
                        collections: [
                            {
                                name: "Collection One",
                                date: "04/18/2022",
                                images: ["img.jpg", "img_img.jpg", "this_img.jpg"]
                            },
                            {
                                name: "Collection Two",
                                date: "04/18/2022",
                                images: ["img.jpg", "img_img.jpg", "this_img.jpg"]
                            },
                            {
                                name: "Collection Three",
                                date: "04/18/2022",
                                images: ["img.jpg", "img_img.jpg", "this_img.jpg"]
                            }
                        ],
                        stats: [
                            {
                                animal: "bears",
                                percent: "10.00"
                            },
                            {
                                animal: "white tail deer",
                                percent: "60.00"
                            },
                            {
                                animal: "rabbits",
                                percent: "10.00"
                            },
                            {
                                animal: "ducks",
                                percent: "5.00"
                            },
                            {
                                animal: "raccoons",
                                percent: "15.00"
                            }
                        ],
                    },
                    {
                        name: "Project Two",
                        date: "04/18/2022",
                        members: [
                            {
                                name: "john smikth",
                                avatar: "https://via.placeholder.com/100x100"
                            },
                            {
                                name: "joe shmoe",
                                avatar: "https://via.placeholder.com/100x100"
                            },
                        ],
                        summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed consequat neque. Donec euismod luctus nisl, id porttitor quam finibus eget. Donec blandit dignissim ipsum pretium faucibus. Donec bibendum quis odio fringilla porta. Pellentesque a risus eu nisi tincidunt efficitur. Mauris efficitur sapien eget pharetra scelerisque. Phasellus ac orci ut mauris interdum bibendum ac sit amet felis. Integer vestibulum mattis ligula.",
                        collections: [
                            {
                                name: "Collection One",
                                date: "04/18/2022",
                                images: ["img.jpg", "img_img.jpg", "this_img.jpg"]
                            },
                        ],
                        stats: [
                            {
                                animal: "bears",
                                percent: "10.00"
                            },
                            {
                                animal: "white tail deer",
                                percent: "60.00"
                            },
                            {
                                animal: "rabbits",
                                percent: "10.00"
                            },
                            {
                                animal: "ducks",
                                percent: "5.00"
                            },
                            {
                                animal: "raccoons",
                                percent: "15.00"
                            }
                        ],

                    },
                ],
                members: [
                    {
                        name: "john smkith",
                        avatar: "https://via.placeholder.com/100x100"
                    },
                    {
                        name: "joe shmoe",
                        avatar: "https://via.placeholder.com/100x100"
                    },
                    {
                        name: "mery terry",
                        avatar: "https://via.placeholder.com/100x100"
                    },
                ],
            },
            isModalVisible: false,

        }
    },

    watch: {},

    methods: {
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        cancelModal() {
            this.isModalVisible = false;
        },
        orgAnimalData(projects) {

            let newList = [{animal: "bears", percent: "10.00"},{animal: "white tail deer",percent: "60.00"}, {animal: "rabbits", percent: "10.00" }, { animal: "ducks", percent: "5.00"}, {animal: "raccoons", percent: "15.00"}]
            //math to get percentages of animals and return that list
            console.log(newList);
            return newList;
        },
        countImages(projects) {
            let images = 0;

                projects.forEach((project, index) => {
                    project.collections.forEach((collection, index) => {
                        images += collection.images.length;
                    });
                });

            return images;
        },

    },

    computed: {

    },

};

</script>

<style lang="scss">

</style>
