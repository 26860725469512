<template>
  <div class="row">
    <img class="row__image" src="@/assets/images/bobcat.jpg" alt="A majestic Bobcat.">
    <div class="row__content">
      <div class="sign-in-box">
        <h2>
          Sign up for <span class="blue-text"> RECONN.AI</span>
        </h2>
        <form action="#" method="POST" v-on:submit.prevent="signUp" class="sign-in-box--form">
          <div class="input-container">
            <label for="email" class="input-container--label">
              Email address
            </label>
            <input
              id="email"
              type="email"
              v-model="user.email"
              required
              class="input-container--box"
            />
          </div>

          <div class="input-container">
            <label for="username" class="input-container--label">
              Username
              <span v-if="!isValidUsername">
                — Username is unavailable
              </span>
              <span v-if="isValidUsername && user.username.length > 2">
                — Username is available
              </span>
            </label>

              <input
                id="username"
                type=""
                v-model="user.username"
                required
                minlength="3"
                class="input-container--box"
              />

          </div>

          <div class="input-container">
            <label for="password" class="input-container--label">
              Password
            </label>

                <!-- <div class="checkmark_container" v-bind:class="{ show_checkmark: valid_password }">
                  <svg width="50%" height="50%" viewBox="0 0 140 100">
                    <path class="checkmark" v-bind:class="{ checked: valid_password }" d="M10,50 l25,40 l95,-70" />
                  </svg>
                </div> -->

            <input class="input-container--box" type="password" @input="checkPassword" v-model="password" autocomplete="off" />

          </div>

          <div class="actions--row">
            <ul>
              <li v-bind:class="{ is_valid: contains_eight_characters }">8 Characters</li>
              <li v-bind:class="{ is_valid: contains_number }">Contains Number</li>
              <li v-bind:class="{ is_valid: contains_uppercase }">Contains Uppercase</li>
              <li v-bind:class="{ is_valid: contains_special_character }">Contains Special Character</li>
            </ul>
            <div class="remember">
              <input
                id="rememberMe"
                v-model="user.rememberMe"
                type="checkbox"
                class=""
              />
              <label for="remember_me" class="tiny-text">
                Remember me
              </label>
            </div>
          </div>
          <div class="actions">
            <p class="the-fine-print">
              By clicking sign-up you agree to our <router-link class="terms-link" :to="{path: '/terms-of-service' }" target="_blank">Terms of Service</router-link> and <router-link class="terms-link" :to="{path: '/privacy-policy' }" target="_blank">Privacy Policy</router-link>.
            </p>
          </div>
          <div class="actions--column">
            <button type="submit" class="button">
              Sign up
            </button>

            <router-link :to="{ name: 'SignIn' }" class="link">
              Or Sign In
            </router-link>

          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import passwordMeter from "vue-simple-password-meter";
import {get, post} from "@/scripts/samsa"
export default {
  components: { passwordMeter },

  props: [],

  data() {
    return {
      user: {
        email: "",
        username: "",
        password: "",
      },
      isValidUsername: true,
      passwordValue: null,
      password: null,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      termsAccepted: false
    };
  },

  watch: {
    "user.username":async function(new_val,old_val){
        if(new_val.length>3){
        let ret = await get("check_availability",{username:new_val})
        console.log(ret)
        this.isValidUsername=ret.data;
      }
      else{
        this.isValidUsername = false
      }
    }
  },

  methods: {
    async signUp() {
      if(this.valid_password) {
        if (this.isValidUsername) {
          // this.termsAccepted
          let ret  = await state.signUp(this.user.email, this.user.username, this.password);
          if(ret){
            this.$router.push({
              name: 'Dashboard',
            });
          }
        }
      }
    },
    checkPassword() {
      this.password_length = this.password.length;
			const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
			}

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
			this.contains_special_character = format.test(this.password);

      if (this.contains_eight_characters === true &&
					this.contains_special_character === true &&
					this.contains_uppercase === true &&
					this.contains_number === true) {
						this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    }
  },

  computed: {},

  mounted() {

    // Check, in realtime, whether username is available.
    // var validateUsername = debounce(() => {
    //   this.user.username = cleanName(this.user.username);
    //   this.$samsa.isUsernameValid(this.user.username).then((res) => {
    //     this.isValidUsername = res.data;
    //   });
    // }, 500);
    // const datasetName = document.querySelector("#username");
    // datasetName.addEventListener("keyup", validateUsername);
  },
  unmounted(){

  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sign-in-up-reset.scss";


ul {
  font-size: .8rem !important;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

li {
	margin-bottom: 5px;
	color: $grey;
	position: relative;
}

li:before {
  content: "";
	width: 0%; height: 2px;
	background: #2ecc71;
	position: absolute;
	left: 0;
  top: 50%;
	display: block;
	transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Checkmark & Strikethrough --------- */

.is_valid { color: rgba(136, 152, 170, 0.8); }
.is_valid:before { width: 100%; }

.checkmark_container {
	border-radius: 50%;
	position: absolute;
	top: -27px;
  right: 2px;
	background: #2ecc71;
	width: 20px;
  height: 20px;
	visibility: hidden;
	opacity: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transition: opacity .4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked { animation: draw 0.5s ease forwards; }

@keyframes draw {
  to { stroke-dashoffset: 0; }
}
</style>
