<template>
    <div>
        <div class="item"
            :style="{'margin-left': `${depth * 20}px`} ">
            <input class="checkbox" type="checkbox" v-model="selected"/>
            <i @click="toggle" :class="['folder', 'fa-solid', expanded ? 'fa-folder-open' : 'fa-folder' ]"></i>
            <p @click="toggle" >
                {{folders.name}}
            </p>
            <i @click="toggle" :class="['icon', 'fa-solid', expanded ? 'fa-caret-up' : 'fa-caret-right' ]"></i>

        </div>
        <file-tree
            v-if="expanded"
            v-for="folder in folders.children"
            :key="folder.name"
            :folders="folder"
            :depth="depth + 1"
        />
    </div>

</template>

<script>

export default {
    name: 'FileTree',

    props: {
        folders: Object,
        depth: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
          selected:this.folders.selected
        }
    },
    watch:{
      selected(){this.folders.selected = this.selected}
    },
    methods: {
        async toggle(){
          if(!this.folders.expanded){
            this.folders.children = await state.oneDrive.getFolders(this.folders.path)
          }

          this.folders.expanded = !this.folders.expanded; console.log(this.folders)

        }
    },

  computed: {
    expanded(){return this.folders.expanded}
  },



};
</script>

<style lang="scss">


    .item {
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        &:hover {
            cursor: pointer;
        }

        .icon {
            padding-left: 5px;
        }

        .folder {
            padding-right: 5px;
            color: #3b82f6;
        }

        .checkbox {
            margin-right: 10px;
        }

    }




</style>
