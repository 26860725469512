<template>
    <div class="page">
        <nav-bar></nav-bar>

        <main class="diagnostics">
            <section class="diagnostics__layout">
                <h2 class="diagnostics__layout__header">Predictions on New Species</h2>
                <p class="diagnostics__layout__info">
                    Thank you for your interest in RECONN.ai, an all-in-one platform driven by machine learning technology to help you find and classify wildlife in your camera trap imagery. 
                </p>
                <p class="diagnostics__layout__info">
                    We have a library of common US Midwest species contained within our database currently that continues to grow, and like all machine learning applications, ours expands and learns based on user annotations, 
                    and what it’s taught. As such, if you upload images containing an animal that it’s never seen before, don’t be surprised by the initial predictions.  
                    To provide you with a better idea of what you can expect to see, you can find a list of all species in our RECONN catalogue down below.
                </p>  
                <p class="diagnostics__layout__info">   
                    Don’t be dissuaded! The time it takes to train our algorithm on a new species is one of its strengths.  
                    After as few as a couple hundred user annotations the algorithm can be re-trained, providing you with the level of accuracy needed on remaining image uploads, this time, with the power of RECONN.ai harnessed and ready to use.
                </p>   
                <p class="diagnostics__layout__info">
                    If you have any additional questions, please don’t hesitate to reach out to <a href="mailto:info@reconn.ai">info@reconn.ai</a>.
                </p>      

            </section>
            <section class="diagnostics__layout">
                <h2 class="diagnostics__layout__title">
                    Diagnostics 
                    <span class="special-note">** performance based on limited data taken from camera traps in the mid-west united states</span>
                </h2>
                <div class="diagnostics__layout__header">
                    <h3 class="columns">Species Name</h3>
                    <h3 class="columns">Accuracy %</h3>
                    <h3 class="columns">Precision %</h3>
                    <h3 class="columns">Recall %</h3>
                    <h3 class="columns"># of Images</h3>
                    <h3 class="columns">More Info</h3>
                </div>
                <div class="diagnostics__layout__section" v-for="creature in species" :key="creature.name">
                    <div class="diagnostics__layout__section--row">
                        <span class="columns">{{ creature.name }}</span>
                        <span class="columns">{{ creature.accuracy }}</span>
                        <span class="columns">{{ creature.precision }}</span>
                        <span class="columns">{{ creature.recall }}</span>
                        <span class="columns">{{ creature.image_ct }}</span>
                        <span class="columns" @click="dropdown(creature)">
                            <i v-if="!creature.isOpen" class="fa-solid fa-caret-down"></i>
                            <i v-if="creature.isOpen" class="fa-solid fa-caret-up"></i></span>
                    </div>
                    <transition name="slide-fade">
                        <div class="diagnostics__layout__section--dropdown" v-if="creature.isOpen">
                            <span class="confusion--title">The {{ creature.name }} is sometimes confused with the
                                following species: </span>
                            <ul class="confusion__list">
                                <li v-for="animal in creature.confusion_list" :key="animal.name" class="confusion__list--item">
                                    <span>{{ animal.name }}</span><span>{{ animal.percentage }}</span>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
            </section>

        </main>
        <footer-bar></footer-bar>
    </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from '@/components/FooterBar.vue';
import { get, post } from "@/scripts/samsa"
import "@/assets/styles/diagnostics.scss";

export default {

    components: {
        NavBar,
        FooterBar,
    },

    props: {
    },

    data() {
        return {
            species: [
                {
                    name: "black bear",
                    image_ct: "200",
                    accuracy: "80%",
                    precision: "85%",
                    recall: "45%",
                    confusion_list: [
                        {
                            name: "brown bear",
                            percentage: "25%"
                        }
                    ],
                    isOpen: false
                },
                {
                    name: "sandhill crane",
                    image_ct: "50",
                    accuracy: "60%",
                    precision: "55%",
                    recall: "45%",
                    confusion_list: [
                        {
                            name: "flamingo",
                            percentage: "70%",

                        },
                        {
                            name: "grus",
                            percentage: "85%",

                        }
                    ],
                    isOpen: false
                }
            ],

        }
    },

    watch: {},

    async created() {
        // samsa call to API
        let performance_stats = await get("performance");
        this.species = performance_stats.data;
        console.log("this.species: ", this.species);
    },

    methods: {
        dropdown(species) {
            species.isOpen = !species.isOpen;
        }

    },

    computed: {

    },

};

</script>


