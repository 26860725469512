<template>
  <div class="page">
    <nav-bar></nav-bar>
    <div class="page--content">
        <input type="file" accept="image/*"  @change="filesChanged" style="display:none" :ref="el=>{upload_element=el}"/>
        <div class="card">
            <self-profile
                v-if="isSelf"
                :user="userData"
                @showModal="showModal"
                @newUserData="updateUser"/>
            <public-profile
                v-else
                :user="userData" />
        </div>
    </div>
    <modal
        v-if="isModalVisible == 'uploadPicture'">
        <template v-slot:header>
            <h2>Update Your Profile Picture</h2>
            <p>Upload a new image for your profile picture. (dimension limits?)</p>
        </template>

        <template v-slot:body>
            <div>
                <i class="fa-solid fa-file-circle-plus upload-icon"></i>
                <h2>Select a Profile Picture</h2>
                <!-- <p>
                    or Drag and Drop
                </p> -->
            </div>
        </template>
        <template v-slot:footer>
            <button
                type="submit"
                class="button"
                @click="closeModal">
                Submit
            </button>
            <a @click="cancelModal" class="btn-close">Cancel</a>
        </template>
    </modal>
    <modal
        v-if="isModalVisible == 'updateSuccess'">
        <template v-slot:header>
            <h2>Success!</h2>
        </template>

        <template v-slot:body>
            <div>
                <p>
                    Your profile is successfully updated!
                </p>
            </div>
        </template>
        <template v-slot:footer>
            <a @click="closeModal" class="btn-close">Close</a>
        </template>
    </modal>

    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import Modal from "@/components/Modal.vue";
import PublicProfile from "@/components/user-profile-public.vue";
import SelfProfile from "@/components/user-profile.vue";
import "@/assets/styles/user-profile.scss";

export default {

    components: {
        NavBar,
        FooterBar,
        Modal,
        PublicProfile,
        SelfProfile
    },

    props: {
    },

    data() {
        return {
            isModalVisible: null,
            isSelf: false,
            userData: {},
            upload_element:null
        }
    },

    watch: {},

    methods: {
      async filesChanged(e){
          state.uploadFile(e.target.files,this.userData)

        },
        showModal(modalType) {
          if(modalType=='uploadPicture'){
            this.upload_element.click()

          }
          else
            this.isModalVisible = modalType;
        },
        closeModal() {
            this.isModalVisible = null;
        },
        cancelModal() {
            this.isModalVisible = null;
        },
        async getUserData() {
            console.log("GET USER DATA")
            let get_user = await state.get_user();
            this.userData = get_user;
            console.log(get_user);
        },
        async getOtherUserData(userName) {

            let get_user = await state.get_user(userName);
            this.userData = get_user;
            console.log(get_user);
        },
        async updateUser(userData) {
            console.log(userData);
            let update_user = await state.update_user(userData, this.user.user_id)
            if (update_user) {

                this.showModal("updateSuccess")
            }
        }
    },

    computed: {
        user: function(){
        return state.user
        },
    },
    unmounted(){
      console.log("PAGE WAS UNMOUNTED")
    },
    updated(){
      
    },
    mounted() {
      console.log("USER: ",this.$route.params.user)
        if(this.$route.params.user == "") {
            this.isSelf = true;
            this.getUserData();
        }else {
            this.isSelf = false;
            this.getOtherUserData(this.$route.params.user)
        }
    }
};

</script>

<style lang="scss">

</style>
