<template>
  <div class="onedrive" @click="change">
    <file-tree
      :folders="root"
    />
    <div class="button-box">
      <button
        type=""
        class="button">
        Upload
      </button>
    </div>
  </div>
</template>

<script>
// import Component from "../component_location"

import FileTree from "@/components/FileTree.vue";

function getSelected(path){
  let ret = []
  if(path.selected)
    ret.push(path.path)
  for(let idx in path.children)
    ret = [...ret, ...getSelected(path.children[idx])]
  return ret
}


export default {
  components: {
    FileTree
  },

  props: {

  },

  data() {
    return {
        folderToUpload: {},
        root: {}
    };
  },

  watch: {},
  async mounted(){
    // state.oneDrive.signIn();
    // this.root = {name:"root",path:"root", expanded:true, selected:false, children:await state.oneDrive.getFolders()};
    // console.log("this.root!:", this.root)

  },
  methods: {
    change(){
      let ret = getSelected(this.root)
      // state.oneDrive.upload(ret)
      console.log(ret)
    }

  },

  computed: {

  },

};
</script>

<style lang="scss">
  
  .onedrive {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 10px;
    .button{
      font-weight: 500;
      
    }
    .button-box {
      text-align: left;
      margin-left: 8px;
    }

  }
</style>
