<template>
  <div class="row">
    <div class="row__content">
      <div class="sign-in-box">
        <img class="logo" src="@/assets/images/logo-full.png" alt="Reconn logo.">
        <form v-on:submit.prevent="signIn" method="POST" class="sign-in-box--form">

          <div v-if="error" class="error">
            <p >The credentials you entered are incorrect.</p>
            <p>Please try again.</p>
          </div>

          <div class="input-container">
            <label for="email" class="input-container--label">
              Username
            </label>

              <input
                id="email"
                type="string"
                v-model="email"
                required
                class="input-container--box"
              />

          </div>

          <div class="input-container">
            <label for="password" class="input-container--label">
              Password
            </label>

            <input
              id="password"
              type="password"
              v-model="password"
              required
              class="input-container--box"
            />
          </div>

          <div class="actions--row">
            <div class="remember">
              <input
                id="remember_me"
                type="checkbox"
                class="" />
              <label for="remember_me" class="tiny-text">
                Remember me
              </label>
            </div>

              <router-link
                :to="{ name: 'PasswordReset', params: {reset_key: null}}"
                class="tiny-text">
                  Forgot your password?
              </router-link>

          </div>

          <div class="actions--column">
            <button
              type="submit"
              class="button">
              Sign in
            </button>

            <router-link :to="{ name: 'SignUp' }" class="link">
              Or Sign Up
            </router-link>

          </div>
        </form>
      </div>
    </div>
    <img class="row__image" src="@/assets/images/bobcat.jpg" alt="A majestic Bobcat.">
    <info-alert v-if="showAlert" :bottom="null" :right="null" :top="'45%'" :left="'30%'">
      <template v-slot:title>
        Terms and Privacy Agreement
      </template>
      <template v-slot:info>
        By clicking "Accept" you agree to our <router-link class="terms-link" :to="{path: '/terms-of-service' }" target="_blank">Terms of Service</router-link> and <router-link class="terms-link" :to="{path: '/privacy-policy' }" target="_blank">Privacy Policy</router-link>.
      </template>
      <template v-slot:actions>
        <button class="button" @click="accept">accept</button>
      </template>
    </info-alert>
  </div>
</template>

<script>
import InfoAlert from "@/components/InfoAlert.vue";
import {get, post} from "@/scripts/samsa"

export default {
  components: {
    InfoAlert
  },

  props: [],

  data() {
    return {
      email: "",
      password: "",
      error: false,
      showAlert: false,
      termsAccepted: false
    };
  },

  watch: {},

  methods: {

    async signIn() {
      let successful = await state.signIn(this.email, this.password);
      console.log("SUCCESSFUL?", successful)
      if(successful == "not accepted"){
        this.showAlert = true;
      }
      else if(successful == true){
          this.error = false;
          this.termsAccepted = true;
          this.showAlert = false;
          this.$router.push("/dashboard");
        } else {
          this.error = true;
        }

    },
    async accept() {
      let successful = await state.acceptTerms(this.email, this.password);
      if(successful == true){
          this.error = false;
          this.termsAccepted = true;
          this.showAlert = false;
          this.$router.push("/dashboard");
        } else {
          this.error = true;
          this.showAlert = false;
        }

    },
      async termsPopup() {

    }

  },

  computed: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sign-in-up-reset.scss";



</style>
