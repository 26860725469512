<template>
    <div class="files">
        <h3 class="files--heading queued">Queued Files</h3>
        <div class="file_container">
          <div class="files__data" v-for="image in images" :key="image.name">
              <div class="files__data--info">

                  <div class="files__data--info--details">
                      <p class="file-name">{{image.name}}</p>
                      <span class="file-size" v-if="image.size>0">{{image.progress.toFixed(1)}} / {{image.size.toFixed(1)}} MB</span>
                  </div>
                  <div class="icon">
                      <i class="fa-solid fa-xmark"></i>
                  </div>
              </div>
              <div class="files__data--progress">
                  <div class="files__data--progress--bar" :style="{'width' : `calc(${(image.progress / image.size).toFixed(2) * 100}% - 10px)`}"></div>
              </div>
          </div>
        </div>
    </div>

</template>

<script>


export default {

    props: {

    },

    data() {
        return {
            _images: [
                // {
                //     name: "image-one.jpeg",
                //     size: 6,
                //     thumbnail: 'https://via.placeholder.com/75x50',
                //     progress: 6
                // },
                // {
                //     name: "image-two.jpeg",
                //     size: 8.5,
                //     thumbnail: 'https://via.placeholder.com/75x50',
                //     progress: 2.8
                // },
                // {
                //     name: "image-three.jpeg",
                //     size: 8.3,
                //     thumbnail: 'https://via.placeholder.com/75x50',
                //     progress: 4.3
                // },
                // {
                //     name: "image-four.jpeg",
                //     size: 5.4,
                //     thumbnail: 'https://via.placeholder.com/75x50',
                //     progress: 0.6
                // },
                // {
                //     name: "image-five.jpeg",
                //     size: 6.2,
                //     thumbnail: 'https://via.placeholder.com/75x50',
                //     progress: 5.5
                // }
            ]
        }
    },
    methods: {


    },

  computed: {
    images(){
      return state.images;
    },
    files(){

      let len = 0;
      for(var name in state.files){
        len+=1
        console.log("!!!", name)
      }
      console.log("Returning ", len)
      return len;

    }
  },



};
</script>

<style lang="scss" >

    .files {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .file_container{
            height: 475px;
            width: 100%;
            overflow-y: scroll;
            padding-left: 20px;
            padding-right: 20px;
        }

        &--heading {
            text-align: left;
            width: 100%;
            color: $dark-blue;
            font-size: 1.1rem;
            font-weight: 400;
            border-bottom: 1px solid $light-grey;
            padding-bottom: 7px;
            margin-bottom: 10px;
            @media only screen and (max-width: 767px) {
                border-bottom: none;
            }
        }

        &__data {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 5px;
            background: $white;
            margin: 5px 0;

            &--info {
                display: flex;
                width: 100%;
                justify-content: space-between;

                &--details {
                    text-align: left;
                    width: calc(100% - 150px);
                    padding: 0 5px;

                    .file-name {
                        padding-bottom: 5px;
                    }

                    .file-size {
                        color: $light-grey;
                    }
                }

                .icon {
                    font-size: 25px;
                    align-self: center;
                    width: 75px;
                    border-left: 1px solid $light-grey;
                    color: $light-grey;
                }
            }

            &--progress {
                height: 5px;
                width: 100%;
                padding: 5px;
                &--bar {
                    background: $primary-color;
                    height: 100%;
                }
            }
        }
    }
</style>
