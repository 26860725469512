<template>

    <section class="profile">

        <form @submit.prevent="submitUserInfo()" method="POST" class="profile__form">
            <div class="profile--heading">
                <section class="profile__form__container">
                    <img v-if="user.avatar" :src="user.avatar"
                        :alt="user.username"
                        :title="user.username"
                        @error="error"
                        class="profile__form__container--img" style="object-fit:cover"/>
                    <img v-else src="@/assets/images/no_image.png"
                      alt="user name"
                        class="profile__form__container--img" style="object-fit:cover"
                      />
                    <div class="button profile__form__container--edit" @click="showModal">
                        <i class="fa-solid fa-pen"></i> Edit
                    </div>
                </section>
                <section class="profile__form__contact">
                    <div class="input-container">
                        <label class="input-container--label"><i class="fa-solid fa-user"></i> Username</label>
                        <input type="text" class="input-container--box" readonly v-model="user.username"/>
                    </div>
                    <div class="input-container">
                        <label class="input-container--label"><i class="fa-solid fa-envelope"></i> Email</label>
                        <input type="email" class="input-container--box" readonly v-model="user.email"/>
                    </div>
                    <div class="input-container">
                        <label class="input-container--label"><i class="fa-solid fa-phone"></i> Phone</label>
                        <input type="phone" class="input-container--box" v-model="user.phone"/>
                    </div>
                </section>
            </div>
            <div class="profile--body">
                <section class="profile--body__section">
                    <div class="input-container">
                        <label class="input-container--label"> <i class="fa-solid fa-location-dot"></i> Location</label>
                        <input type="text" class="input-container--box" v-model="user.location" />
                    </div>
                    <div class="input-container">
                        <label class="input-container--label"><i class="fa-solid fa-link"></i> Website</label>
                        <input type="text" class="input-container--box" v-model="user.website"/>
                    </div>
                </section>
                <section class="profile--body__section">
                    <div class="input-container">
                        <label class="input-container--label"><i class="fa-solid fa-briefcase"></i> Employer</label>
                        <input type="text" class="input-container--box" v-model="user.employer" />
                    </div>
                    <div class="input-container">
                        <label class="input-container--label"><i class="fa-solid fa-star"></i> Interests (studies, species etc..)</label>
                        <input type="text" class="input-container--box" v-model="user.interests"/>
                    </div>
                </section>
            </div>
            <div class="profile--body-single">
                <div class="input-container">
                    <label class="input-container--label"><i class="fa-solid fa-file-pen"></i> Bio</label>
                    <textarea type="text" class="input-container--area" v-model="user.bio"></textarea>
                </div>
            </div>
            <button class="button button-align" type="submit">Submit</button>
        </form>
    </section>

</template>

<script>

export default {

    components: {

    },

    props: {
        user: Object
    },

    data() {
        return {


        }
    },

    watch: {},

    methods: {
        error(e){
          return;
          console.log("Error loading Image")
          let self = this;
          window.setTimeout(function(){
            console.log("Try Again!")
            self.user.avatar = self.user.avatar+"#";
          },1000)
        },
        showModal() {
            this.$emit("showModal", "uploadPicture");
        },
        submitUserInfo() {
            this.$emit("newUserData", this.user);

        }

    },

    computed: {

    },

};

</script>

<style lang="scss">

</style>
