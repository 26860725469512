<template>
    <div class="footer">
      <div class="footer__section">
        <ul class="footer__section--list">
          <li class="footer__section--list--item">
            <a class="link" href="mailto:info@reconn.ai">info@reconn.ai</a>
          </li>
          <li class="footer__section--list--item">
            <p>&copy;2000-2022 Michigan Aerospace Corporation</p>
          </li>
          <li class="footer__section--list--item">
            <p>All rights reserved</p>
          </li>
        </ul>
      </div>
      <div class="footer__section">
        <ul class="footer__section--list">
            <li class="footer__section--list--item">
              <router-link class="link" :to="{path: '/terms-of-service' }" target="_blank">Terms of Service</router-link>
            </li>
            <li class="footer__section--list--item">
              <router-link class="link" :to="{path: '/privacy-policy' }" target="_blank">Privacy Policy</router-link>
            </li>
            <li class="footer__section--list--item">
              <router-link class="link" :to="{path: '/saas' }" target="_blank">Software as a Service </router-link>
            </li>
          </ul>
      </div>
      <div class="footer__section">
        <ul class="footer__section--list">
          <li class="footer__section--list--item">
            <router-link class="link" :to="{path: '/release-notes' }" target="_blank">Release Notes</router-link>
          </li>
          <li class="footer__section--list--item">
            <router-link class="link" :to="{path: '/diagnostics' }" target="_blank">Diagnostics</router-link>
          </li>
          <li class="footer__section--list--item">
            <span class="link" @click="open_help">Help</span>
          </li>
          <li class="footer__section--list--item">
            <router-link class="link" :to="{path: '/feedback' }" target="_blank">Feedback</router-link>
          </li>
        </ul>
      </div>
      <modal
        v-if="isModalVisible == 'helpModal'"
        :isBig="true">
        <template v-slot:header >
            <h2>Help & Quick Guide</h2>
        </template>

        <template v-slot:body>
          <help-content class="help-modal">
            
          </help-content>
        </template>

        <template v-slot:footer>
            <a @click="closeModal" @key.enter="closeModal" class="btn-close">Close</a>
        </template>
      </modal>
    </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import HelpContent from "@/components/HelpContent.vue";

export default {

    components: {
      Modal,
      HelpContent
    },
    props: {
        
    },
    data() {
      return {
        isModalVisible: "",
      }
    },
    methods:{
      open_help() {
        this.isModalVisible = "helpModal";
      },
      
      closeModal(evt) {
        evt.preventDefault();
        this.isModalVisible = "";
      },
    }
}

</script>


<style lang="scss">

.footer {
  background: $almost-black;
  color: $white;
  font-size: 0.87em;
  padding: 1.75em 2.67em;
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-weight: 600;

  &__section {
    &--list {
      &--item {
        margin-bottom: 10px;

        .link {
          text-decoration: none;
          color: $white;

          &:hover {
            color: $light-grey;
            cursor: pointer;
            transition: all .2s;
          }
        }
      }
      

      
    }

    
  }
}

@media only screen and (max-width: 1023px) {
  .footer {
    padding: 1.5em;
    width: 100%;
    font-size: 0.89rem;
  }
}

</style>