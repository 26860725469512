<template>
  <div class="page">
    <nav-bar></nav-bar>
    <div class="page--content">
      <section class="aside">
        <types @onSelected="onSelected($event)"></types>
      </section>
      <section class="card">
        <div class="card--row">
          <div class="small-width left-panel">
            <one-drive v-if="location == 'Microsoft OneDrive'" :ref="el=>{one_drive=el}"></one-drive>
            <local @limit_hit="showModal" class="local" v-if="location == 'Local'" :collection_id="collection_id"></local>
            <div class="left-panel--actions">

              <button
                @click="uploadOneDrive"
                v-if="location == 'Microsoft OneDrive'"
                type=""
                class="button">
                Upload Selected Folders
              </button>
              <button
                @click="viewCollection"
                type=""
                class="button">
                View Collection
              </button>
            </div>
            <internal-library v-if="location == 'Internal Library'"></internal-library>
          <div v-if="!location">
            <p>Please choose a file location.</p>
          </div>
          </div>
          <div class="large-width">
              <files></files>
          </div>
        </div>

        <upload />

      </section>
    </div>
    <footer-bar></footer-bar>
    <modal
        v-if="isModalVisible == 'imageCap'" :isBig="true">
        <template v-slot:header>
            <h2>Upload Limit Reached</h2>
        </template>

        <template v-slot:body>
            <p class="message">
              Thank you for using RECONN.ai! You’ve reached the end of your trial limit, and can no longer upload new photos. Everything you’ve created during your trial will remain, and your account is still available, so don’t worry!
            </p>
            <p class="message">
              We hope you’ve enjoyed harnessing the power of machine learning technology to improve and expedite the review of your camera trap images.
            </p>
            <p class="message">
              To speak with us about joining our team of beta users, or signing up for a yearly membership, please email us at <a href="mailto:reconninfo@michiaganaerospace.com">reconninfo@michiaganaerospace.com</a>.
            </p>
        </template>

        <template v-slot:footer>
            <!-- <button
                type="submit"
                class="button"
                @click="confirmModal">
                View Pricing
            </button> -->
            <a @click="closeModal" class="btn-close">Close</a>
        </template>
    </modal>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import Types from "@/components/UploadTypes.vue";
import OneDrive from "@/components/OneDrive.vue";
import Upload from "@/components/UploadStatus.vue";
import Files from "@/components/FilesUploading.vue";
import Local from "@/components/LocalFiles.vue";
import InternalLibrary from "@/components/InternalLibrary.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    NavBar,
    FooterBar,
    OneDrive,
    Types,
    Upload,
    Files,
    Local,
    InternalLibrary,
    Modal
},

  props: {
    collection_id:undefined
  },

  data() {
    return {
      location: 'Local',
      progress:0,
      total:0,
      one_drive:null,
      isModalVisible: ""
    }
  },

  watch: {},
  unmounted(){
    state.clear();
  },
  mounted(){
    //this.emitter.emit('my-event')
  },
  methods: {
      viewCollection(){
        this.$router.push({
              name: 'Annotations',
              params: { collection_id:this.collection_id }

            });
      },
      uploadOneDrive(){
        let ret = this.one_drive.$.ctx.change()
        console.log("UPLOAD", ret);
        let upload_info = state.oneDrive.upload(ret,this.collection_id);

      },

      onSelected(location) {

      this.location = location;

    },

    showModal() {
      this.isModalVisible = "imageCap";
    },
    confirmModal() {
      this.isModalVisible = "";
      // this.$router.push({
      //   name: 'Prices'

      // });
    },
    closeModal() {
      this.isModalVisible = "";
    }
  },

  computed: {

  },



};
</script>

<style lang="scss" >

.left-panel--actions .button{
  width:350px;
}

.left-panel {
  display: flex;
  flex-direction: column;


  .local {
    flex-grow: 1;
  }

  &--actions {
    margin-top: 25px;


  }

}

</style>
