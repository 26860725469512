<template>
  <Carousel :items-to-show="1" :wrap-around="false">

        <Slide v-for="topic in topics" :key="topic.name">
            <div class="topic-slide">
                <h3 class="topic-slide--title">{{topic.name}}</h3>
                <div class="topic-slide--content" v-html="topic.content"></div>
            </div>
        </Slide>

        <template #addons>
            <Navigation />
            <Pagination />
        </template>
    </Carousel>
</template>

<script>

import { Carousel, Pagination, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {

    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    props: {
    },
    data() {
        return {
            
            topics: [
                {
                    name:"Welcome to Reconn.AI",
                    content: ` <p>Reconn provides an intuitive all-in-one platform to manage and analyze camera trap images by leveraging user trained state-of-the-art machine learning algorithm to help classify any animal species that you are interested in!</p>
                            <p>For a more indepth guide on how to use Reconn please see our <a href='/static/reconn_user_guide.pdf' target="_blank">User Manual</a>.</p>
                    `
                },
                {
                    name: "A Note About Species Accuracy",
                    content: 
                        `<p>Thank you for your interest in RECONN.ai, an all-in-one platform driven by machine learning technology to help you find and classify wildlife in your camera trap imagery.</p>
 
                            <p>We are still in our beta phase, focused on training and improving our performance on common US Midwest species, listed <a href='/diagnostics' target="_blank">here</a>.</p>
 
                            <p>The photos of these current species have many variables that can impact predicted results.  Quantity of photos in the dataset, location that the photos were taken, positioning of the animal within the image and image background can all play a part in the predictive accuracy on your upload images relative to the published numbers on our diagnostics page.</p>
 
                            <p>If you are interested in other species not currently in our dataset, don’t be dissuaded by the initial classification results! We will get there with your and the community’s help. Quickly training our algorithm on a new species is one of our strengths! Learn how you can help us by emailing us at <a href='mailto:info@reconn.ai'>info@reconn.ai</a></p>`
                },
                {
                    name: "Keyboard Shortcuts (hotkeys)",
                    content: `<table class="modal__table">
                                <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Action</th>
                                    <th>Set Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><i class="fa-solid fa-arrow-up"></i></td>
                                    <td>Scroll up</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><i class="fa-solid fa-arrow-down"></i></td>
                                    <td>Scroll down</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>C</td>
                                    <td>Confirm annotation</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>D</td>
                                    <td>Delete annotation</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>B</td>
                                    <td>Confirm blank image</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>H</td>
                                    <td>Open hotkeys menu</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>Open Tag Menu</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>  
                        </table>`
              
                }
            ]
        }
    },
    methods: {
      
    }
}

</script>

<style lang="scss">


</style>