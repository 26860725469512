<template>
    <div class="status">
        <div class="status__headings">
            <span>Time Elapsed</span>
            <span>Completed</span>
            <span>Files Remaining</span>
            <span>Status</span>
        </div>
        <div class="status__data">
            <span>{{time_elapsed}}</span>
            <span>{{progress}} %</span>
            <span>{{files_remaining}}</span>
            <span>{{status}}</span>
        </div>
    </div>

</template>

<script>

const zeroPad = (num, places) => String(num).padStart(places, '0')


export default {

    props: {
        files: Object
    },

    data() {
        return {
          timer_trigger:0,
          event_handle: null
        }
    },
    mounted:function(){
      this.timer_trigger+=1
    },
    methods: {
      update:function(){
        this.timer_trigger+=1

      }


    },

  computed: {
    status:function(){
      state.progress.value;

      if(state.progress.value == 0)
        return "Ready"
      else if(state.progress.value > 0 && state.progress.value < 100)
        return "Uploading"
      else
        return "Complete"
    },
    progress:function(){
      return state.progress.value
    },

    files_remaining:function(){

      this.timer_trigger+=1
      return state.progress.files_remaining
    },
    time_elapsed(){
      this.timer_trigger;

      if(state.progress.files_remaining > 0){
        if(this.event_handle!=null)
          window.clearTimeout(this.event_handle)
        this.event_handle = window.setTimeout(this.update,1000)
      }

      if(state.progress.start_time == null){
        return "--:--:--"
      }
      let now = new Date().getTime()
      let start_time = state.progress.start_time;
      let time_diff = (now-start_time) / 1000
      let hours = Math.floor(time_diff/3600)
      time_diff -= hours*3600
      let minutes = Math.floor(time_diff/60)
      time_diff -= minutes*60
      let seconds = Math.floor(time_diff)

      return `${zeroPad(hours,2)}:${zeroPad(minutes,2)}:${zeroPad(seconds,2)}`

    }
  },



};
</script>

<style lang="scss" >
    .status {
        border-top: 1px solid $light-grey;
        width: 100%;
        padding: 2em 10px 10px 10px;
        @media only screen and (max-width: 767px) {
            width: 92%;
        }
        &__headings {
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: $dark-blue;
            text-align: center;
            font-size: 1.1rem;
            margin-bottom: 7px;
            @media only screen and (max-width: 767px) {
                display: block;
            }
            span {
                width: 25%;

            }
        }

        &__data {
            width: 100%;
            display: flex;
            justify-content: space-between;
            text-align: center;
            @media only screen and (max-width: 767px) {
                display: block;
            }
            span {
                width: 25%;
                color: $almost-black;
            }
        }
    }
</style>
