<template>
  <div class="page collections-page">
    <nav-bar></nav-bar>
    <header-bar
        @open="showModal"
        :user="user"
        :title="collections.length + ' Collections Available'"
        :showButton="true"
        :addButtonText="'New Collection'" />
    <section class="collections">
        <universal-card
            v-for="(collection, index) in collections"
            :key="index"
            :group="collection"/>
    </section>
    <modal
      v-show="isModalVisible" >
      
      <template v-slot:header>
        <h2>Create New Collection</h2>
        <p>Lets get started by filling in the information about the new collection.</p>
        </template>

        <template v-slot:body>
            <div class="input-container">
                <label class="input-container--label">Name of Collection</label>
                <input class="input-container--box" type="text"/>
                <p class="input-container--subtext">**The entered name is processed to match the required format.</p>
            </div>
            <div class="input-container">
                <label class="input-container--label">Summary</label>
                <textarea class="input-container--area"></textarea>
            </div>
        </template>

        <template v-slot:footer>
            <button
                type="submit"
                class="button"
                @click="closeModal">
                Create
            </button>
            <a @click="cancelModal" class="btn-close">Cancel</a>
        </template>
    </modal>
    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import UniversalCard from "@/components/UniversalCard.vue";
import HeaderBar from "@/components/HeaderBar.vue";
import Modal from '@/components/Modal.vue';
import FooterBar from '@/components/FooterBar.vue';

import {get, post} from "@/scripts/samsa"

export default {

    components: {
        NavBar,
        UniversalCard,
        HeaderBar,
        Modal,
        FooterBar
    },

    props: {
    },

    data() {
        return {
            user: {
                username: "Chad Lewis",
                email: "clewis@michaero.com",
                website: "http://MichiganAerospace.com",
                orgs: ["Example Corp", "Company INC"],
                bio: "",
                avatar: "https://via.placeholder.com/100x100",
                location: "Ypsilanti, MI"
            },

            collections: [

            ],
            isModalVisible: false,

        }
    },

    watch: {},

    methods: {
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        cancelModal() {
            this.isModalVisible = false;
        },

    },

    computed: {

    },

};

</script>

<style lang="scss">
    // .collections {
    //     width: 95%;
    //     display: flex;
    //     flex-flow: row wrap;
    //     justify-content: space-between;
    //     padding: 25px;
    //     flex-grow: 1;
    //     height: 100%;
    //     margin: 0 auto;
    // }
    // .page.collections-page {
    //     .header__title {
    //         font-size: 1em;
    //         h1.title {
    //             padding-right: 2em;
    //         }
    //     }
    // }

</style>
