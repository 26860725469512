<template>
  <div class="image_upload" @drop="drop" @dragover="dragover">
User:{{user}}
images

 <one-drive></one-drive>
 <img id="blah"/>

<button @click="signIn">sign in</button>
<button @click="create">Create</button>
<div id="div"></div>
  </div>
</template>

<script>
import {get, post} from "@/scripts/samsa"
import OneDrive from "@/components/OneDrive";




function uploadFile(file, s3_data){
  let s3Data = s3_data.data
  let url = s3_data.url
  var xhr = new XMLHttpRequest();
  xhr.open("POST", s3Data.url);

  var postData = new FormData();
  for(var key in s3Data.fields){
    postData.append(key, s3Data.fields[key]);
  }
  postData.append('file', file);

  xhr.upload.onprogress = function(evt) {

    if(evt.lengthComputable) {
      let progress = Math.floor((evt.loaded / evt.total) * 100);
    }
  }
  xhr.upload.onloadend = function() {

  }
  xhr.onreadystatechange = function() {
    if(xhr.readyState === 4){
      if(xhr.status === 200 || xhr.status === 204){
        //window.$events.$emit("image_added", url)
        //window.$events.$emit("close_edit")
      }
      else{
        alert("Could not upload file.");
      }
   }
  };
  xhr.send(postData);
}


function handleFiles(files) {
  ([...files]).forEach(upload)
}

async function upload(file) {
  let ret = await post("sign_s3",{file_name:file.name,file_type:file.type});
  uploadFile(file, ret.data);

  //formData.append('file', file)
  //post("upload", file.name)
}

export default {
  components: {
OneDrive
  },

  props: {
    user:{default:null}
  },

  data() {
    return {
      files:[]
        }
  },

  watch: {},

  methods: {
    signIn(){
      state.oneDrive.signIn()
      state.oneDrive.listFolders()
    },
    create(){
      state.oneDrive.signOut()
      return;
      console.log(state.imageHandler.Show())

      return
      console.log(FileReader)
      let blah = document.getElementById("blah")
      blah.src = URL.createObjectURL(this.files[0])

      console.log(url)

    },
    drop(e){
      e.preventDefault()
      e.stopPropagation()

      let dt = e.dataTransfer
      let files = dt.files
      this.files = files
      sessionStorage.setItem('uploadFiles', files);
      console.log(files)
      handleFiles(files)

    },
    dragover(e){
      e.preventDefault()
      e.stopPropagation()
    }
  },

  computed: {

  },



};
</script>

<style lang="scss">
  .image_upload{
    width: 100vw;
    height: 100vh;
    background-color: $white;
  }
</style>
