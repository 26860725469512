<template>
  <svg class="pie-chart-area" width="500" height="350"></svg>
</template>

<script>

import * as d3 from 'd3';

export default {
    components: {},
    props: {chartData: Object},
    data() {
        return {
            
        }
    },

    async updated() {
   
      const svg = d3.select("svg"),
        width = svg.attr("width"),
        height = svg.attr("height"),
        radius = Math.min(width, height) / 2.5;

      const g = svg
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 1.9})`);

      const color = d3.scaleOrdinal(["#3B82F6", "#A5D4FF", "#0062D1", "#00227E", "#C5F1FF", "#003598", "#001F7A"]);

      const pie = d3.pie().value(function (d) {
        console.log("pie chart: ", d);
        return d.percent;
      });

      const path = d3
        .arc()
        .outerRadius(radius - 10)
        .innerRadius(radius - 50);

      const label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(radius + 30);

      const data = await this.chartData;
        
      const arc = g
        .selectAll(".arc")
        .data(pie(data))
        .enter()
        .append("g")
        .attr("class", "arc");

      arc
        .append("path")
        .attr("d", path)
        .attr("fill", function (d) {
          return color(d.data.animal);
        });

      arc
        .append("text")
        .attr("transform", function (d) {
          return `translate(${label.centroid(d)})`;
        })
        .text(function (d) {
          return d.data.animal;
        });

      svg
        .append("g")
        .attr("transform", `translate(${width / 2 - 250},20)`)
        .append("text")
        .text("Animals Found")
        .attr("class", "chart-title");
    }
};
    
</script>

<style lang="scss">
.pie-chart-area {
    padding: 25px;
    align-self: center;
}
    .arc {
        
        text {
            font: 12px arial;
            text-anchor: middle;
            fill: $dark-grey;
        }

        path {
            stroke: $white;
        }
    }

.chart-title {
  fill: $dark-grey;
  font-weight: 600;
}
</style>