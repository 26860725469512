<template>
  <div class="menubar">
    <ul class="menubar__tag-list">
      <li v-if="selected != null" class="menubar__tag-list--item" v-for="tag in selected.image_tags" :key="tag">
        <p>{{ tag }}</p>
        <i v-if="!tag.collection_id" class="fa-solid fa-xmark" @click="deleteTag(selected, tag)"></i>
      </li>
      <li v-if="selected != null" class="menubar__tag-list--item collection-color" v-for="tag in selected.collection_tags"
        :key="tag">
        <p>{{ tag }}</p>
      </li>
    </ul>


    <div class="menubar--actions">

      <i class="fa-solid fa-keyboard transparent" @click="hotKeysModal"></i>
      <div @click="exportData" class="button">
        Export Data
      </div>
      <div v-if="show_actions" @click="upload" class="button">
        Upload Images
      </div>
      <div v-if="show_actions" @click="view" class="button">
        View Project
      </div>
    </div>

  </div>
</template>

<script>
import { get, get_blob, post } from "@/scripts/samsa"

export default {

  components: {},
  props: { collection_id: { default: null }, selected: { default: null }, project_id:{default:undefined}, target_id:{default:undefined}, tag_id:{default:undefined}},
  computed: {
    // export_collection: function(){
    //   const APIPREFIX = process.env.VUE_APP_APIPREFIX;
    //   return `${APIPREFIX}/export_collection?collection_id=${this.collection_id}&project_id=${this.project_id}`
    // }
  },
  data() {
    return {
      show_actions:true,
    };
  },
  watch: {
    selected: function (a, b) {
      console.log("TAGS?", a)
    }
  },
  mounted:function(){
    if(this.$route.name=="SearchResults")
      this.show_actions = false;

  },
  methods: {
    hotKeysModal: function() {
      this.$emit("hotkeysModal");
    },  
    exportData:function(){
      //const APIPREFIX = process.env.VUE_APP_APIPREFIX;
      //`${APIPREFIX}/export_collection?collection_id=${this.collection_id}&project_id=${this.project_id}`
      console.log("EXPORT", this.collection_id)
      let file_name = null;
      if(this.collection_id != null)
        file_name = `${this.collection_id}.csv`
      else if(this.project_id != null)
        file_name = `${this.project_id}.csv`
      else if(this.tag_id != null)
        file_name = `${this.tag_id}.csv`
      else if(this.target_id != null)
        file_name = `${this.target_id}.csv`

      get_blob('export_collection',{collection_id:this.collection_id,project_id:this.project_id,target_id:this.target_id,tag_id:this.tag_id}).then(function(response){
        console.log("THEN",response)
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        console.log("file_name:", file_name)
        link.setAttribute('download', "reconn_export.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
    },
    upload: function () {
      this.$router.push({
        name: 'AddImages',
        params: { collection_id: this.collection_id }

      });
    },
    view: async function () {
      let project_id = await post("project_lookup", {}, { collection_id: this.collection_id });
      if (project_id.data) {
        this.$router.push({
          name: 'ProjectDetails',
          params: { project_id: project_id.data }

        });
      }
    },
    deleteTag: function(img, tag) {
      this.$emit("deleteTag", img, tag);
    }
  },

};
</script>

<style lang="scss">
.menubar {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  align-items: center;
  background-color: $white;
  height: 64px;
  flex-shrink: 0;
  font-size: 16px;

  &__tag-list {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    font-weight: 500;

    &--item {
      background: $grey;
      color: $white;
      display: flex;
      align-items: center;
      margin: 0px 5px 10px 5px;
      border-radius: 3px;

      p {
        padding: 3px 5px 3px 5px;
      }

      i {
        margin-left: 5px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 3px 5px;

        &:hover {
          color: $error-red;
          transition: all .2s;
          cursor: pointer;
        }
      }
    }

    .collection-color {
      background: $dark-grey;
    }
  }

  &--actions {
    width: 475px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      font-size: 1.5em;
    }
  }
}

</style>
