import { createRouter, createWebHistory } from 'vue-router'

import Home from "@/views/Landing.vue";
import SignUp from "@/views/SignUp.vue";
import SignIn from "@/views/SignIn.vue";
import PasswordReset from "@/views/PasswordReset.vue";
import Dashboard from "@/views/Dashboard.vue";
import Upload from "@/views/ImageUpload.vue";
import AddImages from "@/views/AddImages.vue";
import Collections from "@/views/Collections.vue";
import Organizations from "@/views/Organizations.vue";
import Annotations from "@/views/Annotations.vue";
import ProjectDetails from "@/views/ProjectDetails.vue";
import OrgDetails from "@/views/OrgDetails.vue";
import Profile from "@/views/UserProfile.vue";
import Sandbox from "@/views/Sandbox.vue";
import ReleaseNotes from "@/views/ReleaseNotes.vue";
import Feedback from "@/views/Feedback.vue";
import Diagnostics from "@/views/Diagnostics.vue";
import SearchResults from "@/views/SearchResults.vue";
import CheckList from "@/views/Checklist.vue";
import TermsOfService from "@/views/TermsOfService.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import AcceptInvite from "@/views/AcceptInvite.vue";
import SaaS from "@/views/SaaS.vue";


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/search/:search_string",
    name: "Search",
    component: SearchResults,
    props: true
  },
  {
    path: "/acceptinvite/:code",
    name: "AcceptInvite",
    component: AcceptInvite,
    props:true
  },
  {
    path: "/sandbox",
    name: "Sandbox",
    component: Sandbox,
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/saas",
    name: "SaaS",
    component: SaaS,
  },
  {
    path: "/checklist",
    name: "CheckList",
    component: CheckList,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/collection/:collection_id",
    name: "Annotations",
    component: Annotations,
    props:true
  },
  {
    path: "/searchresults",
    name: "SearchResults",
    component: Annotations,
    props: route => Object.assign({}, route.query, route.params)
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },

  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },

  {
    path: "/password-reset/:reset_key?",
    name: "PasswordReset",
    component: PasswordReset,
    props: true
  },

  {
    path: "/upload",
    name: "Upload",
    component: Upload,
  },
  {
    path: "/:user/upload",
    name: "UserUpload",
    component: Upload,
    props: true,
  },
  {
    path: "/add-images/:collection_id",
    name: "AddImages",
    component: AddImages,
    props: true,
  },
  {
    path: "/collections",
    name: "Collections",
    component: Collections,
  },
  {
    path: "/orgs",
    name: "Organizations",
    component: Organizations,
  },
  {
    path: "/diagnostics",
    name: "Diagnostics",
    component: Diagnostics,
  },
  {
    path: "/project-details/:project_id",
    name: "ProjectDetails",
    component: ProjectDetails,
    props: true,
  },
  {
    path: "/org-details",
    name: "OrgDetails",
    component: OrgDetails,
  },

  {
    path: "/profile/:user?",
    name: "Profile",
    component: Profile,
    props: true
  },
  {
    path: "/release-notes",
    name: "ReleaseNotes",
    component: ReleaseNotes,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
