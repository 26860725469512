<template>
  <div class="page">
    <nav-bar></nav-bar>
    <div class="search">
      <div class="search__content">
        <search-categories :results="results" @type_selected="type_selected"></search-categories>
      
        <search-result :results="results" :results_type="results_type"></search-result>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import SearchCategories from "@/components/SearchCategories.vue";
import SearchResult from "@/components/SearchResult.vue";
import FooterBar from "@/components/FooterBar.vue";
import {get, post} from "@/scripts/samsa";

import "@/assets/styles/search.scss";

export default {
  components: {
    NavBar, 
    FooterBar,
    SearchCategories,
    SearchResult
  },

  props: {search_string:undefined},

  data() {
    return {
      image_element:null,
      results:{'tags':[],'species':[],'collections':[],'projects':[]},
      width:"100%",
      items:[{x:20,y:20},{x:20,y:30}],
      r:45,
      results_type:null,
    };
  },

  watch: {
    search_string:async function(new_value,old_value){
      console.log("SEARCHING FOR ",new_value)
      let results = await post("search",{},{search_string:new_value});
      if(results.status == 200){
        this.results = results.data
        console.log("Results:", this.results)
      }
    }
  },
  methods: {
    type_selected:function(e){
      console.log("TYPE SELECTED",e)
      this.results_type = e;
    },
    loaded:function(){
      console.log("Loaded")

    },
    rotate:function(){
      this.r+=1
      this.r = this.r%360;
      window.setTimeout(this.rotate,100);
    },
    click:function(){
      let textbox = document.getElementById("text")
      let percent = 0
      let t1 = Date.now()
      function fade(){
        let tnow = Date.now() - t1
        let duration = 250
        percent = (tnow/duration)*100;
        console.log(`tnow: ${tnow}`);
        textbox.style.opacity = `${percent}%`;
        if(percent<100)
          window.setTimeout(fade,1);
        else{
          let textbox = document.getElementById("txt")
          textbox.focus()
        }
      }
      window.setTimeout(fade,1)
      //this.svg.check()
    }
  },

  computed: {

    style () {
       return 'rotate(' + this.r + ')'
    }
  },
  async updated(){

  },
  async mounted() {
    let results = await post("search",{},{search_string:this.search_string});
    if(results.status == 200){
      this.results = results.data
      console.log("Results:", this.results)
    }

  },
};
</script>
