<template>
  <main class="search__content__main">
    <!-- SPECIES RESULTS -->
    <section v-if="this.results_type!=null && this.results_type.key == 'species'" class="search__content__main__results">
      <h2>
        <span>Species Results</span>
      </h2>
      <div class="record">
        <h4 class="sub-cat">
          <span>Image</span>
          <span>Species</span>
          <span># of Images</span>
          <span>&nbsp; </span>
        </h4>
        <div v-for="item,key in results.species" :key="key" class="sub-section">
          <span><img width="100" :src="results.thumbnails[key][item[0].collection_name]" :alt="item[0].species" /></span>
          <span>{{key}}</span>
          <span>{{total(item)}}</span>
          <div class="actions">
            <i @click="view_species(item)"  class="fa-solid fa-images"></i>
            <i @click="export_species(item)" class="fa-solid fa-file-csv"></i>
          </div>
        </div>
      </div>
    </section>
    <!-- TAGS RESULTS -->
    <section v-if="this.results_type!=null && this.results_type.key == 'tags'" class="search__content__main__results">
      <h2>
        <span>Tags Results</span>
      </h2>
      <div class="record">
        <h4 class="sub-cat">
          <span>Image</span>
          <span>Tags</span>
          <span># of Images</span>
          <span>&nbsp; </span>
        </h4>
        <div v-for="item,key in results.tags" :key="key" class="sub-section">
          <span><img width="100" :src="results.thumbnails[item[0].tag][item[0].collection_name]" :alt="item[0].tag" /></span>
          <span>{{key}}</span>
          <span>{{total(item)}}</span>
          <div class="actions">
            <i @click="view_tags(item)"  class="fa-solid fa-images"></i>
            <i @click="export_tags(item)" class="fa-solid fa-file-csv"></i>
          </div>
        </div>
      </div>
    </section>
    <!-- COLLECTIONS RESULTS -->
    <section v-if="this.results_type!=null && this.results_type.key == 'collections'" class="search__content__main__results">
      <h2>
        <span>Collections Results</span>

      </h2>
      <div v-for="item, key in collections" :key="key" class="record">
        <h3 class="title">
          <span>{{item}}</span>
        </h3>
        <h4 v-if="collection_tags(item).length >= 1" class="sub-cat">
          <span>Image</span>
          <span>Tags</span>
          <span>Project</span>
          <span># of Images</span>
          <span>&nbsp; </span>
        </h4>

        <div v-for="tag,tag_key in collection_tags(item)" :key="tag_key" class="sub-section">
          <span><img width="100" :src="results.thumbnails[tag.tag][tag.collection_name]" :alt="tag.tag" /></span>
          <span>{{tag.tag}}</span>
          <span>{{tag.project_name}}</span>
          <span>{{tag.count}}</span>
          <span class="actions">
            <i @click="view_collection_tags(tag)" class="fa-solid fa-images"></i>
            <i @click="export_collection_tags(tag)" class="fa-solid fa-file-csv"></i>
          </span>
        </div>

        <h4 class="sub-cat">
          <span>Image</span>
          <span>Species</span>
          <span>&nbsp; </span>
          <span># of Images</span>
          <span>&nbsp; </span>
        </h4>

        <div v-for="species,species_key in collection_species(item)" :key="species_key" class="sub-section">
          <span><img width="100" :src="results.thumbnails[species.species][item]" :alt="species.species" /></span>
          <span>{{species.species}}</span>
          <span>&nbsp; </span>
          <span>{{species.count}}</span>
          <span class="actions">
            <i @click="view_collection_species(species,species_key)" class="fa-solid fa-images"></i>
            <i @click="export_collection_species(species,species_key)" class="fa-solid fa-file-csv"></i>
          </span>
        </div>
      </div>
    </section>
    <!-- PROJECTS RESULTS -->
    <div v-if="this.results_type!=null && this.results_type.key == 'projects'" class="search__content__main__results">
      <h2>
        <span>Project Results</span>
      </h2>
      <div v-for="item,key in projects" :key="key" class="record">
        <h3 class="title">
          <span>{{item}}</span>
        </h3>
        <h4 v-if="Object.keys(project_tags(item)).length >= 1" class="sub-cat">
          
          <span>Image</span>
          <span>Tags</span>
          <span># of Images</span>
          <span>&nbsp; </span>
        </h4>

        <div v-for="tag, tag_key in project_tags(item)" :key="tag_key" class="sub-section">
          <span><img width="100" :src="tag.url" :alt="tag_key" /></span>
          <span>{{tag_key}}</span>
          <span>{{tag.count}}</span>
          <span class="actions">
            <i @click="view_project_tags(tag,tag_key)" class="fa-solid fa-images"></i>
            <i @click="export_project_tags(tag,tag_key)" class="fa-solid fa-file-csv"></i>
          </span>
        </div>
        <h4 class="sub-cat">
          <span>Image</span>
          <span>Species</span>
          <span># of Images</span>
          <span>&nbsp; </span>
        </h4>
        <div v-for="species, species_key in project_species(item)" :key="species_key" class="sub-section">
          <span><img width="100" :src="species.url" :alt="species_key" /></span>
          <span>{{species_key}}</span>
          <span>{{species.count}}</span>
          <span class="actions">
            <i @click="view_project_species(species,tag_key)" class="fa-solid fa-images"></i>
            <i @click="export_project_species(species,tag_key)" class="fa-solid fa-file-csv"></i>
          </span>
        </div>

      </div>
    </div>
  </main>

</template>

<script>
import { get, get_blob, post } from "@/scripts/samsa"
export default {

    components: {

    },

    props: {
      results_type:{default:null},
      results:{default:{'tags':[],'species':[],'collections':[],'projects':[]}}
    },
    data() {
        return {
        }
    },
    watch:{

    },
    methods: {
      view_species:function(item){
        console.log("VIEW SPECIES")
        this.$router.push({name:"SearchResults",query:{target_id:item[0].target_id}})
      },
      export_species:function(item){
        let file_name = null;
        file_name = `${item[0].common_name}.csv`

        get_blob('export_collection',{target_id:item[0].target_id}).then(function(response){
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', "reconn_export.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
      },
      view_tags:function(item){
        console.log(item[0])
        this.$router.push({name:"SearchResults",query:{tag_id:item[0].tag}})
      },
      export_tags:function(item){
        let file_name = null;
        file_name = `${item[0].tag}.csv`

        get_blob('export_collection',{tag_id:item[0].tag}).then(function(response){
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', "reconn_export.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
      },
      view_collection_tags:function(item){
        console.log("View Collection Tag:",item)
        this.$router.push({name:"SearchResults",query:{collection_id:item.collection_id, tag_id:item.tag}})
      },
      export_collection_tags:function(item){
        let file_name = null;
        file_name = `${item.tag}.csv`

        get_blob('export_collection',{tag_id:item.tag,collection_id:item.collection_id}).then(function(response){
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', "reconn_export.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
      },
      view_collection_species:function(item){

        this.$router.push({name:"SearchResults",query:{collection_id:item.collection_id, target_id:item.target_id}})
      },
      export_collection_species:function(item){
        let file_name = null;
        file_name = `${item.tag}.csv`

        get_blob('export_collection',{target_id:item.target_id,collection_id:item.collection_id}).then(function(response){
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', "reconn_export.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
      },
      //BEGIN PASTE
      view_project_tags:function(project, tag_id){
        this.$router.push({name:"SearchResults",query:{project_id:project.project_id, tag_id:tag_id}})
      },
      export_project_tags:function(project, tag_id){
        let file_name = null;


        get_blob('export_collection',{tag_id:tag_id,project_id:project.project_id}).then(function(response){
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', "reconn_export.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
      },
      view_project_species:function(item){
        //console.log("View PROJECT Tag:",project_id,target_id)
        this.$router.push({name:"SearchResults",query:{project_id:item.project_id, target_id:item.target_id}})
      },
      export_project_species:function(item){
        let file_name = null;
        file_name = `${item.tag}.csv`

        get_blob('export_collection',{target_id:item.target_id,project_id:item.project_id}).then(function(response){
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', "reconn_export.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
      },
      //END PASTE
      total:function(items){
        let ret = 0
        for(var idx in items){
          ret+=items[idx].count;
        }
        return ret;
      },
      collection_tags:function(collection_name){
        let ret = []
        for(var tag in this.results['tags']){
          for(var idx in this.results['tags'][tag]){
            if(collection_name == this.results['tags'][tag][idx]['collection_name']){
                ret.push(this.results['tags'][tag][idx])
            }
          }
        }

        console.log("COLLECTION TAGS", ret);
        return ret
      },
      collection_species:function(collection_name){
        let ret = []
        for(var species in this.results['species']){
          for(var idx in this.results['species'][species]){
            if(collection_name == this.results['species'][species][idx]['collection_name']){
                ret.push(this.results['species'][species][idx])
            }
          }
        }
        return ret
      },
      project_tags:function(project_name){
        let ret = {}
        for(var tag in this.results['tags']){
          for(var idx in this.results['tags'][tag]){
            if(project_name == this.results['tags'][tag][idx]['project_name']){
                if(ret[tag] == undefined){
                  let collection_name = this.results['tags'][tag][idx]['collection_name']
                  ret[tag] = {count:0, url:this.results.thumbnails[tag][collection_name]}
                }
                ret[tag]['count']+=this.results['tags'][tag][idx]['count']
            }
          }
        }
        return ret
      },
      project_species:function(project_name){
        let ret = {}
        console.log(this.results)
        for(var species in this.results['species']){
          console.log("SPECIES: ", species)
          for(var idx in this.results['species'][species]){
            if(project_name == this.results['species'][species][idx]['project_name']){
              if(ret[species] == undefined){
                let collection_name = this.results['species'][species][idx]['collection_name']
                let project_id = this.results['species'][species][idx]['project_id']
                let target_id = this.results['species'][species][idx]['target_id']
                ret[species] = {count:0,project_id:project_id, target_id:target_id, url:this.results.thumbnails[species][collection_name]}
              }
              ret[species]['count'] += this.results['species'][species][idx]['count']
            }
          }
        }
        return ret
      }

    },
    mounted(){

    },

    computed: {
      collections:function(){
        let ret = []
        for(var species in this.results['species']){
          for(var idx in this.results['species'][species]){
          let collection_name = this.results['species'][species][idx]['collection_name']
          if(ret.indexOf(collection_name)==-1){
            ret.push(collection_name)
          }
        }
        }
        return ret
      },
      projects:function(){
        let ret = {}
        for(var species in this.results['species']){
          for(var idx in this.results['species'][species]){
          let project_name = this.results['species'][species][idx]['project_name']
          let project_id = this.results['species'][species][idx]['project_id']
          if(ret[project_id] == undefined){
            ret[project_id] = project_name;
          }
        }
        }
        return ret
      },
      // project_species:function(){
      //   let ret = {}
      //   for(var species in this.results['species']){
      //     for(var idx in this.results['species'][species]){
      //       project_name = this.results['species'][species][idx]['project_name']
      //       if(ret[project_name] == undefined){
      //           ret[project_name] = {count:0}
      //       }
      //       ret[project_name]['count'] += this.results['species'][species][idx]['count']
      //   }
      // }
      //
      // },
      // project_tags:function(){
      //   return []
      // }



    },



};
</script>
