<template>
  <div class="dashboardf">
    <nav-bar></nav-bar>
    <div class="content">
      <image-scroll @species_selected="species_selected" @selected="selected" :collection_id="collection_id"
        :tag_id="tag_id" :target_id="target_id" :project_id="project_id" @blank="blank" @hotkeysModal="hotkeysModal"
        @confirm="confirm" @validate="validate" @delete="delete_labels" @openTagsModal="openTagsModal">
      </image-scroll>

      <div class="rightside">
        <menu-bar :collection_id="collection_id" @deleteTag="delete_tag" @hotkeysModal="hotkeysModal" :tag_id="tag_id"
          :target_id="target_id" :project_id="project_id" :selected="selection"></menu-bar>
        <image-view v-if="selection" @show_warning="showwarning" :target="selected_target" :image="selection"
          @deleteTag="delete_tag" :collection_tags="collection_tags" :selection_index="selection_index"></image-view>
      </div>
    </div>
    <modal v-if="isModalVisible == 'tagsModal'">

      <template v-slot:header>
        <h2>Add Tags</h2>
      </template>

      <template v-slot:body>
        <form id="tag-form" @submit.prevent="closeModal" method="POST">
          <div class="input-container">
            <label class="input-container--label">Tag</label>
            <input class="input-container--box" v-model="new_tag" ref="inputBox" type="text"
              @keydown.enter="closeModal" />
            <p class="input-container--subtext">**The entered text is processed to match the required format.</p>
          </div>
        </form>
      </template>

      <template v-slot:footer>
        <button type="submit" class="button" form="tag-form">
          Add
        </button>
        <a @click="cancelModal" class="btn-close">Cancel</a>

      </template>

    </modal>

    <modal :isMedium="true" :hasHeight="true" v-if="isModalVisible == 'hotkeys'">
      <template v-slot:header>
        <h2>Hotkeys</h2>
      </template>

      <template v-slot:body>
        <table class="modal__table">
          <thead>
            <tr>
              <th>Key</th>
              <th>Action</th>
              <!-- <th>Set Action</th> -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><i class="fa-solid fa-arrow-up"></i></td>
              <td>Scroll up</td>
              <!-- <td>&nbsp;</td> -->
            </tr>
            <tr>
              <td><i class="fa-solid fa-arrow-down"></i></td>
              <td>Scroll down</td>
              <!-- <td>&nbsp;</td> -->
            </tr>
            <tr>
              <td>C</td>
              <td>Confirm annotation</td>
              <!-- <td>&nbsp;</td> -->
            </tr>
            <tr>
              <td>D</td>
              <td>Delete annotation</td>
              <!-- <td>&nbsp;</td> -->
            </tr>
            <tr>
              <td>B</td>
              <td>Confirm blank image</td>
              <!-- <td>&nbsp;</td> -->
            </tr>
            <tr>
              <td>H</td>
              <td>Open hotkeys menu</td>
              <!-- <td>&nbsp;</td> -->
            </tr>
            <tr>
              <td>T</td>
              <td>Open Tag Menu</td>
              <!-- <td>&nbsp;</td> -->
            </tr>
            <!-- <tr>
                <td>1</td>
                <td>&nbsp;</td>
                <td>
                  <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search" :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name" track-by="common_name">
                    <template v-slot:option="{ option }">
                      <span >{{ option.common_name }}</span>
                    </template>
                  </multiselect>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>&nbsp;</td>
                <td>
                  <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search" :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name" track-by="common_name">
                    <template v-slot:option="{ option }">
                      <span >{{ option.common_name }}</span>
                    </template>
                  </multiselect>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>&nbsp;</td>
                <td>
                  <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search" :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name" track-by="common_name">
                    <template v-slot:option="{ option }">
                      <span >{{ option.common_name }}</span>
                    </template>
                  </multiselect>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>&nbsp;</td>
                <td>
                  <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search" :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name" track-by="common_name">
                    <template v-slot:option="{ option }">
                      <span >{{ option.common_name }}</span>
                    </template>
                  </multiselect>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>&nbsp;</td>
                <td>
                  <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search" :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name" track-by="common_name">
                    <template v-slot:option="{ option }">
                      <span >{{ option.common_name }}</span>
                    </template>
                  </multiselect>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>&nbsp;</td>
                <td>
                  <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search" :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name" track-by="common_name">
                    <template v-slot:option="{ option }">
                      <span >{{ option.common_name }}</span>
                    </template>
                  </multiselect>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>&nbsp;</td>
                <td>
                  <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search" :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name" track-by="common_name">
                    <template v-slot:option="{ option }">
                      <span >{{ option.common_name }}</span>
                    </template>
                  </multiselect>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>&nbsp;</td>
                <td>
                  <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search" :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name" track-by="common_name">
                    <template v-slot:option="{ option }">
                      <span >{{ option.common_name }}</span>
                    </template>
                  </multiselect>
                </td>
              </tr> -->
          </tbody>
          <tfoot>
            <!-- <tr>
                <td>9</td>
                <td>&nbsp;</td>
                <td>
                  <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search" :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name" track-by="common_name">
                    <template v-slot:option="{ option }">
                      <span >{{ option.common_name }}</span>
                    </template>
                  </multiselect>
                </td>
              </tr> -->
          </tfoot>
        </table>
      </template>

      <template v-slot:footer>
        <!-- <button type="submit" class="button" ref="hotkeyEnter" @click="saveHotKeys" @keydown.enter="saveHotKeys">
          Save
        </button> -->
        <a @click="cancelModal" class="btn-close">Close</a>
      </template>
    </modal>
    <alert-box v-if="show_warning">

      <template v-slot:body>

        <p class="alert-body">
          <i class="fa-solid fa-triangle-exclamation red-alert"></i>
          <span>Please select a species before annotating the image.</span>
        </p>

      </template>

      <template v-slot:footer>
        <a @click="closeAlert" class="btn-close">OK</a>
      </template>
    </alert-box>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import ImageView from "@/components/Image";
import ImageScroll from "@/components/ImageScroll";
import MenuBar from "@/components/menubar";
import Modal from "@/components/Modal.vue";
import ContextMenu from '@/components/ContextMenu.vue';
import AlertBox from '@/components/AlertBox.vue';
import Multiselect from '@vueform/multiselect';
import { get, post } from "@/scripts/samsa";
import "@/assets/styles/annotations.scss";
import "@vueform/multiselect/themes/default.scss";


export default {

  components: {
    NavBar,
    ImageView,
    ImageScroll,
    MenuBar,
    Modal,
    ContextMenu,
    AlertBox,
    Multiselect
  },

  props: { collection_id: null, target_id: null, tag_id: null, project_id: null },

  data() {
    return {
      show_warning: false,
      searching: false,
      value: null,
      selection: null,
      selected_target: null,
      isModalVisible: false,
      new_tag: "",
      tagged_img: null,
      collection_tags: null,
      selection_index: 0,
    };
  },

  watch: {

  },
  computed: {

  },
  methods: {
    showwarning: function () {
      this.show_warning = true;
    },
    blank: function () {

    },
    confirm: function () {
      for (var idx in this.selection.labels) {
        let label = this.selection.labels[idx];
        label.verified = true
        post("add_label", label)
      }
    },
    validate: function () {

    },
    hotkeysModal: function () {
      this.isModalVisible = "hotkeys";
      // this.$nextTick(() => {
      //   this.$refs.hotkeyEnter.focus();
      // })
    },
    delete_labels: function () {
      console.log("DELETING")
      for (var idx in this.selection.labels) {
        let label = this.selection.labels[idx];
        post("remove_label", label)
      }


      this.selection.labels = []
    },
    nameWithLang: function ({ name, value }) {
      return `${name} — [${value}]`
    },
    selected: function (index, image) {
      //if(this.selected_target == null)
      //  this.show_warning = true;
      console.log("SELECTED: ", index, image)
      this.selection = image;
      this.selection_index = index;
    },
    species_selected: function (target) {
      console.log("TARGET CHANGE", target)
      this.show_warning = false
      this.selected_target = target;

    },
    closeAlert() {
      this.show_warning = false;
    },
    openTagsModal: function (image) {
      this.isModalVisible = "tagsModal";
      this.$nextTick(() => {
        this.$refs.inputBox.focus();
      })
      this.tagged_img = image;
    },
    cancelModal() {
      this.isModalVisible = "";
    },
    async saveHotKeys(evt) {
      evt.preventDefault();
      console.log("saving the hot keys!");
      this.isModalVisible = ""
    },
    async closeModal(evt) {
      evt.preventDefault();
      let tag = { name: this.new_tag.toLowerCase(), image_id: this.tagged_img.image_id, label_id: "", collection_id: "", is_smart: false };
      let tag_name = this.new_tag.toLowerCase();
      //this.tagged_img.image_tags.push(tag_name);
      console.log(this.tagged_img.image_tags)
      console.log("Closing Model: ", this.tagged_img)

      let newTags = await state.create_tags(this.tagged_img, tag_name);
      this.new_tag = "";
      this.isModalVisible = false;
    },
    async delete_tag(img, tag) {
      console.log("TAG TO REMOVE", img, tag);
      let remove_tag = await state.delete_tags(img, tag);

      let idx = img.image_tags.indexOf(tag);
      img.image_tags.splice(idx, 1);
      console.log("DELETED", img.image_tags.length)

    }

  },

  // async created() {
  //   this.isLoading = true;
  //   this.$store.dispatch('users/getUser')
  // },

  async mounted() {
    this.collection_tags = [];//await state.get_tags(this.collection_id);

    //this.tag = this.$route.query.tag


  },
};
</script>

<style lang="scss">
body {
  background-color: $white;

  .dashboardf {
    height: 100vh;

    ::selection {
      background: transparent;
    }

    ::-moz-selection {
      background: transparent;
    }

    .content {
      height: calc(100% - 64px);
      display: flex;

      .rightside {
        display: flex;
        flex-direction: column;
        width: calc(100% - 400px);
      }
    }

  }
}
</style>
