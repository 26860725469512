<template>
  <svg class="chart-area" :id="element_id" :width="width + margin.horizontal" :height="height + margin.vertical"
    :transform="transform"></svg>
</template>
  
<script>
import * as d3 from 'd3';

export default {
  name: 'BarChart',
  props: {
    data: {
      type: Array,
      required: true
    },
    element_id: String,
    width: 0,
    height: 0
  },
  // define width and height
  data() {
    return {
      
      transform: "rotate(0)",
      margin: {
        horizontal: 120,
        vertical: 30,
      }
    }
  },
  async updated() {
    console.log(this.element_id);
    const svg = d3.select("#" + this.element_id),
      width = svg.attr("width"),
      height = svg.attr("height"),
      transform = svg.attr("transform");
    // Create the scales for the x and y axes
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(this.data, d => d.percent)])
      .range([this.margin.horizontal / 2, this.width + this.margin.horizontal / 2 - 2]);

    const yScale = d3
      .scaleBand()
      .domain(this.data.map(d => d.animal))
      .range([this.height - this.margin.vertical / 2, this.margin.vertical])
      .padding(0.1);
    // Create the x and y axes
    const xAxis = d3.axisBottom(xScale)
      .ticks(4)
      .tickFormat(d => d + "%");

    const yAxis = d3.axisLeft(yScale);

    svg.selectAll('rect')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('x', this.margin.horizontal + 1)
      .attr('y', d => yScale(d.animal) + this.margin.vertical / 2)
      .attr('width', d => xScale(d.percent) - this.margin.horizontal / 2)
      .attr('height', 25)
      .attr('stroke', '#3b82f6')
      .attr('fill', '#3b82f6') 
      // => {
      //   switch (i) {
      //     case 0:
      //       return "#3B82F6";
      //     case 1:
      //       return "#A5D4FF";
      //     case 2:
      //       return "#0062D1";
      //     case 3:
      //       return "#00227E";
      //     case 4:
      //       return "#C5F1FF";
      //     case 5:
      //       return "#003598";
      //     case 6:
      //       return "#001F7A";
      //     default:
      //       return '#333';
      //   }
      // }
      // );

    // horizontal bars
    // const xScale = d3.scaleBand()
    //   .domain(this.data.map(d => d.animal))
    //   .range([width - this.margin.horizontal, this.margin.horizontal / 2])
    //   .padding(0.28);

    // const yScale = d3.scaleLinear()
    //   .domain([0, d3.max(this.data, d => d.percent)])
    //   .range([height - this.margin.vertical / 2, this.margin.vertical]);

    // const yAxis = d3.axisLeft(yScale)
    //   .ticks(5);
    // svg.selectAll('rect')
    //   .data(this.data)
    //   .enter()
    //   .append('rect')
    //   .attr('x', d => xScale(d.animal) + this.margin.horizontal / 2)
    //   .attr('y', d => yScale(d.percent))
    //   .attr('width', xScale.bandwidth())
    //   .attr('height', d => height - yScale(d.percent) - this.margin.vertical)
    //   .attr('class', 'bar')
    //   .attr('fill', (d, i) => {
    //     switch (i) {
    //       case 0:
    //         return "#3B82F6";
    //       case 1:
    //         return "#A5D4FF";
    //       case 2:
    //         return "#0062D1";
    //       case 3:
    //         return "#00227E";
    //       case 4:
    //         return "#C5F1FF";
    //       case 5:
    //         return "#003598";
    //       case 6:
    //         return "#001F7A";
    //       default:
    //         return '#333';
    //     }
    //   });

    svg.append('g')
      .call(yAxis)
      .attr('transform', `translate(${this.margin.horizontal}, 0)`)
      .attr("class", "axis");

    svg.append('g')
      .call(xAxis)
      .attr('transform', `translate(${this.margin.horizontal / 2}, ${height - this.margin.vertical * 1.5})`)
      .attr("class", "axis");

    svg.selectAll('text')
      .data(this.data)
      .enter()
      .append('text')
      .text(d => d.percent)
      .attr('x', (d, i) => xScale.bandwidth() * i + xScale.bandwidth() / 2)
      .attr('y', d => yScale(d.percent) - 5)
      .attr('text-anchor', 'middle');

    // axe labels
    // svg.append('text')
    //   .attr('x', this.width / 2 + this.margin.horizontal / 2)
    //   .attr('y', this.height + this.margin.vertical / 1.2)
    //   .attr('text-anchor', 'middle')
    //   .attr('font-size', '14px')
    //   .text('Percent')
    //   .attr('class', 'title');

    // svg.append('text')
    //   .attr('x', - this.height / 2)
    //   .attr('y', this.margin.horizontal / 8)
    //   .attr('text-anchor', 'middle')
    //   .attr('font-size', '14px')
    //   .attr('transform', 'rotate(-90)')
    //   .text('Species')
    //   .attr('class', 'title');

    svg.append("g")
      .attr("transform", `translate(${width / 2 - this.margin.horizontal}, ${this.margin.vertical / 2})`)
      .append("text")
      .text("Animals Found")
      .attr("class", "title");
  }
}
</script>

<style lang="scss">
chart-area {
  padding: 25px;
  align-self: center;
  stroke: $dark-grey;
  stroke-width: 3;
  font-weight: 200;
}

.axis {
  font: 14px Helvetica, sans-serif;
  color: $almost-black;
  font-weight: 500;
  path {
    stroke: $almost-black;
    stroke-width: 1.1;
  }

  line {
    stroke: $almost-black;
    stroke-width: 1.1;
  }
  
}

// .bar {
//   stroke: $almost-black;
//   stroke-width: 1.28;
// }

.title {
  fill: $dark-grey;
  font-weight: 600;
  
}
</style>
  