import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "@/scripts/state.js"
import { verifyAuthenticated } from "@/scripts/authentication.js";
import "@/scripts/organizations";
import "@/scripts/image.js";
import "@/assets/styles/global.scss"
import tooltip from "@/directives/v-tooltip/tooltip.js";
import "@/directives/v-tooltip/tooltip.css";
import mitt from 'mitt';

const emitter = mitt()
console.log("Setting Emitter to window")
window.$events = emitter


// let ret = $samsa.test()
// console.log("TEST:",ret)
//
// let ret2 = $samsa.console.post("hello")
// console.log("console:",ret2)

//
// let a = axios.get(APIPREFIX+"/info", {
//   transformResponse: [
//     (data) => {
//       console.log(data);
//     },
//   ],
// params:{name:"chad",age:34}});
//
// console.log(a)
//
// let b = axios.post(APIPREFIX+"/console",
//   {
//   "value": "string",
//   "name": "string",
//   "age": 0
// },{transformResponse: [
//   (data) => {
//     console.log("h:",data);
//   },
// ]});



state.verifyAuthenticated().then((res)=>{
  let app = createApp(App)
  app.config.globalProperties.emitter = emitter
  app.use(router).directive("tooltip", tooltip).mount('#app')
  emitter.on('my-event', (evt) => {
        console.log("Cool")
      })

})
