<template>
    <aside class="search__content__aside">
      <div :class="[{selected:item==selected}, 'search__content__aside__category']" v-for="item in categories" @click="select(item)" :key="item.key">
        <span>{{item.text}}</span>
        <span v-if="item.key == 'species' || item.key == 'tags'" class="badge">{{total(results[item.key])}}</span>
        <span v-else class="badge">{{total(results.species) + total(results.tags)}}</span>
      </div>
    </aside>
  
</template>

<script>

export default {

    components: {

    },

    props: {
      results:{default:{'tags':[],'species':[],'collections':[],'projects':[]}}
    },
    data() {
        return {
            categories: [
              {
                text:"Species", 
                key:"species"
              },
              {
                text:"Tags",
                key:"tags"
              },
              {
                text:"Collections",
                key:"collections"
              },
              {
                text:"Projects",
                key:"projects"
              }
            ],
            selected: null,
            
        }
    },
    watch:{

    },
    methods: {
      total: function(items){
        console.log("RESULTS ITEM KEY", items)
        let ret = 0
        for(var idx in items){
          for(var idx2 in items[idx]){
            ret+=items[idx][idx2].count;
          }
        }
        return ret;
      },

       

      select: function(e){
        this.selected = e;
        this.$emit("type_selected",e)
      }

    },
    mounted(){
      this.select(this.categories[0])
    },

    computed: {


    },



};
</script>

