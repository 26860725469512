<template>
<div class="page">
  <nav-bar></nav-bar>
  <section class="landing">
    <div class="landing__row">
      <div class="landing__row--left">
        <div class="container">
          <h1 class="oversized-font">Find and classify wildlife <span class="blue-text">in your camera trap imagery</span></h1>
          <p class="container--description">RECONN.AI provides an intuitive all-in-one platform to manage and analyze camera trap images by leveraging user trained state-of-the-art machine learning algorithm to help classify any animal species that you are interested in!</p>
          <!-- <router-link :to="{path: '/diagnostics' }"><button class="button">See the Data!</button></router-link> -->
        </div>
      </div>
      <div class="landing__row--right">
        <img class="landing__row--image" src="../assets/images/bear5.jpg" alt="Black Bear being Annotated" title="Black Bear"/>
      </div>
    </div>
    <div class="landing__row">
      <div class="landing__row--left">
        <img class="landing__row--small-image" src="../assets/images/dashboard_example.png" alt="Dashboard user interface example" title="Dashboard Image"/>
      </div>
      <div class="landing__row--right center" >
        <div class="container">
          <h1 class="oversized-font">Streamline Your <span class="blue-text">Project</span></h1>
          <p class="container--description">
            RECONN.ai makes it easy to upload, store, annotate, and export your data. Wildlife in your photos are automatically identified through the utilization of machine learning technology. Analyze and tag thousands of photos in just minutes, freeing up your time to be spent elsewhere.
          </p>
          <router-link :to="{path: '/sign-up' }"><button class="button">Get Started</button></router-link>
        </div>
      </div>
    </div>
  </section>
  <info-alert v-if="showAlert" :bottom="'20px'" :right="'10px'" :top="null" :left="null">
    <template v-slot:title>
      <i class="fa-solid fa-cookie-bite"></i> Use of Cookies
    </template>
    <template v-slot:info>
      By continuing to browse or by clicking "Accept", you agree to the use of cookies to enhance your site experience. To learn more about how we use cookies or to learn about GDPR please see our <router-link class="terms-link" :to="{path: '/privacy-policy' }" target="_blank">Privacy Policy</router-link>.
    </template>
    <template v-slot:actions>
      <button class="button" @click="accept(true)">accept and close</button>
    </template>
  </info-alert>
  <footer-bar></footer-bar>
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import InfoAlert from "@/components/InfoAlert.vue";


export default {
  components: {
    NavBar,
    FooterBar,
    InfoAlert
  },

  props: [],

  data() {
    return {
      isModalVisible: false,
      showAlert: false,
      cookieAccepted: null
    };
  },

  watch: {},

  methods: {
    accept(accepted) {
      console.log("COOKIE ACCEPTED")
      localStorage.setItem("accepted_cookies",true);
      this.cookieAccepted = accepted;
      this.showAlert = false;
    }
  },

  computed: {},

  beforeMount() {
    let val = localStorage.getItem("accepted_cookies");
    if(val == undefined)
      this.showAlert = true;
  },
};
</script>

<style lang="scss">
.landing {
  color: $almost-black;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__row {
    width: 100%;
    display: flex;
    background: $white;
    height: 840px;
    overflow: hidden;

    &--left {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    .center {
      align-items: center;
    }

    &--image {
      width: 100%;
    }

    &--small-image {
      width: 90%;
    }

    .container {
      width: 65%;
      height: 50%;
      text-align: left;

      &--description {
        font-size: 1.3rem;
        color: $dark-grey;
        margin: 15px 0 25px 0;
      }
    }
  }

}


</style>
