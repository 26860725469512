<template>
  <div class="annotation_container" :ref="el => { annotation_container = el }">

    <svg v-if="this.image" :height="height" :width="width" @mousemove="mousemove" style="position:relative"
      @mouseup="mouseup" @mouseleave="mouseleave">


      <foreignObject v-if="image != null" width="100%" height="100%">
        <ul class="annotation_container__tag-list">
          <li class="annotation_container__tag-list--item" v-for="tag in species_list" @mouseover="highlight(tag)"
            @mouseleave="unhighlight" :key="tag.name" :style="{ 'background-color': color(tag) }">
            <p>{{ tag }}</p>
            <i v-if="!tag.collection_id" @click="delete_species(this.image, tag)" class="fa-solid fa-xmark"></i>
          </li>
        </ul>

        <!-- Add the bookmark icon here -->
        <i v-if="!searchResults" :class="(bookmarkHovered && (bookmarkIndex === selection_index)) ? 'fa-solid fa-bookmark' : 'fa-regular fa-bookmark'" @mouseover="bookmarkHovered = true"
          @mouseout="bookmarked === true ? bookmarkHovered = true : bookmarkHovered = false" @click="bookmarkIt(image)"></i>

        <div class="fill">
          <img @resize="resize" @mousedown="mousedown" class="aimage" @load="loaded" @error="error"
            :ref="el => { image_element = el }" :src="image_src(image.url)" draggable="false">
        </div>
      </foreignObject>

      <!-- <g v-for="annotation, idx in this.image.labels" :key="idx">
        <rect :class="{verified:annotation.verified}" :x="_title_x(annotation.x) + _width(annotation.width)/2 - 175" :y="_title_y(annotation.y, annotation.height)-40" width="355px" height="35" class="text_rect"></rect>
        <text :class="{verified:annotation.verified}" :x="_title_x(annotation.x) + _width(annotation.width)/2-175+20" :y="_title_y(annotation.y, annotation.height) -20 " >{{capitalCase(annotation.text)}}</text>

        <foreignObject :class="{verified:annotation.verified}" :y="_title_y(annotation.y, annotation.height) - 35" :x="_title_x(annotation.x)  + _width(annotation.width)/2-175+ 332" width="20px" height="20px"><i @mousedown="delete_method(annotation)" class="fa-solid fa-trash"></i></foreignObject>
        <foreignObject :class="{verified:annotation.verified}" :y="_title_y(annotation.y, annotation.height) - 35" :x="_title_x(annotation.x)  + _width(annotation.width)/2-175+ 310" width="20px" height="20px"><i @mousedown="approve_method(annotation)" class="fa-solid fa-certificate"></i></foreignObject>
      </g> -->

      <g v-if="show_cursor">
        <line class="cursor" :x1="x" y1="0" :x2="x" y2="100%" stroke="red" />
        <line class="cursor" x1="0" :y1="y" x2="100%" :y2="y" stroke="red" />
      </g>

      <rect v-if="is_mouse_down" class="verified" :x="selection_x" :width="selection_width" :y="selection_y"
        :height="selection_height" stroke="red" />


      <rect v-for="annotation, idx in this.image.labels" @click="openMenu(annotation)" stroke-width=2 rx=2 ry=2
        :style="{ 'stroke': color(annotation.text), 'fill': 'none' }" :key="idx" :x="_x(annotation.x)"
        :width="_width(annotation.width)" :y="_y(annotation.y)" :height="_height(annotation.height)" />
      <g v-for="annotation, idx in this.image.labels" :key="idx"
        :transform="translate(_x(annotation.x) + _width(annotation.width), _y(annotation.y))">
        <circle v-if="annotation.verified" stroke-width=1 stroke="#FFF" fill="#2ecc71" r=8 :key="idx" :cx="0" :cy="0" />
        <path v-if="annotation.verified" stroke-width=2 fill="none" stroke="#FFF" d="M-2,-0 L0,3 L3,-4 M-2,3" />
      </g>
    </svg>

    <context-menu v-if="isMenuVisible" :arrowRight="arrowRight" :top="_title_y()" :left="_title_x()" :bottom="null"
      :right="null">

      <template v-slot:header>
        <p>{{ selectedItem.text }}</p>
        <p v-if="selectedItem.score" class="small-text">{{ Math.floor(selectedItem.score * 100).toFixed(1) + "%" }}</p>
      </template>

      <template v-slot:body>

        <div @click="approve_method(selectedItem)" v-if="!selectedItem.verified" class="context-list-item"><span>Confirm
            Annotation</span></div>
        <div @click="remove_approval(selectedItem)" v-else class="context-list-item"><span>Unconfirm Annotation</span>
        </div>
        <div @click="delete_method(selectedItem)" class="context-list-item"><span>Delete Annotation</span></div>

      </template>
    </context-menu>
    <!--<img :ref="el=>{image_element=el}" :src="image">
    <span style="float:left;">{{width}}</span>-->

  </div>

</template>

<script>

import { ref, toRaw, onMounted, getCurrentInstance } from 'vue'
import { get, post } from "@/scripts/samsa"
import ContextMenu from "@/components/ContextMenu.vue";



export default {

  components: { ContextMenu },
  props: { image: { default: null }, target: { default: null }, collection_tags: null, selection_index: {type: Number, required: true} },

  data() {
    return {
      x: 0,
      y: 0,
      tick: 0,
      _show_cursor: false,
      is_mouse_down: false,
      mouse_down_x: 0,
      mouse_down_y: 0,
      width: "100",
      height: "100",
      image_element: null,
      annotation_container: null,
      pixel_width: 0,
      pixel_height: 0,
      isMenuVisible: false,
      selectedItem: null,
      arrowRight: false,
      species_list: [],
      colors: ["#2ecc71", "#3498db", "#9b59b6", "#34495e", "#e67e22", "#e74c3c", "#95a5a6", "#d35400", "#f1c40f", "#bdc3c7"],
      highlighted_item: null,
      is_error: false,
      error_src: null,
      bookmarked: false,
      bookmarkHovered: false,
      bookmarkIndex: null,
      searchResults: false,
    };
  },
  watch: {
    "image.labels": function () {
      this.isMenuVisible = false;
      this.setSpecies()
    },
    image: function (image, b) {
      this.is_error = false;
      if (image.labels.length == 1) {
        this.selectedItem = image.labels[0];
        this.isMenuVisible = true;
      }
      else {
        this.isMenuVisible = false;
      }
    },
    selection_index: function(new_index, old_index) {
      console.log(`selection index changed from ${old_index} to ${new_index}`);
      console.log("bookmark index: ", this.bookmarkIndex);
    }
  },
  methods: {
    async bookmarkIt(image) {
      if (this.bookmarkIndex != this.selection_index) {
        this.bookmarked = false;
      }
      
      if (this.bookmarked) {
        this.bookmarked = false;
        this.bookmarkIndex = null;
        // console.log("removing bookmark from image index: ", this.selection_index);
        let ret = await post("remove_bookmark",{}, {collection_id:image.collection_id});
        console.log("ret.data: ", ret.data);
      } else {
        this.bookmarked = true;
        this.bookmarkIndex = this.selection_index;
        // console.log("adding bookmark to image index: ", this.bookmarkIndex);
        let ret = await post("add_bookmark",{}, {collection_id:image.collection_id, index:this.bookmarkIndex});
        console.log("ret.data: ", ret.data);
      }
    },

    translate(x, y) {
      return `translate(${x},${y})`;
    },
    highlight(tag) {
      this.highlighted_item = tag;
    },
    unhighlight() {
      this.highlighted_item = null;
    },
    color(c) {
      if (c == this.highlighted_item)
        return "#f1c40f"
      else
        return this.colors[this.species_list.indexOf(c)]
    },
    camelToSnakeCase: function (str) {
      return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    },
    capitalCase: function (str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return word.toUpperCase();
      })
    },
    setSpecies() {
      if (this.image != null) {
        let species_list = []
        for (var idx in this.image.labels) {
          let species_name = this.image.labels[idx].text;
          if (species_list.indexOf(species_name) == -1) {
            species_list.push(species_name)
          }
        }
        this.species_list = species_list;
      }

    },
    openMenu(annotation) {
      //CONTEXT MENU OPEN
      this.selectedItem = annotation;
      this.isMenuVisible = true
    },

    _x: function (x) {
      this.tick;

      if (this.image_element) {
        return this.width * x;
      }
      else
        return 0;
    },
    _y: function (y) {

      this.tick;
      if (this.image_element)
        return this.height * y;
      else
        return 0
    },
    _title_x: function (x) {

      x = this.annotation_container.getBoundingClientRect().x + this.annotation_container.getBoundingClientRect().width / 2 - this.width / 2;

      if (this.image_element) {
        if (this.selectedItem.x + this.selectedItem.width > .5) {
          this.arrowRight = true;
          return x + this.selectedItem.x * this.width - 165;

        }
        else {
          this.arrowRight = false;
          return x + this.selectedItem.x * this.width + this.selectedItem.width * this.width + 15;
        }

        if (this.width * x > 200) {
          return this.width * x;
        } else
          return 150;
      }
      else
        return 0;
    },
    _title_y: function (y, h) {
      if (this.image_element) {
        return this.image_element.getBoundingClientRect().y + this.selectedItem.y * this.height + this.selectedItem.height * this.height / 2 - 15;
      }

    },
    _width: function (width) {
      if (this.image_element) {
        return this.width * width;
      } else {
        return 0
      }

    },
    _height: function (height) {
      if (this.image_element) {
        // console.log("HEIGHT: ", height)
        return this.height * height;
      }
      else {
        return 0
      }

    },
    mouseleave: function () {
      this._show_cursor = false;
    },
    trash: function (e) {

    },
    delete_method: function (annotation) {
      let i = this.image.labels.indexOf(annotation)
      this.image.labels.splice(i, 1)

      post("remove_label", annotation)
      this.setSpecies()

      //for context menu to close when menu item selected
      this.isMenuVisible = false

    },
    approve_method: async function (annotation) {
      annotation.verified = true;
      await post("add_label", annotation)
      //this.tick += 1;
      //for context menu to close when menu item selected
      this.isMenuVisible = false
    },
    remove_approval: function (annotation) {
      annotation.verified = false
      post("add_label", annotation)
    },
    resize: function (e) {
      this.loaded()
    },
    error: function () {
      console.log("ERROR");
      this.is_error = true;
      this.error_src = this.image.url;
    },
    image_src: function (src) {
      if (this.is_error) {
        return require('@/assets/images/desert_placeholder.jpg');
      } else {
        return src;
      }
    },
    loaded: function (e) {
      console.log("LOADED", this.image.url);

      let width = this.image_element.naturalWidth;
      let height = this.image_element.naturalHeight;
      console.log("WIDTH: ", width);
      let aspect_ratio = width / height;


      let container_width = this.annotation_container.clientWidth;
      console.log("CONTAINER WIDTH: ", container_width);
      let container_height = this.annotation_container.clientHeight;

      // let max_width = container_height * aspect_ratio
      // let max_height = container_width * aspect_ratio

      this.height = container_height;
      this.width = this.height * aspect_ratio;
      console.log("THIS WIDTH: ", this.width);
      console.log("aspect ratio", aspect_ratio);

      if (this.width > container_width) {
        this.width = container_width
        this.height = container_width / aspect_ratio;
        console.log("aspect ratio", aspect_ratio);
      }

      this.setSpecies()
      this._show_cursor = true;
      this.tick += 1
    },
    mousemove: function (e) {
      if (this.target == null)
        return
      this._show_cursor = true;
      const rect = this.image_element.getBoundingClientRect();
      const x = e.pageX - rect.x;
      const y = e.y - rect.y;
      this.x = x;
      this.y = y;
    },
    mousedown: function (e) {
      if (this.target == null) {
        this.$emit("show_warning")
        return
      }
      this.mouse_down_x = e.offsetX;
      this.mouse_down_y = e.offsetY;
      this.is_mouse_down = true;

      const rect = this.image_element.getBoundingClientRect();
      const x = e.pageX - rect.x;
      const y = e.y - rect.y;
      this.x = x;
      this.y = y;
    },
    mouseup: async function (e) {
      if (this.target == null)
        return

      let width = this.image_element.clientWidth;
      let height = this.image_element.clientHeight;

      let fracX = this.selection_x / width;
      let fracY = this.selection_y / height;
      let fracWidth = this.selection_width / width;
      let fracHeight = this.selection_height / height;

      if (fracWidth > .01 && fracHeight > .01) {
        let target = toRaw(this.target)

        let annotation = { target_id: this.target.target_id, image_id: this.image.image_id, text: target.common_name, target: target, verified: true, x: fracX, y: fracY, width: fracWidth, height: fracHeight, manual: true };

        this.image.labels.push(annotation);
        let new_label = await post("add_label", annotation);
        annotation.label_id = new_label.data.label_id;

        this.setSpecies()

      }
      //this.annotations.push(annotation);
      this.is_mouse_down = false;
    },
    delete_species: function (img, tag) {
      let cont = true
      while (cont) {
        cont = false;
        for (let idx in this.image.labels) {
          if (this.image.labels[idx].text == tag) {
            let annotation = this.image.labels[idx]
            this.image.labels.splice(idx, 1)
            post("remove_label", annotation)
            cont = true;
            break
          }
        }

      }
      this.setSpecies()
      this.isMenuVisible = false;
      //this.$emit('deleteTag', img, tag);
    },
  },
  computed: {

    all_tags() {
      return this.image.image_tags;//this.image.tags.concat(this.collection_tags);
    },
    show_cursor: function () {
      if (this.target != null)
        return this._show_cursor;
      else
        return false;
    },
    selection_x: function () {
      if (!this.is_mouse_down)
        return 0;
      else {
        return Math.min(this.mouse_down_x, this.x);
      }
    },
    selection_width: function () {
      if (!this.is_mouse_down)
        return 0;
      else {
        return Math.abs(this.mouse_down_x - this.x);
      }
    },
    selection_y: function () {

      if (!this.is_mouse_down)
        return 0;
      else {
        return Math.min(this.mouse_down_y, this.y);
      }
    },
    selection_height: function () {
      if (!this.is_mouse_down)
        return 0;
      else {
        return Math.abs(this.mouse_down_y - this.y);
      }
    }
  },
  mounted() {
    const currentUrl = window.location.href;

    if (currentUrl.includes("searchresults")) {
      this.searchResults = true;
      console.log("searchresults!")
    } else {   
      this.searchResults = false;
      let bookmarkIndex = get("get_bookmark", {collection_id: this.image.collection_id});
      this.bookmark_index = bookmarkIndex.data;
      this.bookmarkHovered = true;
      this.bookmarkIt(this.image);
    }

    // let self = this;
    //console.log("INITIAL", self.image_element.clientHeight);
    //this.width = self.image_element.clientWidth+ "px";

    if (this.image.labels.length == 1) {
      this.selectedItem = this.image.labels[0];
      this.isMenuVisible = true;
    }

    window.onresize = this.resize;

  },
};
</script>

<style lang="scss">
.annotation_container {
  user-select: none;
  flex-grow: 1;
  background-color: $white;
  position: relative;
  overflow: hidden;

  &__tag-list {
    position: absolute;
    padding: 10px;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    font-weight: 500;

    &--item {
      background: $grey;
      color: $white;
      display: flex;
      align-items: center;
      margin: 0px 5px 10px 5px;
      cursor: default;
      border-radius: 3px;

      p {
        padding: 3px 5px 3px 5px;
      }

      i {
        margin-left: 5px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 3px 5px;

        &:hover {
          color: $error-red;
          transition: all .2s;
        }
      }
    }

    .collection-color {
      background: $dark-grey;
    }
  }

  foreignObject {
    color: $black;

    .fill {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
    }
  }

  .aimage {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  i {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  .fa-bookmark {
    position: absolute;
    top: 0px;
    right: 10px;
    z-index: 100;
    cursor: pointer;
    font-size: 2.25vw;
    transition: all .2s;
    &.fa-regular {
      color: $white;
    }
    &.fa-solid {
      color: $primary-color;
    }
  }

  foreignObject i {
    cursor: pointer;
    font-size: 16px;
  }

  line.cursor,
  rect.cursor {
    pointer-events: none;
    fill: #cae555;
    stroke: #cae5BB;
  }

  line,
  rect {
    pointer-events: visible;
    fill: #FFcae555;
    stroke: #FFcae5BB;
  }

  foreignObject.verified {
    cursor: pointer;
    color: black;
  }

  rect.verified {
    pointer-events: visible;
    fill: #95cae555;
    stroke: #95cae5BB;

  }

  text.verified {
    pointer-events: none;
    stroke: black;
    fill: black;
    color: black;
  }

  text {
    stroke: black;
    stroke-width: 0px;
    pointer-events: none;
    color: black;
  }

  .text_rect {
    pointer-events: none;
    fill: #FFcae5;
    stroke: #FFcae5;
    color: white;
  }

  .text_rect.verified {
    pointer-events: none;
    fill: #95cae5;
    stroke: #95cae5;
    color: white;
  }
}
</style>
