

class State{
  #name = "Chad"
  constructor() {
    console.log("State Initialized")


  }

  Show(){
    console.log(this.#name)
  }
}

import { reactive,ref, onMounted, getCurrentInstance } from 'vue'

window.state = reactive(new State());


export {State}
