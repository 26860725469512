<template>
  <div style="position:relative">


  </div>


</template>

<script>


import NavBar from "@/components/NavBar.vue";
import HeaderBar from "@/components/HeaderBar.vue";
import FooterBar from "@/components/FooterBar.vue";

export default {
  components: {NavBar,HeaderBar, FooterBar},

  props: [],

  data() {
    return {
      image_element:null,
      width:"100%",
      items:[{x:20,y:20},{x:20,y:30}],
      r:45
    };
  },

  watch: {},

  methods: {

    loaded:function(){
      console.log("Loaded")

    },
    rotate:function(){
      this.r+=1
      this.r = this.r%360;
      window.setTimeout(this.rotate,100);
    },
    click:function(){
      let textbox = document.getElementById("text")
      let percent = 0
      let t1 = Date.now()
      function fade(){
        let tnow = Date.now() - t1
        let duration = 250
        percent = (tnow/duration)*100;
        console.log(`tnow: ${tnow}`);
        textbox.style.opacity = `${percent}%`;
        if(percent<100)
          window.setTimeout(fade,1);
        else{
          let textbox = document.getElementById("txt")
          textbox.focus()
        }
      }
      window.setTimeout(fade,1)
      //this.svg.check()
    }
  },

  computed: {

    style () {
       return 'rotate(' + this.r + ')'
    }
  },

  mounted() {


  },
};
</script>

<style>




</style>
