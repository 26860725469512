<template>
  <div class="page">
    <nav-bar></nav-bar>
    <div class="terms">
      <section class="terms__card">
        <h2>Privacy Policy</h2>
        <h3>Effective on: October 31, 2022</h3>

        <p class="block">
          Michigan Aerospace is committed to protecting your privacy and is
          constantly working to give you a safe online experience. This “Privacy
          Policy” applies to Michigan Aerospace and governs its collection and usage
          of personally identifiable information. By using the website or any
          products or services of Michigan Aerospace, you consent to the data
          practices described in this Privacy Policy. Please read our Privacy Policy
          for more information.
        </p>
        <p class="block">
          Defined terms in Michigan Aerospace’s Terms and Conditions (“Terms”) will
          have the same meaning as in the Terms, unless otherwise defined in the
          Privacy Policy.
        </p>

	      <h3>1. DEFINITIONS</h3>
        <p class="block">
          A. “Data” includes both Non-Personal Information and Personally
          Identifiable Information.
        </p>
        <p class="block">
          B. “Non-Personal Information” is any information that is not Personally
          Identifiable Information (defined below). Non-Personal Information
          includes, without limitation, Personally Identifiable Information that has
          been anonymized.
        </p>
        <p class="block">
          C. “Personal Information,” “Personally Identifiable Information” or “PII”
          is non-public information we receive from your use of the Site that can be
          used, alone or in combination with other information in our possession, to
          identify a particular person/individual. It may include information such as
          name, address, telephone number and other personal information you provide
          us.
        </p>
        <p class="block">
        D. “Site” refers to the website at <a href="https://reconn.ai">https://reconn.ai</a>, including
          any related blogs, domains, mobile sites, online services and applications
          maintained by Michigan Aerospace.
        </p>
        <p class="block">
          E. “We,” “us” and “our” mean Michigan Aerospace.
        </p>
        <p class="block">
          F. “You” and “your” mean the individual or entity visiting or using the
          Site.
          
        </p>

	      <h3>2. INFORMATION COLLECTED AND CONSENT</h3>
        <p class="block">
          You do not have to provide any Personally Identifiable Information to
          browse the publicly available pages of the Site; however, in order to
          obtain or use products or services that are or may be provided through the
          Site or that we may otherwise offer online or offline (collectively, the
          “Products”) you may be required to register and/or provide Personally
          Identifiable Information.
        </p>

	      <h4 class="sub-title">Personal Information You Provide Us</h4>
    <p class="block">
      We may collect PII from you in a variety of ways, including, but not
      limited to, when you visit the Site, communicate with us through the Site,
      provide PII in connection with other activities, products, services,
      features or resources we make available on the Site or through any services
      we may offer or events we may participate in online or offline, or through
      third parties that we conduct business with or through. We may collect your
      name, mailing address, billing address, phone number, payment information,
      email address, preferences, interests and preferences. We may also collect
      additional information from you depending on the nature of the transaction.
    </p>
    <ul class="block-list">
      <li>
        Identifiers
      </li>
      <ul class="block-list-alt">
        <li>
          First and last name
        </li>
        <li>
          Alias
        </li>
        <li>
          Postal/mailing address
        </li>
        <li>
          Email address
        </li>
        <li>
          Password
        </li>
        <li>
          Telephone number
        </li>
        <li>
          Cellular telephone number
        </li>
        <li>
          Geographic area
        </li>
        <li>
          Geolocation data
        </li>
        <li>
          Title
        </li>
        <li>
          Internet protocol address
        </li>
        <li>
          Avatar image file
        </li>
        <li>
          State driver’s license number
        </li>
        <li>
          Documents to verify your identity
        </li>
        <li>
          Your occupation
        </li>
        <li>
          The name of the organization you work for
        </li>
        <li>
          Name of employer
        </li>
        <li>
          General biographical information that you may provide to us.
        </li>
        <li>
          Interests (studies, species, etc.)
        </li>
        <li>
          Photograph of you or license plate number (depending on the nature
          of the Products provided. Michigan Aerospace manually pseudonymizes
          the photographs that are uploaded or otherwise automatically
          captured.
        </li>
      </ul>

      <li>
        Commercial information
      </li>
      <ul class="block-list-alt">
        <li>
          Transaction information
        </li>
        <li>
          Products or services purchased, obtained or considered
        </li>
        <li>
          Your purchasing or consuming history or tendencies, along with
          other preferences and interests
        </li>
        <li>
          Financial Information about you and/or your company
        </li>
        <li>
          Information about your business
        </li>
        <li>
          Any other information required for the provision of the Products.
        </li>
      </ul>

      <li>
        Geolocation data
      </li>

      <li>

          Inferences drawn from this personal information to create a profile
          about you. We do not combine personal information collected from third
          party sources with the personal information you provide us.
      </li>
    </ul>
    <p class="block">
      We collect information from you when you (i) register on the Site, (ii)
      fill out forms or fields on the Site or offline, (iii) complete an
      application or purchase order for a product or service available through
      the Site (iv) communicate with us by email, mail, text, telephone,
      facsimile or other electronic means, (v) use the Site or authorize us to
      access any financial information stored or processed by a third party, or
      (vi) provide us with Personal Information or other information directly.
    </p>
    <p class="block">
      We will treat as personal information any item that, either alone or
      together with other information, we could use to identify an individual.
      Except as described below, we will not share with third parties any
      personal information without your permission.
    </p>
    <p class="block">
      By providing your Personal Information to us, you explicitly agree to our
      collection and use of such information as described in this Privacy Policy.
      By providing Personal Information belonging to another person, you
      expressly represent and warrant that you have the authority and right to do
      so, and that you obtained all appropriate and required consents, and you
      also indemnify and hold us harmless for any violation of this warranty.
    </p>
    <p class="block">
      We may also collect your Personal Information from sources that you have
      authorized to share with us or that you have authorized us to obtain
      information from.
    </p>
    <p class="block">
      We collect information about you from third parties related to transactions
      you are contemplating entering into with us or that you have entered into
      with us.
    </p>
    <p class="block">
      If you believe we received your personal information from a source without
      authority to share your personal information with us, please contact us so
      that we can resolve your complaint.
    </p>
    <h4 class="sub-title">Information We Automatically Collect</h4>
    <p class="block">
      Michigan Aerospace may collect information about you automatically when you
      visit the Site, communicate through the site, purchase, receive or
      otherwise use any of our Products. We may automatically receive certain
      information from your browser or mobile device, such as your browser type,
      the operating system of your device, the URL of the site from which you
      came and the URL of the site you visit when leaving the Site or any of our
      online Products, the equipment you use to access the Site, the unique
      identifier of your mobile device and your activities on the Site, such as
      the webpages you view and how you interact with those webpages. We may also
      receive your IP address and the name of your internet service provider
      (“ISP”) or your mobile carrier. Depending on your device’s settings, we may
      also receive location data and other types of information sent from third
      party services or GPS-enabled devices.
    </p>
    <p class="block">
      As you use the Site or any of our Products, we may also collect information
      about such usage and your actions on the Site, such as pages you viewed,
      access times, and how long you spent on a page. We may use cookies to
      collect such information, as described in more detail below. This
      information is considered Non-Personal Information, which we can use for
      any purpose, including, without limitation, to help us improve the Site or
      the Products and to enhance your and other users’ experience. We may also
      convert personal information into Non-Personal Information by excluding
      information that is personally identifiable. You acknowledge that
      Non-Personal Information and Personal Information that is converted into
      Non-Personal Information belongs to Michigan Aerospace and that Michigan
      Aerospace has the right to use such general information as it determines in
      its sole discretion.
    </p>
    <h3>3. Web Browser Cookies</h3>

    <p class="block">
      The Site or our online Products may send a “cookie” to your computer. A
      cookie is a small piece of data sent to your browser from a web server and
      stored on your computer’s hard drive. A cookie cannot read data off your
      hard disk or read cookie files created by other sites. Cookies do not
      damage your system. Cookies allow us to recognize you as a user when you
      return to the Site or use the online Products using the same computer and
      web browser. We may also use beacons, tags, and scripts to collect and
      track information. We use cookies and other tracking technologies to
      identify which areas of the Site or online Products you have visited. We
      also may use this information to better personalize the content you see on
      the Site or online Products. Michigan Aerospace does not store unencrypted
      Personal Information in the cookies. We also do not link Non-Personal
      Information from cookies to your Personal Information.
    </p>
    <p class="block">
      To help us optimize the Site or for marketing purposes, we may allow other
      authorized third parties to place or recognize unique cookies on your
      browser. Any information provided to third parties through cookies will be
      for enhancing your user experience by providing more relevant marketing.
      Third party services and tools we use, which may send cookies to users of
      the Site and may collect information from users, includes:
    </p>

    <p class="block">
      <span style="font-weight:bold;">Google Analytics, Google Tag Manager</span>
      : Google Analytics and Google Tag Manager (collectively, “Google”) collect
      anonymous data (ad views, browser information, cookie data, date/time,
      demographic data, hardware/software type, interaction data, page views, and
      serving domains), pseudonymous data (IP Address (EU PII), search history,
      location based data, Clickstream Data, PII (name , address, phone number,
      email address, login, EU- IP address, EU-unique device ID ), sensitive
      (financial information). Google’s privacy policy can be found at:
      <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>. 
      Anonymous data is shared
      with third parties. Michigan Aerospace does not combine the information
      collected through Google with Personally Identifiable Information.
    </p>
    <p class="block">
      Some browsers may allow you to manage the storage of cookies on your
      device. If supported by your browser, you may set your browser to refuse
      all cookies (or, sometimes all third-party cookies) or to alert you when a
      cookie is placed. However, if you select these settings, you may be unable
      to access certain parts of the Site or the online Products. Unless you have
      adjusted your browser setting to refuse cookies, the Site and online
      Products will issue cookies. For more information about how to manage your
      cookies preferences, use the ‘help’ menu of your web browser or explore the
      customer support sections of your web browser. To opt-out of all cookies or
      certain advertising cookies, visit the company website for your browser for
      instructions.
    </p>
    <p class="block">
      You can opt-out of receiving personalized ads from advertisers and ad
      networks that are members of the Network Advertising Initiative (“NAI”) or
      who follow the Digital Advertising Alliance (“DAA”) Self-Regulatory
      Principles for Online Behavioral Advertising using their respective opt-out
      tools. The NAI's opt-out tool can be found here
      <a href="http://www.networkadvertising.org/choices/">
        http://www.networkadvertising.org/choices/
      </a>
      and the DAA's opt out tool can be found here
      <a href="http://www.aboutads.info/choices/">
        http://www.aboutads.info/choices/
      </a>.
    </p>
    <p class="block">
      In addition, your mobile devices may offer settings that enable you to make
      choices about the collection, use, or transfer of mobile app information
      for online behavioral advertising (for example, Apple iOS’ Advertising ID
      and Google Android’s Advertising ID). Please note that opting out does not
      prevent the display of all advertisements to you.
    </p>

    <h3>4. Embedded Pixels</h3>

    <p class="block">
      We may use on the Site embedded pixel technologies on selected pages for
      the purposes of identifying unique user visits to the Site as opposed to
      aggregate hits. In addition, embedded pixels or other technologies may be
      used in emails to provide information on when the email was opened to track
      marketing campaign responsiveness; information collected using these
      technologies may be associated with your email address.
    </p>
    <h3>5. Flash Cookies</h3>
    <p class="block">
      We may use flash cookies, also known as “local shared objects”, on the Site
      that employs flash technology. Flash cookies are small files similar to
      browser cookies and are used to remember the site’s settings to personalize
      the look and feel of the site. Like normal cookies, flash cookies are
      represented as small files on your computer.<strong></strong>
    </p>

    <h3>6. Embedded URLs</h3>
    <p class="block">
      We also use a tracking technique that employs embedded URLs to allow use of
      the Site without cookies. Embedded URLs allow limited information to follow
      you as you navigate the Site but is not associated with your Personal
      Information and is not used beyond the session.
    </p>

	  <h3>7. Widgets</h3>
    <p class="block">
      The Site may include widgets, which are interactive mini-programs that run
      on our Site to provide specific services from another company (e.g., links
      to bookmarked sites). Some information, such as your email address, may be
      collected through the widget. Cookies may also be set by the widget to
      enable it to function properly. Information collected by this widget is
      governed by the privacy policy of the company that created it.
    </p>

    <h3>
      8. HOW WE USE AND SHARE YOUR PERSONALLY IDENTIFIABLE INFORMATION
    </h3>
    <p class="block">
      We will use your personal information to provide the Products that your
      personal information was shared with us to fulfill. Michigan Aerospace may
      use personal information for the following purposes:
    </p>
    <p class="block">
      A. <em>To improve customer service: </em>Your information helps us to more
      effectively communicate with inquiries and existing customers, and to
      respond to your customer service requests and support needs.
    </p>
    <p class="block">
      B. <em>To personalize user experience: </em>We may use information in the
      aggregate to understand how our users as a group use the Products and
      resources provided on the Site.
    </p>
    <p class="block">
      C. <em>To improve the Site: </em>We continually strive to improve our Site
      and the Products based on the information and feedback we receive from you.
    </p>
    <p class="block">
      D. <em>To provide products or services and to process transactions: </em>
      Michigan Aerospace will use and share Data for the purpose of fulfilling
      our duties and providing the Products. We may use and share the information
      that you provide about yourself or that you have provided to our clients
      for the purpose of providing and fulfilling the Products ordered or
      otherwise requested. In addition to the purposes set forth in this
      paragraph, we share this information with outside parties that are
      necessary to provide the Products; this may include, without limitation,
      our service providers, lenders and third-party brokers. Michigan Aerospace
      may use Non-Personal Information for statistical analysis, product
      development, research, or other purposes.
    </p>
    <p class="block">
      E. <em>To send periodic mail, emails and surveys: </em>The email address
      you provide for the Products will also be used to send you information,
      newsletters, surveys and updates pertaining to the Products requested,
      provided or that we think you may be interested in. It may also be used to
      respond to your inquiries and/or other requests or questions.
    </p>
    <p class="block">
      F. <em>To develop new products or services: </em>We use your Personal
      Information and Non-Personal Information for research and commercial
      purposes. The information we collect may be used to develop new products or
      services. Except as otherwise provided in this Privacy Policy, we may use
      your Personal Information and Non-Personal Information internally or, among
      other things, to offer our own or third-party products and services.
    </p>
    <p class="block">
      G. <em>Fulfillment of Obligations: </em>Comply with contractual
      obligations, relevant industry standards and our policies.
    </p>
    <p class="block">
      H. <em>For marketing: </em>Except as otherwise expressly provided for in
      this Privacy Policy or except as prohibited by applicable law, Michigan
      Aerospace may use your Personal Information and Non-Personal Information to
      enhance its networking, marketing, social, and recruiting activities, and
      for other similar business purposes. Michigan Aerospace may also use your
      Personal Information and Non-Personal Information to contact you on behalf
      of external business partners about a particular offering that may be of
      interest to you. In these cases, your Personal Information is not
      transferred to the third party. We may also use Personal Information to
      provide you information regarding new products or services or to post
      testimonials from you related to our products or services. Michigan
      Aerospace does not use or disclose sensitive Personal Information, such as
      race, religion, or political affiliations, without your explicit consent.
    </p>
    <p class="block">
      I. <em>Compliance with applicable law: </em>We may disclose your Personal
      Information as we may in our sole discretion determine is necessary or
      reasonably required to comply with the law, applicable regulations, court
      orders or subpoenas, to enforce our Terms of Use, or to protect our rights,
      property or safety, or the rights, property or safety of others.
    </p>
    <p class="block">
      J. <em>Security: </em>Mitigate fraud, enhance the security of the Site and
      manage institutional risk.
    </p>
    <p class="block">
      K. <em>Change in the Site Ownership: </em>If ownership of the Site or of
      Michigan Aerospace changes, whether in whole or in part, information
      collected through the Site and the Products about you may be transferred to
      the new owner of the Site and/or Michigan Aerospace, as the case may be,
      and any Products can continue. In that case, your Personal Information
      would remain subject to the terms and conditions of the applicable version
      of the Privacy Policy.
    </p>
    <p class="block">
      L. <em>Payment Processing and Credit Applications</em>: For processing
      payments, creating credit accounts, and performing and exercising any
      rights or obligations related to the Products we provide you.
    </p>
    <p class="block">
      M. <em>Cooperation with Third Parties.</em> As part of the provision of the
      Products, we may provide access to information you provide in order to
      facilitate the provision of Products to you, including, without limitation,
      the processing of credit applications with us and collections.
    </p>
    <h3>9. CONSENT TO ELECTRONIC COMMUNICATIONS</h3>
    <p class="block">
      We may use your email address to send you messages notifying you of
      important changes to the Products or special offers we think you may be
      interested in. Further, we may contact you by telephone if you provide us
      with your telephone number, in order to communicate with you regarding the
      Products we are providing to you. If you do not want to receive email
      messages or telephone calls, please contact us. Some communications related
      to ongoing transactions or the enforcement of terms you have agreed to
      related to the provision of Products may not be subject to your opting out
      of or choosing not to receive.
    </p>
    <p class="block">
      In order to receive our Products, it may be necessary, as we may determine,
      for you to consent to transacting business with us electronically. We may
      also require you to consent to giving us certain disclosures
      electronically, either through the Site or to the email address you provide
      to us. By entering into any agreement for any of the Products, you agree to
      receive electronically all documents, communications, notices, contracts
      and agreements (collectively, “Communications and Agreements”) arising from
      or relating to your use of the Site and the Products, including your
      registration on the Site, your use of the Products, and matters involving
      payment for the purchase of our Products.
    </p>
    <p class="block">
      A. <span style="font-weight: bold;">Opt-Out: </span>You may revoke your consent by contacting us
              by email at <a href="mailto:privacy@reconn.ai">privacy@reconn.ai</a>.
    </p>
    <p class="block">
      B. <span style="font-weight: bold;">Scope of Consent: </span>Your consent to receive
      Communications and Agreements and transact business electronically, and our
      agreement to do so, applies to any transactions to which such
      Communications and Agreements apply.
    </p>
    <h3>10. RETENTION AND STORAGE OF PERSONAL INFORMATION</h3>
    <p class="block">
      We retain your Personal Information for as long as necessary to fulfill the
      purpose for which it was collected and to comply with applicable laws. We
      use reasonable security precautions to protect your information while in
      storage.
    </p>
    <p class="block">
      To determine the appropriate retention period for Personal Information, we
      consider the amount, nature, and sensitivity of the personal information,
      the potential risk of harm from unauthorized use or disclosure of your
      Personal Information, the purposes for which we process your personal
      information, whether we can achieve those purposes through other means, and
      applicable legal requirements.
    </p>

    <h3>11. SECURITY</h3>
    <p class="block">
      We will use commercially reasonable methods to keep your Personal
      Information securely in our files and systems. When credit card numbers are
      transmitted through a field in a form on the Site requesting credit card
      numbers, it is protected through the use of encryption, such as the Secure
      Socket Layer (SSL) protocol.
    </p>
    <h3>12. DO NOT TRACK</h3>
    <p class="block">
      Your browser may provide you with the option to send a “Do Not Track”
      signal to websites you visit. This signal is used to request that websites
      not send the requesting device cookies, but websites have no obligation to
      respond to such signal or to modify their operation. At the current time,
      the Site is not programmed to recognize Do Not Track signals, so the Site
      will not treat you differently if we receive such signals from your browser
      and we may not comply with Do Not Track settings on your browser.
    </p>
    <h3>13. EXEMPTIONS FROM THE PRIVACY POLICY</h3>

    <p class="block">
      Our Privacy Policy does not apply to any information you may send to
      Michigan Aerospace by email or instant messaging programs (e.g., AOL,
      Yahoo, etc.), or through social media networks, even if you open such
      programs or services by clicking a link displayed on the Site. Email,
      social media, and instant messages are not recognized as secure
      communication forms. Please do not send any information you consider
      private to us by email or instant message or through social media sites
      (e.g., Twitter, Facebook, etc.) due to the public nature of such postings.
    </p>

    <h3>14. LINKS TO OTHER SITES</h3>
    <p class="block">
      The Site and the online Products may contain links to other websites.
      Michigan Aerospace is not responsible for the actions, practices, or
      content of websites linked to or from the Site or the online Products. You
      understand such websites may require you to agree to their terms of use and
      that we have no control over these terms. As always, you understand it is
      your responsibility to verify your legal use of a website and use of
      information from the website with the corresponding website owner.
    </p>
    <h3>15. AGE OF CONSENT</h3>
    <p class="block">
      Michigan Aerospace takes special care to protect the privacy needs of
      children under the age of 13 and we encourage parents to be an active
      participant in their child’s online activities. Michigan Aerospace abides
      by the Children’s Online Privacy Protection Act (COPPA) and other relevant
      laws. The Site does not target and is not intended for children under the
      age of 13, and Michigan Aerospace will not knowingly collect Personal
      Information directly from them. If Michigan Aerospace discovers that a
      child has provided personal data directly through the Site, Michigan
      Aerospace will eliminate that data.
      <span style="font-weight: bold;">
        This Site is only intended for persons 18 years of age or older. If you
        are under the age of 18, you must ask a parent or legal guardian for
        permission prior to submitting any information to this Site.
      </span>
    </p>
    <p class="block">
      If you have knowledge that a child 13 years of age or younger has submitted
      Personally Identifiable Information to us, please contact us and we will
      delete the Personal Information collected belonging to that child. You may
      contact us via email at the address below. Parents and guardians can also
      email us at <a href="mailto:privacy@reconn.ai">privacy@reconn.ai</a> before
      any information is disclosed, the parent will be required to provide
      verification his/her identity and authority related to any request. We will
      only send the information to the parent email address in the registration
      file.
    </p>
    <h3>16. CAN-SPAM COMPLIANCE NOTICE</h3>
    <p class="block">
      Michigan Aerospace fully complies with the federal CAN-SPAM Act. You can
      always opt out of receipt of further email correspondence from us.
    </p>
    <h3>17. CALIFORNIA PRIVACY RIGHTS</h3>

    <p class="block">
      Under Section 1798.83 of the California Civil Code, residents of California
      can obtain certain information from companies with whom they have an
      established business relationship. That information is about the Personal
      Information those companies have shared with third parties for direct
      marketing purposes during the preceding calendar year. The law requires
      companies to inform consumers about the categories of Personal Information
      shared with third parties, the names and addresses of those third parties,
      and examples of the services or products marketed by those third parties.
      To request a copy of the information disclosure provided by Michigan
      Aerospace under Section 1798.83 of the California Civil Code, please
      contact us via email to <a href="mailto:privacy@reconn.ai">privacy@reconn.ai</a>.
    </p>
    <h3>18. CALIFORNIA CONSUMER PRIVACY ACT</h3>
    <p class="block">
      TO THE EXTENT APPLICABLE TO MICHIGAN AEROSPACE, THE FOLLOWING TERMS IN THIS
      SECTION 18 APPLY:
    </p>
    <p class="block">
      This Privacy Policy for California residents supplements the information
      contained above in our general privacy policy and applies solely to
      visitors, users and others who reside in the State of California. Any
      inconsistent terms in any other sections of the Privacy Policy will be
      superseded by this Section for California residents.
    </p>
    <h3>We will not sell your personal information</h3>

    <p class="block">
      Michigan Aerospace does not and will not sell your Personal Information to
      third parties.
    </p>
    <h3>Your Rights Under the CCPA</h3>

      <p class="block">
        To the extent applicable to Michigan Aerospace, under the CCPA, California
        residents are granted the following rights:
      </p>
      <ol class="block-list-num">
        <li>
          To know what personal information is being collected about them;
        </li>
        <li>
          To know whether their personal information is sold or disclosed and to
          whom;
        </li>
        <li>
          To say no to the sale of their personal information;
        </li>
        <li>
          To access their personal information;
        </li>
        <li>
          To have their personal information deleted; and
        </li>
        <li>
          To have the right to equal service and price, even if they exercise
          their privacy rights under this law.
        </li>
      </ol>
      <p class="block">
        When receiving a request, we will verify that the individual making the
        request is the resident to whom the personal information subject to the
        request pertains. California residents may exercise their rights themselves
        or may use an authorized agent to make requests to disclose certain
        information about the processing of their personal information or to delete
        personal information on their behalf. If you use an authorized agent to
        submit a request, we may require that you provide us additional information
        demonstrating that the agent is acting on your behalf.
      </p>
      <h4 class="sub-title">Categories of Personal Information We Collect</h4>

      <p class="block">
        We may collect information as set forth above and also information that
        identified you, your household or your device or is reasonably capable of
        being connected with or linked to you, your household or your device.
        “Personal information” does not include public information available from
        government records, de-identified or aggregated information.
      </p>
      <h4 class="sub-title">How We Use Your Information</h4>

      <p class="block">
        See above for how we use your information. Except as otherwise stated in
        this Privacy Policy, we will not collect additional categories of personal
        information or use the personal information we collected for materially
        different, unrelated, or incompatible purposes without providing you
        notice.
      </p>
      <h4 class="sub-title">Requests to Know</h4>
      <p class="block">
        You have the right to request that we disclose certain information to you
        about our collection and use of your Personal Information over the prior 12
        months. Once we receive and confirm your verifiable consumer request, we
        will disclose to you:
      </p>
      <ul class="block-list">
        <li>
          The categories of Personal Information we collected about you.
        </li>

        <li>
          The categories of sources for the Personal Information we collected
          about you.
        </li>

        <li>
          The business or commercial purpose for collecting or selling your
          Personal Information.
        </li>

        <li>
          The categories of third parties with whom we share your Personal
          Information.
        </li>

        <li>
          The specific pieces of Personal Information we have collected about you
        </li>

        <li>
          If we sold or disclosed your Personal Information for a business
          purpose, which we do not do and will not do, two separate lists
          disclosing:
        </li>

        <ul class="block-list-alt">
          <li>
            In the case of a sale, the categories of Personal Information sold
            about you and the categories of third parties to whom the Personal
            Information was sold, by category of Personal Information for each
            category of third parties; and
          </li>
          <li>
            In the case of disclosure for a business purpose, the categories of
            Personal Information that were sold or disclosed for a business
            purpose and if Personal Information has not been sold or disclosed
            for a business purpose, that we did not do so.
          </li>
        </ul>
      </ul>
      <p class="block">
        We are not required to (i) retain your Personal Information if collected
        for a single one-time transaction if, in the ordinary course of business,
        that information would not be retained and (2) re-identify or otherwise
        link any data that, in the ordinary course of business, is not maintained
        in a way that would be considered personal information.
      </p>
      <h4 class="sub-title">Requests to Delete</h4>
      <p class="block">
        You have the right to request that we delete any of your Personal
        Information that we collected from you and retained, subject to certain
        exceptions. Once we receive and confirm your verifiable consumer request,
        we will delete (and direct our service providers to delete) your Personal
        Information from our records, unless an exception applies, which we will
        disclose to you.
      </p>
      <p class="block">
        We are not required to grant your deletion request if retaining the
        Personal Information is necessary for us or our service providers to:
      </p>
      <ol class="block-list-num">
        <li>
          Complete the transaction for which the Personal Information was
          collected, fulfill the terms of a written warranty or product recall,
          provide a good or service requested by you, or reasonably anticipated
          within the context of our ongoing business relationship with you, or
          otherwise perform a contract between us and you.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive,
          fraudulent or illegal activity; or prosecute those responsible for that
          activity.
        </li>
        <li>
          Debug to identify and repair errors that impact existing intended
          functionality.
        </li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise
          that consumer’s right of free speech, or exercise another right
          provided for by law.
        </li>
        <li>
          Comply with the California Electronic Communications Privacy Act.
        </li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest when the deletion of the
          public information is likely to render impossible or seriously impair
          the achievement of such research, if the consumer has provided informed
          consent.
        </li>
        <li>
          For solely internal uses that are reasonably aligned with your
          expectations based on your relationship with us.
        </li>
        <li>
          Comply with a legal obligation.
        </li>
        <li>
          Internally make otherwise lawful uses of your Personal Information that
          are compatible with the context in which you provided your Personal
          Information.
        </li>
      </ol>
      <h4 class="sub-title">How to Submit a Request to Know or a Request to Delete</h4>

      <p class="block">
        To request this information, please submit a verifiable consumer request to
        us by either:
      </p>
      <p class="block">
          Emailing us at <a href="mailto:privacy@reconn.ai">privacy@reconn.ai</a>
          and submit your request in the body of the email with the subject
          heading: “CCPA Consumer Request.”
        
      </p>
      <p class="block">
        Only you, or someone legally authorized to act on your behalf, may make a
        verifiable consumer request related to your Personal Information. We are
        not required to provide you with Personal Information more than twice in a
        12-month period.
      </p>
      <p class="block">
        We will not be able to respond to your request or provide you with Personal
        Information if we cannot verify your identity or authority to make the
        request and confirm the Personal Information relates to you.
      </p>
      <p class="block">
        We will try to respond to your request within forty-five (45) days of
        receipt of your written request. If we require more time (up to 90 days),
        we will inform you of the extension period in writing. We will deliver our
        written response by mail or electronically, at your option. Any disclosures
        we provide will only cover the 12-month period preceding the verifiable
        consumer request’s receipt. The response we provide will also explain the
        reasons we cannot comply with a request, if applicable. For data
        portability requests, we will select a format to provide your Personal
        Information that is readily useable and should allow you to transmit the
        information from one entity to another entity without hindrance,
        specifically by electronic mail communication.
      </p>
      <p class="block">
        We do not charge a fee to process or respond to your verifiable consumer
        request unless it is excessive, repetitive, or manifestly unfounded. If we
        determine that the request warrants a fee, we will tell you why we made
        that decision and provide you with a cost estimate before completing your
        request.
      </p>
      <h4 class="sub-title">Opt-Out Request</h4>

      <p class="block">
        Once you make an opt-out request, we will wait at least twelve (12) months
        before asking you to reauthorize personal information sharing with third
        parties. However, you may change your mind and opt back into personal
        information sharing with third parties at any time by emailing us at
        privacy@reconn.ai
      </p>
      <p class="block">
        We will only use Personal Information provided in an opt-out request to
        review and comply with the request. We will act upon your opt-out request
        within 15 days of receipt.
      </p>
	    <h4 class="sub-title">Non-Discrimination</h4>
      <p class="block">
        We will not discriminate against you for exercising any of your CCPA
        rights. Unless permitted by the CCPA, we will not:
      </p>
      <ul class="block-list">
        <li>
          Deny you goods or services, except to the extent that providing the
          Products requires the disclosure of Personal Information that you have
          decided not to disclose or allow us to use for the purpose of providing
          the Products.
        </li>
        <li>
          Charge you different prices or rates for goods or services, including
          through granting discounts or other benefits, or imposing penalties.
        </li>
        <li>
          Provide you a different level or quality of goods or services.
        </li>
        <li>
          Suggest that you may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ul>
        <p class="block">
          However, we may offer you certain financial incentives permitted by the
          CCPA that <span style="font-weight: bold">can result </span>in different prices, rates, or
          quality levels. Any CCPA-permitted financial incentive we offer will
          reasonably relate to your personal information's value and contain written
          terms that describe the program's material aspects. Participation in a
          financial incentive program requires your prior opt in consent, which you
          may revoke at any time.
        </p>

        <h3>19. YOUR NEVADA PRIVACY RIGHTS</h3>
        <p class="block">
          Nevada law (SB 220), permits customers in Nevada to opt-out of the sale of
          certain kinds of personal information. A sale under Nevada law is the
          transfer of this personal information to third parties for monetary
          consideration so these third parties can then re-sell or license the sold
          information. We do not sell your Personal Information to third parties as
          defined in Nevada law. If you are a Nevada resident and wish to opt-out of
          the sale of your personal information should we change our practices in the
          future, you must send a request by email to privacy@reconn.ai
        </p>
        <h3>20. AMENDMENTS</h3>
        <p class="block">
          Michigan Aerospace reserves the right to change the Privacy Policy from
          time to time at its sole discretion with or without notice. Any revisions
          are effective immediately once posted on the Site. Your continued use of
          the Site shall indicate your acceptance of the revision. Revisions to the
          Privacy Policy regarding the use of Personal Information are not
          retroactive.
        </p>
	      <h3>21. EUROPEAN UNION DISCLOSURE</h3>
        <p class="block">
          Personal information voluntarily submitted to Michigan Aerospace online,
          via electronic communication, or otherwise, may be maintained or accessed
          in servers or files in the United States of America, which the European
          Union has not deemed to provide “adequate” privacy protection. If you do
          not consent to having your information processed and stored in the United
          States of America, please do not provide it to Michigan Aerospace. Michigan
          Aerospace contends that the General Data Protection Regulation (EU)
          2016/679 does not apply to its processing of personal information and that
          it is not within the jurisdiction of the EU. All processing of PII is
          governed by the applicable laws of the United States of America.
        </p>

	      <h3>22. CONTACT</h3>

        <p class="block">
          If you have any questions about the Privacy Policy, or to report a
          violation of the Terms of Use, please contact us at:
        </p>
        <p class="block">
          Michigan Aerospace Corporation
        </p>
        <p class="block">
          PO BOX 981338
          
        </p>
        <p class="block">
          Ypsilanti, MI 481978
        </p>
        <p class="block">
          <a href="mailto:privacy@reconn.ai">privacy@reconn.ai</a>
        </p>

      </section>
    </div>
    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";


export default {
    components: {
        NavBar,
        FooterBar
    },

  props: [],

  data() {
    return {
      
    };
  },

  watch: {},

  methods: {

    
    
  },

  computed: {

  },

  mounted() {


  },
};
</script>

<style lang="scss">
  .terms {
    padding: 25px 0;
    flex-grow: 1;
    &__card {
      background: $off-white;
      width: 80%;
      margin: 25px auto;
      padding: 2em 5em 2em 4em;
      border-radius: 7px;
      border: 1px solid;
      border-color: $border-colors;
      box-shadow: 0 0 10px $grey;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      @media only screen and (max-width: 767px) {
          width: 92%;
          height: auto;
          padding: 1em;
      }
      @media only screen and (max-width: 415px) {
          width: 90%;
      }

      .sub-title {
        font-size: 1.2rem;
        font-style: italic;
        text-transform: capitalize;
        font-weight: 700;
      }

      .block {
        padding: 10px 25px;
      }

      .block-list {
        padding: 10px 50px;
        list-style-type: disc;
      }

      .block-list-alt {
        padding: 10px 50px;
        list-style-type: circle;
      }

      .block-list-num {
        padding: 10px 50px;
        list-style-type: decimal;
      }
    }
  }

</style>