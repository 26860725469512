<template>
  
    <div class="cookies" :style="{'top': top, 'left': left, 'bottom': bottom, 'right': right}">
        <h2 class="cookies__title">
          <slot name="title">

          </slot>
        </h2>
        <p class="cookies__statement">
          <slot name="info">

          </slot>
        </p>
        <div class="cookies__action">
          <slot name="actions">

          </slot>
        </div>
    </div>
  
</template>

<script>
  export default {
    name: 'InfoAlert',
    props: {
        top: null,
        left: null,
        bottom: null,
        right: null,
    },
    data() {
      return {
        
      }
    },
    methods: {
      
    },
    
  };
</script>

<style lang="scss">
.cookies {
    position: fixed;
    background: $off-white;
    color: $dark-grey;
    border-radius: 5px;
    box-shadow: 0 0 10px $black;
    width: 45%;
    height: 250px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    padding: 15px;
    &__title {
        text-align: left;
        margin: 25px 0 0 25px;
        color: $primary-color;
    }
    &__statement{
        text-align: left;
        padding: 25px;
    }

    &__action {
        padding: 25px;
        align-self: flex-start;
    }
}


</style>