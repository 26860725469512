<template>
  <div class="page">
    <nav-bar></nav-bar>
    <main class="dashboard">
        <div class="dashboard__top">
            <button @click="showModal('createOrg','org')" class="button transparent-white">
                <i class="fa-solid fa-plus"></i> <span>new organization</span>
            </button>
        </div>
        <section class="dashboard__row" v-for="org in orgs" :key="org.name" >

            <div class="dashboard__content">
                <div class="dashboard__content__header">
                    <h2>
                        <i v-if="org.name != 'Personal Projects'" class="fa-solid fa-building-user"></i>
                        <i v-else class="fa-solid fa-user"></i>
                        {{org.name}}
                    </h2>

                    <button class="button transparent" @click="showModal('createProject', org.organization_id)" >
                        <i class="fa-solid fa-plus"></i>
                        <span> Add Project</span>
                    </button>
                </div>

                <display-carousel :projects="check(org.projects)" @delete_project="confirmModal" v-if="org.projects.length >= 1"/>

                <div v-else class="no-projects">
                    No projects have been started yet.
                </div>
                <a v-if="org.name != 'Personal Projects'" @click="confirmModal('deleteOrg', org)" class="btn-delete">Delete</a>
            </div>
            <div class="dashboard__sidebar">
                <h2 class="dashboard__sidebar--heading"><span>Org Summary</span><i @click="showModal('inviteOrg', org.organization_id)" class="fa-solid fa-user-plus"></i></h2>
                <ul class="dashboard__sidebar--list">
                    <li class="dashboard__sidebar--list--item row">
                        <div class="stats-section">
                            <i class="fa-solid fa-clipboard"></i>
                            <h1>{{org.projects.length}}</h1>
                            <p>Projects</p>
                        </div>
                        <div class="stats-section">
                            <i class="fa-solid fa-images"></i>
                            <h1>{{countImages(org.projects)}}</h1>
                            <p>Images</p>
                        </div>
                    </li>

                    <li class="dashboard__sidebar--list--item">
                        <i class="fa-solid fa-users"></i>
                        <ul class="member-list">
                            <li v-for="member in getShortList(org)" :key="member.user_id">
                                <router-link :to="{ name: 'Profile', params: {user: member.username} }">
                                    <img v-if="member.avatar" :src="member.avatar" :alt="member.username" class="member-list--avatar" />
                                    <img v-else src="@/assets/images/no_image.png" :alt="member.username" class="member-list--avatar" />
                                </router-link>
                                <div class="member_name">{{member.username}}</div>
                            </li>
                            <li>
                                <div v-if="getShortList(org).length > 2" class="more-members">+ {{members.length - 2}}</div>
                            </li>
                       </ul>
                       <p>Members</p>
                       <p @click="userModal('manageUsers', org.members)" class="manage-members">Manage</p>
                    </li>
                </ul>

            </div>
        </section>
    </main>
    <footer-bar></footer-bar>
    <modal
        v-if="isModalVisible == 'createOrg'">
        <template v-slot:header >
            <h2>Create New Organization</h2>
            <p>Lets get started by filling in the information about the new organization.</p>
        </template>

        <template v-slot:body>
            <form id="new-org-form" @submit.prevent="closeModal" method="POST">
                <div class="input-container">
                    <label class="input-container--label">Name of Organization</label>
                    <input class="input-container--box" v-model="organization_name" required="required" type="text" ref="firstInput"/>
                    <p class="input-container--subtext">**The entered name is processed to match the required format.</p>
                </div>
                <div class="input-container">
                    <label class="input-container--label">Description</label>
                    <textarea class="input-container--area"></textarea>
                </div>
            </form>
        </template>

        <template v-slot:footer>
            <button
                type="submit"
                class="button"
                form="new-org-form">
                Create
            </button>
            <a @click="cancelModal" class="btn-close">Cancel</a>
        </template>
    </modal>
    <modal
        v-if="isModalVisible == 'manageUsers'" :isMedium="true">
        <template v-slot:header >
            <h2>Manage Users</h2>
        </template>

        <template v-slot:body>
            <ul class="users">
                <li class="users__list-item" v-for="user in members" :key="user.name">
                    <router-link :to="{ name: 'Profile', params: {user: user.username} }">
                        <img v-if="user.avatar" :src="user.avatar" :alt="user.username" class="users__list-item--avatar" />
                        <img v-else src="@/assets/images/no_image.png" :alt="user.username" class="users__list-item--avatar" />
                    </router-link>
                    <span class="users__list-item--name">{{user.username}}</span>
                    <multiselect v-model="user.permissions" :options="permissions" :searchable="false" :close-on-select="true" :show-labels="true" mode="single" placeholder="Choose a Permission"></multiselect>
                    <span class="users__list-item--delete" @click="deleteMember(user.user_id)"><i class="fa-solid fa-trash"></i></span>
                </li>
            </ul>
        </template>

        <template v-slot:footer>
            <a @click="cancelModal" class="btn-close">Close</a>
        </template>
    </modal>

    <modal
        v-if="isModalVisible == 'createProject'">
        <template v-slot:header >
            <h2>Create New Project</h2>
            <p>Lets get started by filling in the information about the new project.</p>
        </template>

        <template v-slot:body>
            <form id="new-pro-form" @submit.prevent="closeModal" method="POST">
                <div class="input-container">
                    <label class="input-container--label">Name of Project</label>
                    <input class="input-container--box" required="required" v-model="project_name" type="text" ref="firstInput"/>
                    <p class="input-container--subtext">**The entered name is processed to match the required format.</p>
                </div>
                <div class="input-container">
                    <label class="input-container--label">Summary</label>
                    <textarea class="input-container--area"></textarea>
                </div>
            </form>
        </template>

        <template v-slot:footer>
            <button
                type="submit"
                class="button"
                form="new-pro-form">
                Create
            </button>
            <a @click="cancelModal" class="btn-close">Cancel</a>
        </template>
    </modal>

    <modal
        v-if="isModalVisible == 'deleteOrg'">
        <template v-slot:header >
            <h2>Are you sure you want to delete this?</h2>
        </template>

        <template v-slot:body>
            <p>Delete {{delete_item.name}}?</p>
        </template>

        <template v-slot:footer>
            <button
                type="submit"
                class="button"
                @click="closeModal"
                @keydown.enter="closeModal"
                ref="deleteIt" >
                Delete
            </button>
            <a @click="cancelModal" class="btn-close">Cancel</a>
        </template>
    </modal>

    <modal
        v-if="isModalVisible == 'deleteProject'">
        <template v-slot:header >
            <h2>Are you sure you want to delete this?</h2>
        </template>

        <template v-slot:body>
            <p>Delete {{delete_item.name}}?</p>
        </template>

        <template v-slot:footer>
            <button
                type="submit"
                class="button"
                @click="closeModal"
                @keydown.enter="closeModal"
                ref="deleteIt" >
                Delete
            </button>
            <a @click="cancelModal" class="btn-close" >Cancel</a>
        </template>
    </modal>
    <modal
        v-if="isModalVisible == 'inviteOrg'">
        <template v-slot:header >
            <h2>Invite a New Member</h2>
        </template>

        <template v-slot:body>
            <i class="fa-solid fa-envelope"></i>
            <form id="invite-email" @submit.prevent="closeModal" method="POST">
                <div class="input-container">
                    <label class="input-container--label">Users Email:</label>
                    <input class="input-container--box" v-model="user_email" type="email" required="required" ref="firstInput"/>
                </div>
            </form>
        </template>

        <template v-slot:footer>
            <button
                type="submit"
                class="button"
                form="invite-email">
                Send Invite
            </button>
            <a @click="cancelModal" class="btn-close">Cancel</a>
        </template>
    </modal>

  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import DisplayCarousel from '@/components/DisplayCarousel.vue';
import FooterBar from '@/components/FooterBar.vue';
import Modal from '@/components/Modal.vue';
import PieChart from '@/components/PieChart.vue';
import Multiselect from '@vueform/multiselect';
import {get, post} from "@/scripts/samsa";
import "@/assets/styles/dashboard.scss";

export default {

    components: {
        NavBar,
        DisplayCarousel,
        FooterBar,
        Modal,
        PieChart,
        Multiselect
    },

    props: {
    },
    async mounted(){
      let orgs = await state.get_organizations();
      this.orgs = orgs;

    },
    data() {
        return {
            user: {
                username: "User Name",
                email: "email@email.com",
                website: "http://www.mywebsite.com",
                orgs: ["Example Corp", "Company INC"],
                bio: "",
                avatar: "https://via.placeholder.com/100x100",
                location: "Ypsilanti, MI"
            },
            project_name: "",
            organization_name: "",
            user_email: "",
            current_org: null,
            orgs: [],
            isModalVisible: "",
            delete_item: null,
            permissions: ["owner", "member"],
            members: [
                {
                    username: "jdfoe",
                    user_id: "",
                    avatar: "https://via.placeholder.com/100x100",
                    permissions: "member"
                },
                {
                    username: "sjohnson",
                    user_id: "",
                    avatar: "https://via.placeholder.com/100x100",
                    permissions: "member"
                },
                {
                    username: "clewis",
                    user_id: "",
                    avatar: "https://via.placeholder.com/100x100",
                    permissions: "member"
                },
                {
                    username: "lborst",
                    user_id: "",
                    avatar: "https://via.placeholder.com/100x100",
                    permissions: "member"
                }
            ]
        }
    },

    watch: {},

    methods: {
        check(projects){
          if(projects[0].name==null)
            return null
          else {
            return projects
          }
        },
        getShortList(org) {
          console.log("SHORT LIST", org, org.members, org.members.length)
          if(org.members.length>0)
            return org.members.slice(0, 2);
          else {
            return org.members
          }
        },
        showModal(contentType, id) {
            console.log(contentType,id);
            this.current_org = id
            this.isModalVisible = contentType;
            
            this.$nextTick(() => {
                this.$refs.firstInput.focus();
            })
            
        },
        confirmModal(itemType, item) {
            this.isModalVisible = itemType;
            this.delete_item = item;

            this.$nextTick(() => {
                this.$refs.deleteIt.focus();
            });
        },
        userModal(modalType, userList) {
            this.members = userList
            console.log("TRIGGER MEMBER MODAL")
            this.isModalVisible = modalType;
            //UNCOMMENT THIS WHEN ORG LEVEL USERS IS SET UP
            // this.members = userList;
        },
        async closeModal() {
            if(this.isModalVisible == "createProject") {
                let project = await state.create_project( this.project_name, this.current_org )
                 this.$router.push({
                     name: 'ProjectDetails',
                     params: { project_id:project.project_key }

                 });

            }else if(this.isModalVisible == "createOrg") {
                let org = await state.create_organization( this.organization_name);
                this.orgs[org.organization_id] = org;
                this.organization_name = "";

            }else if(this.isModalVisible == "deleteOrg") {
                let delete_org = await state.delete_organization(this.delete_item);
                console.log("DELETE", this.delete_item)
                delete this.orgs[this.delete_item.organization_id]


            }else if(this.isModalVisible == "deleteProject") {

                let delete_project = await state.delete_project(this.delete_item);
                console.log("DELETE", this.orgs.filter)
                for(var org_id in this.orgs){
                  let org = this.orgs[org_id]
                  if(org.projects.includes(this.delete_item)){
                    let idx = org.projects.indexOf(this.delete_item);
                    org.projects.splice(idx, 1);
                    break;
                  }
                }


            }else if(this.isModalVisible == "inviteOrg") {
                // let invite_to_org = await state.invite_member_org(this.user_email, this.current_org);
                post("invite_to_org",{},{email:this.user_email, organization:this.current_org})
                console.log("SEND AN INVITE",this.user_email, this.current_org);
            }

            this.isModalVisible = "";

        },

        cancelModal() {
            this.isModalVisible = "";
        },
        countImages(projects) {
            let images = 0;

                projects.forEach((project, index) => {
                    console.log(projects);
                    images += project.image_count;
                });

            return images;
        },
        // orgAnimalData(projects) {
        //     let newList = [];
        //     newList = [{animal: "bears", percent: "10.00"},{animal: "white tail deer",percent: "60.00"}, {animal: "rabbits", percent: "10.00" }, { animal: "ducks", percent: "5.00"}, {animal: "raccoons", percent: "15.00"}]
        //     //math to get percentages of animals and return that list
        //     console.log(newList);
        //     return newList;
        // }

    },

    computed: {

    },

};

</script>
