<template>
  <div class="page">
    <nav-bar></nav-bar>
        <div class="details--section" >
            <section class="card">

                <h3 class="details--title">
                    <span v-if="!editing" @click="enableEditing(selectedProject)">{{selectedProject.name}}</span>
                    <div v-else class="input-container title-adjust">
                    <input class="input-container--box" v-model="tempValue" />
                </div>
                    <button @click="showModal('inviteProject', selectedProject.project_id)" class="button">
                        <i class="fa-solid fa-plus"></i> Invite Member
                    </button>
                </h3>


                <div class="card--row">
                    <ul class="details__list">
                        <li class="details__list--item row">
                            <div class="stats-section">
                                <i class="fa-solid fa-object-group"></i>
                                <h1>{{selectedProject.collections.length}}</h1>
                                <p>Collections</p>
                            </div>
                            <div class="stats-section">
                                <i class="fa-solid fa-images"></i>
                                <h1>{{selectedProject.image_count}}</h1>
                                <p>Images</p>
                            </div>
                        </li>

                        <li class="details__list--item">
                            <i class="fa-solid fa-users"></i>
                            <ul class="member-list">
                                <li v-for="member in getShortList(2)" :key="member.user_id">
                                    <router-link :to="{ name: 'Profile', params: {user: member.username} }">
                                        <img v-if="member.avatar" :src="member.avatar" :alt="member.username" class="member-list--avatar" />
                                        <img v-else src="@/assets/images/no_image.png" :alt="member.username" class="member-list--avatar" />
                                    </router-link>
                                    <div class="member_name">{{member.username}}</div>
                                </li>
                                <li>
                                    <div v-if="selectedProject.members.length > 2" class="more-members">+ {{selectedProject.members.length - 2}}</div>
                                </li>
                            </ul>
                            <p>Members</p>
                            <p @click="userModal('manageUsers', selectedProject.members)" class="manage-members">Manage</p>
                        </li>
                    </ul>

                    <!-- <pie-chart :chartData="selectedProject.stats" ></pie-chart> -->
                    <bar-chart 
                        :data="selectedProject.stats" 
                        :element_id="'project_chart'"
                        :width="500"
                        :height="350"/>
                </div>
            </section>
            <section class="card">
                <h3 class="details--title">
                    <span>Collections</span>
                    <button @click="showModal('createCollection')" class="button"><i class="fa-solid fa-plus"></i> New Collection</button>
                </h3>
                <div class="collections">
                    <universal-card
                        v-for="(collection, index) in selectedProject.collections"
                        @viewImages="view(collection)"
                        @confirmModal="confirmModal"
                        :key="index"
                        :group="collection"
                        @openTags="open_tag_modal"
                        @collection_details="collection_details"
                    />
                </div>
            </section>
        </div>

        <modal v-if="isModalVisible == 'createCollection'">

            <template v-slot:header>
                <h2>Create New Collection</h2>
                <p>Lets get started by filling in the information about the new collection.</p>
            </template>

            <template v-slot:body>
                <form id="new-collec-form" @submit.prevent="closeModal" method="POST">
                    <div class="input-container">
                        <label class="input-container--label">Name of Collection</label>
                        <input v-model="collection_name" class="input-container--box" type="text" required="required" ref="firstInput"/>
                        <p class="input-container--subtext">**The entered name is processed to match the required format.</p>
                    </div>
                    <div class="input-container">
                        <label class="input-container--label">Summary</label>
                        <textarea v-model="collection_summary" class="input-container--area"></textarea>
                    </div>
                </form>
            </template>

            <template v-slot:footer>
                <button
                    type="submit"
                    class="button"
                    form="new-collec-form">
                    Create
                </button>
                <a @click="cancelModal" class="btn-close">Cancel</a>
            </template>
        </modal>

        <modal v-if="isModalVisible == 'removeCollection'">
            <template v-slot:header >
                <h2>Are you sure you want to delete this?</h2>
            </template>

            <template v-slot:body>
                <p>Delete {{delete_item.name}}?</p>
            </template>

            <template v-slot:footer>
                <button
                    type="submit"
                    class="button"
                    @click="closeModal"
                    @keydown.enter="closeModal"
                    ref="confirmDelete">
                    Delete
                </button>
                <a @click="cancelModal" class="btn-close">Cancel</a>
            </template>
        </modal>

        <modal v-if="isModalVisible == 'tags'">
            <template v-slot:header >
                <h2>Current Tags</h2>
            </template>

            <template v-slot:body>
                <div class="input-container">
                    <label class="input-container--label">Add Tag to All Images</label>
                    <div class="input-container--wrapper">
                        <input class="input-container--box" v-model="new_tag" type="text" ref="addCollTag" @keydown.enter="addCollectionTag"/>
                        <button
                            type="submit"
                            class="add-btn"
                            @click="addCollectionTag">
                            Add
                        </button>
                    </div>
                    <p class="input-container--subtext">**The entered text is processed to match the required format.</p>
                </div>
                <ul class="tag-list">
                    <li class="tag-list--item" v-for="(tag, idx ) in tagListFinal" :key="tag.name" :class="{'collection-color': tag.collection_id}" >
                        <p>{{ tag.name }} </p>
                        <i v-if="tag.collection_id" @click="deleteCollectionTag(tag, idx)" class="fa-solid fa-xmark"></i>
                    </li>
                </ul>
            </template>

            <template v-slot:footer>
                <a @click="cancelModal" class="btn-close">Close</a>
            </template>
        </modal>
        <modal
            v-if="isModalVisible == 'inviteProject'">
            <template v-slot:header >
                <h2>Invite a New Member</h2>
            </template>

            <template v-slot:body>
                <i class="fa-solid fa-envelope"></i>
                <form id="send-email" @submit.prevent="closeModal" method="POST">
                    <div class="input-container">
                        <label class="input-container--label">Users Email:</label>
                        <input class="input-container--box" v-model="user_email" type="email" ref="firstInput" require="require" />
                    </div>
                </form>
            </template>

            <template v-slot:footer>
                <button
                    type="submit"
                    class="button"
                    form="send-email">
                    Send Invite
                </button>
                <a @click="cancelModal" class="btn-close">Cancel</a>
            </template>
        </modal>
        <modal
            v-if="isModalVisible == 'manageUsers'" :isMedium="true">
            <template v-slot:header >
                <h2>Manage Users</h2>
            </template>

            <template v-slot:body>
                <ul class="users">
                    <li class="users__list-item" v-for="user in members" :key="user.name">
                        <router-link :to="{ name: 'Profile', params: {user: user.username} }">
                            <img v-if="user.avatar" :src="user.avatar" :alt="user.username" class="users__list-item--avatar" />
                            <img v-else src="@/assets/images/no_image.png" :alt="user.username" class="users__list-item--avatar" />
                        </router-link>
                        <span class="users__list-item--name">{{user.username}}</span>
                        <multiselect v-model="user.permissions" :options="permissions" :searchable="false" :close-on-select="true" :show-labels="true" mode="single" placeholder="Choose a Permission"></multiselect>
                        <span class="users__list-item--delete" @click="deleteMember(user.user_id)"><i class="fa-solid fa-trash"></i></span>
                    </li>
                </ul>
            </template>

            <template v-slot:footer>
                <a @click="cancelModal" class="btn-close">Close</a>
            </template>
        </modal>
            <!-- COLECTIONS DETAILS MODAL-->
        <modal
            v-if="isModalVisible == 'collectionInfo'" :isXL="true">
            <template v-slot:header >
                <h2>{{details.name}}</h2>
            </template>

            <template v-slot:body>
               
                <section class="details-summary">
                    <h3>Summary</h3>
                    <div v-if="!editingSummary" @click="editSummary(details)">
                        <p v-if="details.summary">{{details.summary}}</p>
                        <p v-else>Write a summary here.</p>
                    </div>
                    <div v-else>
                        <textarea v-model="tempSummary" class="input-container--area" ref="summary"></textarea>
                    </div>
                </section>
                <section class="modal--body__collections">
                    <div class="collections-column">
                        <div class="collections-column__stats-section">
                            <i class="fa-solid fa-images"></i>
                            <h1>{{image_count}}</h1>
                            <p>Images</p>
                        </div>
                        <div class="collections-column__tag-list">
                            <h2>Tags</h2>
                            <ul class="list">
                                <li class="list--item" v-for="tag in details_tags" :key="tag.name" :class="{'collection-color': tag.collection_id}" >
                                    <p>{{ tag.name }} </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="collections-column-table">
                        <bar-chart 
                            :data="collection_data" 
                            :element_id="'modal_chart'"
                            :width="500"
                            :height="350"/>
                    </div>
                </section>
            </template>

            <template v-slot:footer>
                <a @click="cancelModal" class="btn-close">Close</a>
            </template>
        </modal>

    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Modal from '@/components/Modal.vue';
import UniversalCard from "@/components/UniversalCard.vue";
import FooterBar from "@/components/FooterBar.vue";
// import PieChart from "@/components/PieChart.vue";
import BarChart from '@/components/BarChart.vue';
import Multiselect from '@vueform/multiselect';
import { get, post } from "@/scripts/samsa"

import "@/assets/styles/details-pages.scss";


export default {

    components: {
        NavBar,
        Modal,
        UniversalCard,
        FooterBar,
        // PieChart,
        BarChart,
        Multiselect
    },

    props: {
      project_id: null,
    },

    data() {
        return {
            user: {
                username: "Chad Lewis",
                email: "clewis@michaero.com",
                website: "http://MichiganAerospace.com",
                orgs: ["Example Corp", "Company INC"],
                bio: "",
                avatar: "https://via.placeholder.com/100x100",
                location: "Ypsilanti, MI"
            },
            selectedProject: {
                name: "Project One",
                date: "04/18/2022",
                members: [
                    {
                        name: "john smith",
                        avatar: "https://via.placeholder.com/100x100"
                    },
                    {
                        name: "mery terry",
                        avatar: "https://via.placeholder.com/100x100"
                    },
                ],
                // stats used for pie chart
                stats: [
                    {
                        animal: "", // No Data
                        percent: ""
                    },
                ],
                collections: [],
                summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed consequat neque. Donec euismod luctus nisl, id porttitor quam finibus eget. Donec blandit dignissim ipsum pretium faucibus. Donec bibendum quis odio fringilla porta. Pellentesque a risus eu nisi tincidunt efficitur. Mauris efficitur sapien eget pharetra scelerisque. Phasellus ac orci ut mauris interdum bibendum ac sit amet felis. Integer vestibulum mattis ligula.",
            },
            isModalVisible: "",
            collection_name: null,
            collection_summary: null,
            delete_item: null,
            editing: false,
            editingSummary: false,
            tempValue: null,
            tempSummary: null,
            collection_id: "",
            new_tag: "",
            // tagGroup: null,
            tagListFinal: null,
            user_email: "",
            members: [],
            image_count: 0,
            details: null,
            details_tags: null,
            collection_data: [],
            selected_collection: null
            
        }
    },

    watch: {},

    async mounted(){
        this.selectedProject = await state.get_project(this.project_id);
        // this.tagGroup = this.selectedProject.tagData;
        console.log("PROJECT DETAILS", this.selectedProject);

        // get chart data from backend
        let chart = await get("project-data", { project_id: this.project_id });
        chart.data = chart.data.slice(0,5);
        this.selectedProject.stats = chart.data;
    },

    methods: {
        getShortList(shortListSize) {
            return this.selectedProject.members.slice(0, shortListSize);
        },
        limit(images){
            return images.splice(0,3)
        },
        view(collection){
            console.log("VIEW")
            this.$router.push({
                name: 'Annotations',
                params: { collection_id:collection.collection_key }
            });
        },
        userModal(modalType, userList) {
            this.isModalVisible = modalType;
            this.members = userList;
        },
        showModal(contentType) {
            console.log(contentType);
            this.isModalVisible = contentType;
            this.$nextTick(() => {
                this.$refs.firstInput.focus();
            });
        },
        async collection_details(collection, tags) {
            //COLLECTION DETAILS MODAL OPEN
            
            this.image_count = collection.image_count;
            this.details_tags = tags;
            this.details = collection;
            this.isModalVisible = "collectionInfo";
            console.log(collection.collection_id);
            let chart = await get("collection-data", { collection_id: collection.collection_id });
            
            console.log(chart.data);
            this.collection_data = chart.data.slice(0,5);
            this.selected_collection = collection;
        },
        
        confirmModal(itemType, item) {
            this.isModalVisible = itemType;
            this.delete_item = item;
            this.$nextTick(() => {
                this.$refs.confirmDelete.focus();
            });
        },
        async closeModal() {
            if(this.isModalVisible == "createCollection") {
                let collection = await state.create_collection(this.project_id, this.collection_name, this.collection_summary);

                this.$router.push({
                    name: 'AddImages',
                    params: { collection_id:collection.collection_key }

                });
            }else if(this.isModalVisible == "removeCollection") {
                let delete_collection = await state.delete_collection(this.delete_item);
                
                let idx = this.selectedProject.collections.map(item => item.id).indexOf(this.delete_item.collection_id);
                this.selectedProject.collections.splice(idx, 1);

            }else if(this.isModalVisible == "inviteProject") {
                post("invite_to_project",{},{email:this.user_email, project_id:this.project_id})
                // let invite_to_project = await state.invite_member_project(this.user_email, this.project_id);
                console.log("SEND AN INVITE");
            }

            this.isModalVisible = "";

        },
        cancelModal() {
            this.isModalVisible = "";
        },

        enableEditing: function(){
            this.tempValue = this.selectedProject.name;
            this.editing = true;
            window.addEventListener("mousedown", this.saveEdit);

        },
        disableEditing: function(){
            this.tempValue = null;
            this.editing = false;
            console.log("disable editing");
        },
        async saveEdit(evt){

            if(evt.target.tagName == "INPUT") {
                return
            }
            
            let newName = await state.update_project_name(this.tempValue, this.selectedProject);

            if(newName) {
                this.selectedProject.name = newName;

                this.disableEditing();
                window.removeEventListener("mousedown", this.saveEdit);
            }

        },
        saveSummary(evt) {
            this.saveNewSummary(evt, this.selected_collection);
        },
        editSummary: function(){
            
            this.tempSummary = this.selected_collection.summary;
            this.editingSummary = true;
            this.$nextTick(() => {
                this.$refs.summary.focus();
            })
            window.addEventListener("mousedown", this.saveSummary);

        },
        noEditSummary: function(){
            this.tempSummary = null;
            this.editingSummary = false;
            console.log("disable editing");
            
        },
        async saveNewSummary(evt, collection){
            console.log(evt.target);
            if(evt.target.tagName == "TEXTAREA") {
                return
            }

            let newSummary = await state.update_collection_summary(this.tempSummary, collection);

            if(newSummary) {
                collection.summary = newSummary;
                
                this.noEditSummary();
                window.removeEventListener("mousedown", this.saveSummary);
            }
            
            

        },
        open_tag_modal(tags, id) {
            console.log("event fired project details", tags)
            this.tagListFinal = tags;
            this.collection_id = id;
            this.isModalVisible = 'tags';
            this.$nextTick(() => {
                this.$refs.addCollTag.focus();
            })
        },

        async addCollectionTag() {
            let add_collection_tags = await state.add_tag_to_collection(this.collection_id, this.new_tag);
            console.log(add_collection_tags)
            if(add_collection_tags) {
                this.tagListFinal.push(add_collection_tags);
                this.new_tag = "";
            }

        },

        async deleteCollectionTag(tag, idx) {
            console.log(tag)
            let remove_tag = await state.delete_collection_tag(tag);

            this.tagListFinal.splice(idx, 1);
        }

    },

    computed: {
    }

};

</script>

<style lang="scss">
.member_name{
  font-size:12px;
  text-align: center;
}
</style>
