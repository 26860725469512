<template>
  <div class="page">
    <nav-bar></nav-bar>
    <div class="terms">
      <section class="terms__card">
        <h2>Software As A Service Agreement</h2>
        <p class="block">
	        This Software as a Service Agreement (the “<span style="font-weight: bold;">Agreement</span>”),
          effective as of the last date below written (the “<span style="font-weight: bold;">Effective Date</span>”), 
          is by and between	<span style="font-weight: bold;">MICHIGAN AEROSPACE CORPORATION</span>, a Michigan corporation
	        with offices located at 301 W. Michigan Ave., Ste. 319, Ypsilanti, Michigan
	        48197 (“<span style="font-weight: bold;">Provider</span>”) and you (“<span style="font-weight: bold;">Customer</span>”).
        </p>
        <h3>Recitals:</h3>
        <p class="block">
          A. Provider provides access to its software-as-a-service offerings to its
          customers.
        </p>
        <p class="block">
          B. Customer desires to access certain software-as-a-service offerings
          described herein, and Provider desires to provide Customer access to such
          offerings, subject to the terms and conditions set forth in this Agreement.
        </p>
        <p class="block">
          NOW, THEREFORE, in consideration of the mutual covenants, terms, and
          conditions set forth herein, and for other good and valuable consideration,
          the receipt and sufficiency of which are hereby acknowledged, the parties
          agree as follows:
        </p>
        <h3>1. Definitions.</h3>
        <p class="block">
          
            “<span style="font-weight: bold;">Access Credentials</span>” means any user name,
            identification number, password, license or security key, security
            token, PIN, or other security code, method, technology, or device, used
            alone or in combination, to verify an individual’s identity and
            authorization to access and use the Services.
          
        </p>
        <p class="block">
          
            “<span style="font-weight: bold;">Action</span>” means any claim, action, cause of action,
            demand, lawsuit, arbitration, inquiry, audit, notice of violation,
            proceeding, litigation, citation, summons, subpoena, or investigation
            of any nature, civil, criminal, administrative, regulatory, or other,
            whether at law, in equity, or otherwise.

        </p>
        <p class="block">
	
          “<span style="font-weight: bold;">Affiliate</span>” of a Person means any other Person that
          directly or indirectly, through one or more intermediaries, controls,
          is controlled by, or is under common control with, such Person. The
          term “control” (including the terms “controlled by” and “under common
          control with”) means the direct or indirect power to direct or cause
          the direction of the management and policies of a Person, whether
          through the ownership of voting securities, by contract, or otherwise.
        
      </p>

      <p class="block">
        “<span style="font-weight: bold;">Agreement</span>” has the meaning set forth in the preamble.
      </p>
      <p class="block">
        
          “<span style="font-weight: bold;">Authorized Users</span>” means Customer’s employees,
          consultants, contractors, and agents (a) who are authorized by Customer
          to access and use the Services under the rights granted to Customer
          pursuant to this Agreement; and (b) for whom access to the Services has
          been purchased hereunder.
        
      </p>
      <p class="block">
        
        “<span style="font-weight: bold;">Availability Requirement</span>” has the meaning set forth in
        Section 5.1.
      </p>
      <p class="block">

          “<span style="font-weight: bold;">Available</span>” has the meaning set forth in Section 5.1.
      </p>
      <p class="block">
        
          “<span style="font-weight: bold;">Backup Policy</span>” has the meaning set forth in Section 6.
      </p>
      <p class="block">
          “<span style="font-weight: bold;">Confidential Information</span>” has the meaning set forth in Section 9.1.
      </p>
      <p class="block">
          “<span style="font-weight: bold;">Customer</span>” has the meaning set forth in the preamble.
      </p>
      <p class="block">
          “<span style="font-weight: bold;">Customer Data</span>” means information, data, and other
          content, in any form or medium, that is collected, downloaded, or
          otherwise received, directly or indirectly, from Customer or an
          Authorized User by or through the Services. For the avoidance of doubt,
          Customer Data does not include Resultant Data or any other information
          reflecting the access or use of the Services by or on behalf of
          Customer or any Authorized User.
      </p>
      <p class="block">
          “<span style="font-weight: bold;">Customer Failure</span>” has the meaning set forth in Section 4.2.
      </p>
      <p class="block">
		    “<span style="font-weight: bold;">Customer Indemnitee</span>” has the meaning set forth in Section 12.1.
      </p>
      <p class="block">
          “<span style="font-weight: bold;">Customer Systems</span>” means the Customer’s information
          technology infrastructure, including computers, software, hardware,
          databases, electronic systems (including database management systems),
          and networks, whether operated directly by Customer or through the use
          of third-party services.
      </p>
    <p class="block">
        “<span style="font-weight: bold;">Disclosing Party</span>” has the meaning set forth in Section 9.1.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Documentation</span>” means any manuals, instructions, or
        other documents or materials that the Provider provides or makes
        available to Customer in any form or medium and which describe the
        functionality, components, features, or requirements of the Services or
        Provider Materials, including any aspect of the installation,
        configuration, integration, operation, use, support, or maintenance
        thereof.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Effective Date</span>” has the meaning set forth in the
        preamble.
    </p>

    <p class="block">
      “<span style="font-weight: bold;">Exceptions</span>” has the meaning set forth in Section 5.1.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Fees</span>” has the meaning set forth in Section 8.1.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Force Majeure Event</span>” has the meaning set forth in Section 15.9.
    </p>
    <p class="block">
      “<span style="font-weight: bold;">Harmful Code</span>” means any software, hardware, or other
      technology, device, or means, including any virus, worm, malware, or
      other malicious computer code, the purpose or effect of which is to (a)
      permit unauthorized access to, or to destroy, disrupt, disable,
      distort, or otherwise harm or impede in any manner any (i) computer,
      software, firmware, hardware, system, or network; or (ii) any
      application or function of any of the
      foregoing or the security, integrity, confidentiality, or use of any data
      Processed thereby; or (b) prevent Customer or any Authorized User from
      accessing or using the Services or Provider Systems as intended by this
      Agreement. Harmful Code does not include any Provider Disabling Device.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Indemnitee</span>” has the meaning set forth in Section 12.3.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Indemnitor</span>” has the meaning set forth in Section 12.3.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Initial Term</span>” has the meaning set forth in Section 14.1.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Intellectual Property Rights</span>” means any and all
        registered and unregistered rights granted, applied for, or otherwise
        now or hereafter in existence under or related to any patent,
        copyright, trademark, trade secret, database protection, or other
        related laws, and all similar or equivalent rights or forms of
        protection, in any part of the world.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Law</span>” means any statute, law, ordinance, regulation,
        rule, code, order, constitution, treaty, common law, judgment, decree,
        or other requirement of any federal, state, local, or foreign
        government or political subdivision thereof, or any arbitrator, court,
        or tribunal of competent jurisdiction.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Losses</span>” means any and all losses, damages,
        deficiencies, claims, actions, judgments, settlements, interest,
        awards, penalties, fines, costs, or expenses of whatever kind,
        including reasonable attorneys’ fees and the costs of enforcing any
        right to indemnification hereunder and the cost of pursuing any
        insurance providers.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Person</span>” means an individual, corporation,
        partnership, joint venture, limited liability entity, governmental
        authority, unincorporated organization, trust, association, or other
        entity.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Personal Information</span>” means information that Customer
        provides or for which Customer provides access to Provider, or
        information which Provider creates or obtains on behalf of Customer, in
        accordance with this Agreement that: (i) directly or indirectly
        identifies an individual (including, for example, names, signatures,
        addresses, telephone numbers, email addresses, and other unique
        identifiers); or (ii) can be used to authenticate an individual
        (including, without limitation, employee identification numbers,
        government-issued identification numbers, passwords or PINs, user
        identification and account access credentials or passwords, financial
        account numbers, credit report information, student information,
        biometric, genetic, health, or health insurance data, answers to
        security questions, and other personal identifiers, in case of both
        subclauses (i) and (ii), including Sensitive Personal Information as
        defined herein). Customer’s business contact information is not by
        itself Personal Information.
    </p>

    <p class="block">
      “<span style="font-weight: bold;">Process</span>” means to take any action or perform any
      operation or set of operations that the Services are capable of taking or
      performing on any data, information, or other content.
    </p>
    <p class="block">
      “<span style="font-weight: bold;">Processing</span>” and “<span style="font-weight: bold;">Processed</span>” have
      correlative meanings.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Provider</span>” has the meaning set forth in the preamble.
    </p>
    <p class="block">
      “<span style="font-weight: bold;">Provider Disabling Device</span>” means any software,
      hardware, or other technology, device, or means (including any back
      door, time bomb, time out, drop dead device, software routine, or other
      disabling device) used by Provider or its designee to disable
      Customer’s or any Authorized User’s access to or use of the Services
      automatically with the passage of time or under the positive control of
      Provider or its designee.
    </p>
    <p class="block">
		  “<span style="font-weight: bold;">Provider Indemnitee</span>” has the meaning set forth in Section 12.2.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Provider Materials</span>” means the Services,
        Specifications, Documentation, and Provider Systems and any and all
        other information, data, documents, materials, works, and other
        content, devices, methods, processes, hardware, software, and other
        technologies and inventions, including any deliverables, technical or
        functional descriptions, requirements, plans, or reports, that are
        provided or used by Provider or any Subcontractor in connection with
        the Services or otherwise comprise or relate to the Services or
        Provider Systems. For the avoidance of doubt, Provider Materials
        include Resultant Data and any information, data, or other content
        derived from Provider’s monitoring of Customer’s access to or use of
        the Services, but do not include Customer Data.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Provider Personnel</span>” means all individuals involved in
        the performance of Services as employees, agents, or independent
        contractors of Provider or any Subcontractor.
    </p>
    <p class="block">
      “<span style="font-weight: bold;">Provider Systems</span>” means the information technology
      infrastructure used by or on behalf of Provider in performing the
      Services, including all computers, software, hardware, databases,
      electronic systems (including database management systems), and
      networks, whether operated directly by Provider or through the use of
      third-party services.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Receiving Party</span>” has the meaning set forth in Section 9.1.
    </p>
    <p class="block">
		  “<span style="font-weight: bold;">Renewal Term</span>” has the meaning set forth in Section 14.2.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Representatives</span>” means, with respect to a party, that
        party’s and its Affiliates’ employees, officers, directors,
        consultants, agents, independent contractors, service providers,
        sublicensees, subcontractors, and legal advisors.
    </p>
    <p class="block">
      “<span style="font-weight: bold;">Resultant Data</span>” means data and information related to
      Customer’s use of the Services that is used by Provider in an aggregate and
      anonymized manner, including to compile statistical and performance
      information related to the provision and operation of the Services.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Scheduled Downtime</span>” has the meaning set forth in Section 5.3.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Sensitive Personal Information</span>” means an individual’s
        (i) government-issued identification number, including Social Security
        number, driver’s license number, or state-issued identification number;
        (ii) financial account number, credit report information, or credit,
        debit, or other payment cardholder information, with or without any
        required security or access code, personal identification number, or
        password that permits access to the individual’s financial account; or
        (iii) biometric, genetic, health, or health insurance data.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Service Credit</span>” has the meaning set forth in Section 5.2.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Service Level Failure</span>” has the meaning set forth in Section 5.1.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Service Period</span>” has the meaning set forth in Section 5.1.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Services</span>” means the software-as-a-service offering
        described in <span style="font-weight: bold;">Exhibit A</span>.
    </p>
    <p class="block">
		  “<span style="font-weight: bold;">Specifications</span>” means the specifications for the
		  Services set forth in <span style="font-weight: bold;">Exhibit B</span>
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Subcontractor</span>” has the meaning set forth in Section 2.7.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Support Exhibit</span>” has the meaning set forth in Section 5.4.
    </p>
    <p class="block">
      
        “<span style="font-weight: bold;">Support Services</span>” has the meaning set forth in Section 5.4.
    </p>
    <p class="block">
      “<span style="font-weight: bold;">Term</span>” has the meaning set forth in Section 14.2.
    </p>
    <p class="block">
        “<span style="font-weight: bold;">Third-Party Materials</span>” means materials and
        information, in any form or medium, including any software, documents,
        data, content, specifications, products, equipment, or components of or
        relating to the Services that are not proprietary to Provider.
    </p>

	  <h3>2. Services.</h3>

    <p class="block">
        2.1 Access and Use. Subject to and conditioned on Customer’s and its
        Authorized Users’ compliance with the terms and conditions of this
        Agreement, Provider hereby grants Customer a non-exclusive,
        non-transferable (except in compliance with
      Section 15.8) right to access and use the Services
      during the Term, solely for use by Authorized Users in accordance with the
      terms and conditions herein. Such use is limited to Customer’s internal
      use. Provider will provide to Customer the Access Credentials within a
      reasonable time following the Effective Date.
    </p>
    <p class="block">
        2.2 Documentation License. Provider hereby grants to Customer a
        non-exclusive, non-sublicensable, non-transferable (except in
        compliance with
        Section 15.8) license to use the Documentation during the Term solely for
        Customer’s internal business purposes in connection with its use of the
    </p>
    <p class="block">
        2.3 Service and System Control. Except as otherwise expressly provided
        in this Agreement, as between the parties:
    </p>
    <p class="block">
        (a) Provider has and will retain sole control over the operation,
        provision, maintenance, and management of the Provider Materials; and
    </p>
    <p class="block">
        (b) Customer has and will retain sole control over the operation,
        maintenance, and management of, and all access to and use of, the
        Customer Systems, and sole responsibility for all access to and use of
        the Provider Materials by any Person by or through the Customer Systems
        or any other means controlled by Customer or any Authorized User,
        including any: (i) information, instructions, or materials provided by
        any of them to the Services or Provider; (ii) results obtained from any
        use of the Services or Provider Materials; and (iii) conclusions,
        decisions, or actions based on such use.
    </p>

    <p class="block">
      2.4 Reservation of Rights. Nothing in this Agreement grants any right,
      title, or interest in or to (including any license under) any Intellectual
      Property Rights in or relating to, the Services, Provider Materials, or
      Third-Party Materials, whether expressly, by implication, estoppel, or
      otherwise. All right, title, and interest in and to the Services, the
      Provider Materials, and the Third-Party Materials are and will remain with
      Provider and the respective rights holders in the Third-Party Materials.	<u></u>
    </p>
    <p class="block">
        2.5 Service Management. Each party will, throughout the Term, maintain
        within its organization a service manager to serve as such party’s
        primary point of contact for day-to-day communications, consultation,
        and decision-making regarding this Agreement. Each service manager will
        be responsible for providing all day-to-day consents and approvals on
        behalf of such party under this Agreement. Each party will ensure its
        service manager has the requisite organizational authority, skill,
        experience, and other qualifications to perform in such capacity. If
        either party’s service manager ceases to be employed by such party or
        such party otherwise wishes to replace its service manager, such party
        will promptly name a new service manager by written notice to the other
        party.
    </p>
    <p class="block">
      2.6 Changes. Provider reserves the right, in its sole discretion, to
      make any changes to the Services and Provider Materials that it deems
      necessary or useful to: (a) maintain or enhance: (i) the quality or
      delivery of Provider’s services to its customers; (ii) the competitive
      strength of or market for Provider’s services; or (iii) the Services’
      cost
      efficiency or performance; or (b) to comply with applicable Law. Without
      limiting the foregoing, either party may, at any time during the Term,
      request in writing changes to the Services.
    </p>
    <p class="block">
		  2.7 Subcontractors. Provider may from time to time in its discretion
      engage third parties to perform Services (each, a “<span style="font-weight: bold;">Subcontractor</span>”).
    </p>
    <p class="block">
        2.8 Suspension or Termination of Services. Provider may, directly or
        indirectly, and by use of a Provider Disabling Device or any other
        lawful means, suspend, terminate, or otherwise deny Customer’s, any
        Authorized User’s, or any other Person’s access to or use of all or any
        part of the Services or Provider Materials, without incurring any
        resulting obligation or liability, if: (a) Provider receives a judicial
        or other governmental demand or order, subpoena, or law enforcement
        request that expressly or by reasonable implication requires Provider
        to do so; or (b) Provider believes, in its sole discretion, that: (i)
        Customer or any Authorized User has failed to comply with any term of
        this Agreement, or accessed or used the Services beyond the scope of
        the rights granted or for a purpose not authorized under this Agreement
        or in any manner that does not comply with any instruction or
        requirement of the Specifications; (ii) Customer or any Authorized User
        is, has been, or is likely to be involved in any fraudulent,
        misleading, or unlawful activities; or (iii) this Agreement expires or
        is terminated. This Section 2.8 does not limit any of Provider’s other
        rights or remedies, whether at law, in equity, or under this Agreement.
    </p>

	  <h3>3. Use Restrictions.</h3>
    <p class="block">
      Customer will not, and will not permit any other Person to, access or
      use the Services or Provider Materials except as expressly permitted by
      this Agreement and, in the case of Third-Party Materials, the
      applicable third-party license agreement. For purposes of clarity and
      without limiting the generality of the foregoing, Customer will not,
      except as this Agreement expressly permits:
    </p>
    <p class="block">
        (a) copy, modify, or create derivative works or improvements of the
        Services or Provider Materials;
    </p>
    <p class="block">
        (b) rent, lease, lend, sell, sublicense, assign, distribute, publish,
        transfer, or otherwise make available any Services or Provider
        Materials to any Person, including on or in connection with the
        internet or any time-sharing, service bureau, software as a service,
        cloud, or other technology or service;
    </p>
    <p class="block">
        (c) reverse engineer, disassemble, decompile, decode, adapt, or
        otherwise attempt to derive or gain access to the source code of the
        Services or Provider Materials, in whole or in part;
    </p>
    <p class="block">
        (d) bypass or breach any security device or protection used by the
        Services or Provider Materials or access or use the Services or
        Provider Materials other than by an Authorized User through the use of
        his or her own then valid Access Credentials;
    </p>
    <p class="block">
      (e) input, upload, transmit, or otherwise provide to or through the
      Services or Provider Systems, any information or materials that are
      unlawful or injurious, or contain, transmit, or activate any Harmful Code;
    </p>
    <p class="block">
        (f) damage, destroy, disrupt, disable, impair, interfere with, or
        otherwise impede or harm in any manner the Services, Provider Systems,
        or Provider’s provision of services to any third party, in whole or in
        part;
    </p>
    <p class="block">
        (g) remove, delete, alter, or obscure any trademarks, Specifications,
        Documentation, EULA, warranties, or disclaimers, or any copyright,
        trademark, patent, or other intellectual property or proprietary rights
        notices from any Services or Provider Materials, including any copy
        thereof;
    </p>
    <p class="block">
        (h) access or use the Services or Provider Materials in any manner or
        for any purpose that infringes, misappropriates, or otherwise violates
        any Intellectual Property Right or other right of any third party
        (including by any unauthorized access to, misappropriation, use,
        alteration, destruction, or disclosure of the data of any other
        Provider customer), or that violates any applicable Law;
    </p>
    <p class="block">
        (i) access or use the Services or Provider Materials for purposes of
        competitive analysis of the Services or Provider Materials, the
        development, provision, or use of a competing software service or
        product or any other purpose that is to the Provider’s detriment or
        commercial disadvantage;
    </p>
    <p class="block">
        (j) access or use the Services or Provider Materials in, or in
        association with, the design, construction, maintenance, or operation
        of any hazardous environments, systems, or applications, any safety
        response systems or other safety-critical applications, or any other
        use or application in which the use or failure of the Services could
        lead to personal injury or severe physical or property damage; or
    </p>
    <p class="block">
        (k) otherwise access or use the Services or Provider Materials beyond
        the scope of the authorization granted under this Section 3.
    </p>

	  <h3>4. Customer Obligations.</h3>
    <p class="block">
        4.1 Customer Systems and Cooperation. Customer will at all times during
        the Term: (a) set up, maintain, and operate in good repair and in
        accordance with the Specifications all Customer Systems on or through
        which the Services are accessed or used; (b) provide Provider Personnel
        with such access to Customer’s premises and Customer Systems as is
        necessary for Provider to perform the Services in accordance with the
        Availability Requirement and Specifications; and (c) provide all
        cooperation and assistance as Provider may reasonably request to enable
        Provider to exercise its rights and perform its obligations under and
        in connection with this Agreement.
    </p>
    <p class="block">
        4.2 Effect of Customer Failure or Delay. Provider is not responsible or
        liable for any delay or failure of performance caused in whole or in
        part by Customer’s delay in
        performing, or failure to perform, any of its obligations under this
        Agreement (each, a “<span style="font-weight: bold;">Customer Failure</span>”).
    </p>
    <p class="block">
      4.3 Corrective Action and Notice. If Customer becomes aware of any
      actual or threatened activity prohibited by
      Section 3, Customer will, and will cause its Authorized Users to,
      immediately: (a) take all reasonable and lawful measures within their
      respective control that are necessary to stop the activity or threatened
      activity and to mitigate its effects (including, where applicable, by
      discontinuing and preventing any unauthorized access to the Services and
      Provider Materials and permanently erasing from their systems and
      destroying any data to which any of them have gained unauthorized access);
      and (b) notify Provider of any such actual or threatened activity.
    </p>
    <p class="block">
        4.4 Non-Solicitation. During the Term and for two (2) years after,
        Customer will not, and will not assist any other Person to, directly or
        indirectly, recruit or solicit (other than by general advertisement not
        directed specifically to any Person or Persons) for employment or
        engagement as an independent contractor any Person then or within the
        prior twelve (12) months employed or engaged by Provider or any
        Subcontractor and involved in any respect with the Services or the
        performance of this Agreement. In the event of a violation of this
        Section 4.4, Provider will be entitled to liquidated damages equal to
        the compensation paid by Provider to the applicable employee or
        contractor during the prior twelve (12) months.
    </p>
	  <h3>5. Service Levels and Credits.</h3>
    <p class="block">
        5.1 Service Levels. Subject to the terms and conditions of this
        Agreement, Provider will use commercially reasonable efforts to make
        the Services Available at least ninety-nine and one half percent
        (99.5%) of the time as measured over the course of each calendar month
        during the Term (each such calendar month, a “		<span style="font-weight: bold;">Service Period</span>”), excluding unavailability as a result
        of any of the Exceptions described below in this Section 5.1 (the “<span style="font-weight: bold;">Availability Requirement</span>”). “		<span style="font-weight: bold;">Service Level Failure</span>” means a material failure of the
        Services to meet the Availability Requirement. “		<span style="font-weight: bold;">Available</span>” means the Services are available for access
        and use by Customer and its Authorized Users over the Internet and
        operating in material accordance with the Specifications. For purposes
        of calculating the Availability Requirement, the following are “		<span style="font-weight: bold;">Exceptions</span>” to the Availability Requirement, and
        neither the Services will be considered un-Available nor any Service
        Level Failure be deemed to occur in connection with any failure to meet
        the Availability Requirement or impaired ability of Customer or its
        Authorized Users to access or use the Services that is due, in whole or
        in part, to any: (a) access to or use of the Services by Customer or
        any Authorized User, or using Customer’s or an Authorized User’s Access
        Credentials, that does not strictly comply with this Agreement and the
        Specifications; (b) Customer Failure; (c) Customer’s or its Authorized
        User’s Internet connectivity; (d) Force Majeure Event; (e) failure,
        interruption, outage, or other problem with any software, hardware,
        system, network, facility, or other matter not supplied by Provider
        pursuant to this Agreement; (f) Scheduled Downtime; or (g) disabling,
        suspension, or termination of the Services pursuant to Section 2.8.
    </p>
    <p class="block">
      5.2 Service Level Failures and Remedies. In the event of a Service Level
      Failure, Provider will issue a credit to Customer in the amount of ten
      percent (10%) of the monthly Fees for the Services due for the Service
      Period the Service Level Failure occurred (each a “	<span style="font-weight: bold;">Service Credit</span>”), subject to the following:
    </p>
    <p class="block">
        (a) Provider has no obligation to issue any Service Credit unless: (i)
        Customer reports the Service Failure to Provider immediately on
        becoming aware of it; and (ii) requests such Service Credit in writing
        within thirty (30) days of the Service Level Failure; and
    </p>
    <p class="block">
        (b) in no event will a Service Credit for any Service Period exceed
        thirty percent (30%) of the total Fees that would be payable for that
        Service Period if no Service Level Failure had occurred.
    </p>
    <p class="block"> 
        Any Service Credit payable to Customer under this Agreement will be
        issued to Customer in the calendar month following the Service Period
        in which the Service Level Failure occurred. This
        Section 5.2 sets forth Provider’s sole obligation and liability and
        Customer’s sole remedy for any Service Level Failure.
    </p>
    <p class="block">
        5.3 Scheduled Downtime. Provider will use commercially reasonable
        efforts to: (a) schedule downtime for routine maintenance of the
        Services between the hours of 2 a.m. and 6 a.m., Eastern Time (ET); and
        (b) give Customer at least 48 hours prior notice of all scheduled
        outages of the Services (“<span style="font-weight: bold;">Scheduled Downtime</span>”).
    </p>
    <p class="block">
        5.4 Service Support. The Services include Provider’s standard customer
        support services (“<span style="font-weight: bold;">Support Services</span>”) at the support
        levels Customer purchases in accordance with the Provider service
        support schedule then in effect. Customer may purchase enhanced support
        services separately at Provider’s then current rates.
    </p>
    <p class="block">
        6. Data Backup. The Services do not replace the need for Customer to
        maintain regular data backups or redundant data archives. PROVIDER HAS
        NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION,
        DAMAGE, CORRUPTION, OR RECOVERY OF CUSTOMER DATA.
    </p>
    <h3>7. Security.</h3>
    <p class="block">
        7.1 Information Security. Provider will employ security measures in
        accordance with Provider’s data privacy and security policy as amended
        from time to time.
    </p>
    <p class="block">
        7.2 Customer Control and Responsibility. Customer has and will retain
        sole responsibility for: (a) all Customer Data, including its content
        and use; (b) all information, instructions, and materials provided by
        or on behalf of Customer or any Authorized User in connection with the
        Services; (c) Customer Systems; (d) the security and use of Customer’s
        and its Authorized Users’ Access Credentials; and (e) all access to and
        use of the Services
        and Provider Materials directly or indirectly by or through the Customer
        Systems or its or its Authorized Users’ Access Credentials, with or without
        Customer’s knowledge or consent, including all results obtained from, and
        all conclusions, decisions, and actions based on, such access or use.	<u></u>
    </p>
      <p class="block">
          7.3 Access and Security. Customer will employ all physical,
          administrative, and technical controls, screening, and security
          procedures and other safeguards necessary to: (a) securely administer
          the distribution and use of all Access Credentials and protect against
          any unauthorized access to or use of the Services; and (b) control the
          content and use of Customer Data, including the uploading or other
          provision of Customer Data for Processing by the Services.
      </p>
      <h3>8. Fees and Payment.</h3>
    <p class="block">
      8.1 Fees. Customer will pay Provider the fees set forth in <span style="font-weight: bold;">Exhibit A</span> (“<span style="font-weight: bold;">Fees</span>”) in accordance with
      this Section 8.
    </p>
    <p class="block">
        8.2 Taxes. All Fees and other amounts payable by Customer under this
        Agreement are exclusive of taxes and similar assessments. Without
        limiting the foregoing, Customer is responsible for all sales, use, and
        excise taxes, and any other similar taxes, duties, and charges of any
        kind imposed by any federal, state, or local governmental or regulatory
        authority on any amounts payable by Customer hereunder, other than any
        taxes imposed on Provider’s income.
    </p>
    <p class="block">
        8.3 Payment. Customer will pay all Fees within 30 (thirty) days after
        the date of the invoice therefor. Customer will make all payments
        hereunder in US dollars. Customer will make payments to the address or
        account specified in <span style="font-weight: bold;">Exhibit A</span> or such other address
        or account as Provider may specify in writing from time to time.
    </p>

    <p class="block">
      8.4 Late Payment. If Customer fails to make any payment when due then, in
      addition to all other remedies that may be available:
    </p>
    <p class="block"> 
		  (a) Provider may charge interest on the past due amount at the rate of
		  1.5% per month calculated daily and compounded monthly or, if lower,
		  the highest rate permitted under applicable Law;
    </p>
    <p class="block">
        (b) Customer will reimburse Provider for all costs incurred by Provider
        in collecting any late payments or interest, including attorneys’ fees,
        court costs, and collection agency fees; and
    </p>
    <p class="block">
        (c) if such failure continues for ten (10) days following written
        notice thereof, Provider may suspend performance of the Services until
        all past due amounts and interest thereon have been paid, without
        incurring any obligation or liability to Customer or any other Person
        by reason of such suspension.
    </p>
    <p class="block">
      8.5 No Deductions or Setoffs. All amounts payable to Provider under
      this Agreement will be paid by Customer to Provider in full without any
      setoff, recoupment,
	    counterclaim, deduction, debit, or withholding for any reason (other than
	    Service Credits issued pursuant to Section 5.2 or any deduction or
	    withholding of tax as may be required by applicable Law).
    </p>

	  <h3>9. Confidentiality.</h3>

    <p class="block">
		  9.1 Confidential Information. In connection with this Agreement each
		  party (as the “<span style="font-weight: bold;">Disclosing Party</span>”) may disclose or make
      available Confidential Information to the other party (as the “<span style="font-weight: bold;">Receiving Party</span>”). Subject to
      Section 9.2, “<span style="font-weight: bold;">Confidential Information</span>” means information
      in any form or medium (whether oral, written, electronic, or other) that
      the Disclosing Party considers confidential or proprietary, including
      information consisting of or relating to the Disclosing Party’s technology,
      trade secrets, know-how, business operations, plans, strategies, customers,
      and pricing, and information with respect to which the Disclosing Party has
      contractual or other confidentiality obligations, in each case whether or
      not marked, designated, or otherwise identified as “confidential”. Without
      limiting the foregoing: all Provider Materials are the Confidential
      Information of Provider, and the terms of this Agreement are the
      Confidential Information of Provider.
    </p>
    <p class="block">
        9.2 Exclusions. Confidential Information does not include information
        that: (a) was rightfully known to the Receiving Party without
        restriction on use or disclosure prior to such information’s being
        disclosed or made available to the Receiving Party in connection with
        this Agreement; (b) was or becomes generally known by the public other
        than by the Receiving Party’s or any of its Representatives’
        noncompliance with this Agreement; (c) was or is received by the
        Receiving Party on a non-confidential basis from a third party that was
        not or is not, at the time of such receipt, under any obligation to
        maintain its confidentiality; or (d) the Receiving Party can
        demonstrate by written or other documentary records was or is
        independently developed by the Receiving Party without reference to or
        use of any Confidential Information.
    </p>

    <p class="block">
        9.3 Protection of Confidential Information. As a condition to being
        provided with any disclosure of or access to Confidential Information,
        the Receiving Party will:
    </p>
    <p class="block">
		  (a) not access or use Confidential Information other than as necessary
		  to exercise its rights or perform its obligations under and in
		  accordance with this Agreement;
    </p>
    <p class="block">
		  (b) except as may be permitted by and subject to its compliance with
      Section 9.4, not disclose or permit access to Confidential Information
      other than to its Representatives who: (i) need to know such Confidential
      Information for purposes of the Receiving Party’s exercise of its rights or
      performance of its obligations under and in accordance with this Agreement;
      (ii) have been informed of the confidential nature of the Confidential
      Information and the Receiving Party’s obligations under this Section 9.3;
      and (iii) are bound by written confidentiality and restricted use
      obligations at least as protective of the Confidential Information as the
      terms set forth in this Section 9;
    </p>
    <p class="block">
      (c) safeguard the Confidential Information from unauthorized use, access,
      or disclosure using at least the degree of care it uses to protect its
      similarly sensitive information and in no event less than a reasonable
      degree of care;
    </p>
    <p class="block">
        (d) promptly notify the Disclosing Party of any unauthorized use or
        disclosure of Confidential Information and take all reasonable steps to
        prevent further unauthorized use or disclosure; and
    </p>
    <p class="block">
        (e) ensure its Representatives’ compliance with and be responsible and
        liable for any of its Representatives’ non-compliance with, the terms
        of this Section 9.
    </p>
    <p class="block">
		  Notwithstanding any other provisions of this Agreement, the Receiving
		  Party’s obligations under this
      Section 9 with respect to any Confidential Information that constitutes a
      trade secret under any applicable Law will continue until such time, if
      ever, as such Confidential Information ceases to qualify for trade secret
      protection under one or more such applicable Laws other than as a result of
      any act or omission of the Receiving Party or any of its Representatives.
    </p>
    <p class="block">
      9.4 Compelled Disclosures. If the Receiving Party or any of its
      Representatives is compelled by applicable Law to disclose any
      Confidential Information then, to the extent permitted by applicable
      Law, the Receiving Party will: (a) promptly, and prior to such
      disclosure, notify the Disclosing Party in writing of such requirement
      so that the Disclosing Party can seek a protective order or other
      remedy or waive its rights under
      Section 9.3; and (b) provide reasonable assistance to the Disclosing Party,
      at the Disclosing Party’s sole cost and expense, in opposing such
      disclosure or seeking a protective order or other limitations on
      disclosure. If the Disclosing Party waives compliance or, after providing
      the notice and assistance required under this Section 9.4, the Receiving
      Party remains required by Law to disclose any Confidential Information, the
      Receiving Party will disclose only that portion of the Confidential
      Information that the Receiving Party is legally required to disclose and,
      on the Disclosing Party’s request, will use commercially reasonable efforts
      to obtain assurances from the applicable court or other presiding authority
      that such Confidential Information will be afforded confidential treatment.
    </p>
	  <h3>10. Intellectual Property Rights.</h3>
    <p class="block">
      10.1 Provider Materials. All right, title, and interest in and to the
      Provider Materials, including all Intellectual Property Rights therein,
      are and will remain with Provider and, with respect to Third-Party
      Materials, the applicable third-party providers own all right, title,
      and interest, including all Intellectual Property Rights, in and to the
      Third-Party Materials. Customer has no right, license, or authorization
      with respect to any of the Provider Materials except as expressly set
      forth in
      Section 2.1 or the applicable third-party license, in each case subject to
      Section 3. All other rights in and to the Provider Materials are expressly
      reserved by Provider. In furtherance of the foregoing, Customer hereby
      unconditionally and irrevocably grants to Provider an assignment of all
      right, title, and interest in and to the Resultant Data, including all
      Intellectual Property Rights relating thereto.<u></u>
    </p>
    <p class="block">
      10.2 Customer Data. As between Customer and Provider, Customer is and will
      remain the sole and exclusive owner of all right, title, and interest in
      and to all Customer Data, including all Intellectual Property Rights
      relating thereto, subject to the rights and permissions granted in Section
    </p>
    <p class="block">
        10.3 Consent to Use Customer Data. Customer hereby irrevocably grants
        all such rights and permissions in or relating to Customer Data as are
        necessary or useful to Provider, its Subcontractors, and the Provider
        Personnel to enforce this Agreement and exercise Provider’s, its
        Subcontractors’, and the Provider Personnel’s rights and perform
        Provider’s, its Subcontractors’, and the Provider Personnel’s
        obligations hereunder.
    </p>
    <h3>11. Representations and Warranties.</h3>
    <p class="block">
        11.1 Mutual Representations and Warranties. Each party represents and
        warrants to the other party that:
    </p>
    <p class="block">
        (a) it is duly organized, validly existing, and in good standing as a
        corporation or other entity under the Laws of the jurisdiction of its
        incorporation or other organization;
    </p>
    <p class="block">
        (b) it has the full right, power, and authority to enter into and
        perform its obligations and grant the rights, licenses, consents, and
        authorizations it grants or is required to grant under this Agreement;
    </p>
    <p class="block">
        (c) the execution of this Agreement by its representative whose
        signature is set forth at the end of this Agreement has been duly
        authorized by all necessary corporate or organizational action of such
        party; and
    </p>
    <p class="block">
        (d) when executed and delivered by both parties, this Agreement will
        constitute the legal, valid, and binding obligation of such party,
        enforceable against such party in accordance with its terms.
    </p>
    <p class="block">
      11.2 Additional Provider Representations, Warranties, and Covenants.
      Provider represents, warrants, and covenants to Customer that Provider
      will perform the Services using personnel of required skill,
      experience, and qualifications and in a professional and workmanlike
      manner in accordance with generally recognized industry standards for
      similar services and will devote adequate resources to meet its
      obligations under this Agreement.
      HOWEVER, IF THE SERVICES ARE BEING PROVIDED TO CUSTOMER FOR BETA TESTING,
      THEN PROVIDER DISCLAIMS ANY AND ALL WARRANTIES RELATED TO THE SERVICES AND
      THEY ARE PROVIDED “AS IS.”
    </p>
    <p class="block">
        11.3 Additional Customer Representations, Warranties, and Covenants.
        Customer represents, warrants, and covenants to Provider that Customer
        owns or otherwise has and will have the necessary rights and consents
        in and relating to the Customer Data so that, as received by Provider
        and Processed in accordance with this Agreement, they do not and will
        not infringe, misappropriate, or otherwise violate any Intellectual
        Property Rights, or any privacy or other rights of any third party or
        violate any applicable Law.
    </p>
    <p class="block">
      11.4 DISCLAIMER OF WARRANTIES. EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH
      IN SECTION 11.1 AND SECTION 11.2, ALL SERVICES AND PROVIDER MATERIALS ARE
      PROVIDED “AS IS.” PROVIDER SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
      NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE,
      OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, PROVIDER MAKES NO
      WARRANTY OF ANY KIND THAT THE SERVICES OR PROVIDER MATERIALS, OR ANY
      PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER’S OR ANY OTHER
      PERSON’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED
      RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES,
      OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. ALL
      THIRD-PARTY MATERIALS ARE PROVIDED “AS IS” AND ANY REPRESENTATION OR
      WARRANTY OF OR CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY BETWEEN
      CUSTOMER AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY
    </p>
	  <h3>12. Indemnification.</h3>
    <p class="block">
      12.1 Provider Indemnification. Provider will indemnify, defend, and
      hold harmless Customer from and against any and all Losses incurred by
      Customer resulting from any Action by a third party (other than an
      Affiliate of Customer) that Customer’s use of the Services (excluding
      Customer Data and Third-Party Materials) in accordance with this
      Agreement (including the Specifications) infringes or misappropriates
      such third party’s US patents, copyrights, or trade secrets. The
      foregoing obligation does not apply to the extent that the alleged
      infringement arises from:
    </p>
    <p class="block">
      (a) Third-Party Materials or Customer Data;
    </p>
    <p class="block">
      (b) access to or use of the Provider Materials in combination with any
      hardware, system, software, network, or other materials or service not
      provided by Provider or specified for Customer’s use in the
      Documentation;
    </p>
    <p class="block">
        (c) modification of the Provider Materials other than: (i) by or on
        behalf of Provider; or (ii) with Provider’s written approval in
        accordance with Provider’s written specification;
    </p>
    <p class="block">
        (d) failure to timely implement any modifications, upgrades,
        replacements, or enhancements made available to Customer by or on
        behalf of Provider; or
    </p>
    <p class="block">
      (e) act, omission, or other matter described in
      Section 12.2(a), Section 12.2(b), Section 12.2(c), or Section 12.2(d),
      whether or not the same results in any Action against or Losses by any
      Provider Indemnitee.
    </p>
    <p class="block">
      12.2 Customer Indemnification. Customer will indemnify, defend, and hold
      harmless Provider and its officers, directors, employees, agents,
      successors, and assigns (each, a “<span style="font-weight: bold;">Provider Indemnitee</span>”)
      from and against any and all Losses incurred by such Provider Indemnitee
      resulting from any Action by a third party (other than an Affiliate of a
      Provider Indemnitee) that arise out of or result from, or are alleged to
      arise out of or result from:<u></u>
    </p>
    <p class="block">
		  (a) Customer Data, including any Processing of Customer Data by or on
		  behalf of Provider in accordance with this Agreement;
    </p>
    <p class="block">
        (b) any other materials or information (including any documents, data,
        specifications, software, content, or technology) provided by or on
        behalf of Customer or any Authorized User, including Provider’s
        compliance with any specifications or directions provided by or on
        behalf of Customer or any Authorized User to the extent prepared
        without any contribution by Provider;
    </p>
    <p class="block">
        (c) allegation of facts that, if true, would constitute Customer’s
        breach of any of its representations, warranties, covenants, or
        obligations under this Agreement; or
    </p>
    <p class="block">
        (d) negligence or more culpable act or omission (including recklessness
        or willful misconduct) by Customer, any Authorized User, or any third
        party on behalf of Customer or any Authorized User, in connection with
        this Agreement.
    </p>
    <p class="block">
		  12.3 Indemnification Procedure. Each party will promptly notify the
		  other party in writing of any Action for which such party believes it
		  is entitled to be indemnified pursuant to
      Section 12.1 or Section 12.2, as the case may be. The party seeking
      indemnification (the “<span style="font-weight: bold;">Indemnitee</span>”) will cooperate with the
      other party (the “<span style="font-weight: bold;">Indemnitor</span>”) at the Indemnitor’s sole
      cost and expense. The Indemnitor will promptly assume control of the
      defense and will employ counsel reasonably acceptable to the Indemnitee to
      handle and defend the same, at the Indemnitor’s sole cost and expense. The
      Indemnitee may participate in and observe the proceedings at its own cost
      and expense with counsel of its own choosing. The Indemnitor will not
      settle any Action on any terms or in any manner that adversely affects the
      rights of any Indemnitee without the Indemnitee’s prior written consent. If
      the Indemnitor fails or refuses to assume control of the defense of such
      Action, the Indemnitee will have the right, but no obligation, to defend
      against such Action, including settling such Action after giving notice to
      the Indemnitor, in each case in such manner and on such terms as the
      Indemnitee may deem appropriate. The Indemnitee’s failure to perform any
      obligations under this Section 12.3 will not relieve the Indemnitor of its
      obligations under this Section 12, except to the extent that the Indemnitor
      can demonstrate that it has been prejudiced as a result of such failure.
    </p>
    <p class="block">
		  12.4 Mitigation. If any of the Services or Provider Materials are, or
		  in Provider’s opinion are likely to be, claimed to infringe,
		  misappropriate, or otherwise violate any third-party Intellectual
		  Property Right, or if Customer’s or any Authorized User’s use of the
      Services or Provider Materials is enjoined or threatened to be enjoined,
      Provider may, at its option and sole cost and expense:<u></u>
    </p>
    <p class="block">
		  (a) obtain the right for Customer to continue to use the Services and
		  Provider Materials materially as contemplated by this Agreement;
    </p>
    <p class="block">
        (b) modify or replace the Services and Provider Materials, in whole or
        in part, to seek to make the Services and Provider Materials (as so
        modified or replaced) non-infringing, while providing materially
        equivalent features and functionality, in which case such modifications
        or replacements will constitute Services and Provider Materials, as
        applicable, under this Agreement; or
    </p>
    <p class="block">
        (c) by written notice to Customer, terminate this Agreement with
        respect to all or part of the Services and Provider Materials, and
        require Customer to immediately cease any use of the Services and
        Provider Materials or any specified part or feature thereof.
    </p>
    <p class="block">
        12.5 Sole Remedy. THIS SECTION 12 SETS FORTH CUSTOMER’S SOLE
        REMEDIES AND PROVIDER’S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL,
        THREATENED, OR ALLEGED CLAIMS THAT THE SERVICES AND PROVIDER MATERIALS
        OR ANY SUBJECT MATTER OF THIS AGREEMENT INFRINGES, MISAPPROPRIATES, OR
        OTHERWISE VIOLATES ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY.
    </p>

	  <h3>13. Limitations of Liability.</h3>
    <p class="block">
      13.1 EXCLUSION OF DAMAGES. IN NO EVENT WILL PROVIDER OR ANY OF ITS
      LICENSORS, SERVICE PROVIDERS, OR SUPPLIERS BE LIABLE UNDER OR IN
      CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR
      EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
      NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a) LOSS OF
      PRODUCTION, USE, BUSINESS, REVENUE, OR PROFIT OR DIMINUTION IN VALUE;
      (b) IMPAIRMENT, INABILITY TO USE OR LOSS, INTERRUPTION, OR DELAY OF THE
      SERVICES, OTHER THAN FOR THE ISSUANCE OF ANY APPLICABLE SERVICE CREDITS
      PURSUANT TO
      SECTION 5.2; (c) LOSS, DAMAGE, CORRUPTION, OR RECOVERY OF DATA, OR BREACH
      OF DATA OR SYSTEM SECURITY; (d) COST OF REPLACEMENT GOODS OR SERVICES; (e)
      LOSS OF GOODWILL OR REPUTATION; OR (f) CONSEQUENTIAL, INCIDENTAL, INDIRECT,
      EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER
      SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR
      SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE
      FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.<u></u>
    </p>
    <p class="block">
      13.2 CAP ON MONETARY LIABILITY. EXCEPT AS OTHERWISE PROVIDED IN
      SECTION 13.3, IN NO EVENT WILL THE AGGREGATE LIABILITY OF PROVIDER ARISING OUT OF OR RELATED TO THIS
      AGREEMENT, WHETHER ARISING UNDER OR RELATED TO BREACH OF CONTRACT, TORT
      (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE
      THEORY, EXCEED THE TOTAL AMOUNTS PAID TO PROVIDER UNDER THIS AGREEMENT IN
      THE ONE (1) YEAR PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM. THE
      FOREGOING LIMITATIONS APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL
    </p>
    <p class="block">
      13.3 Exceptions. The exclusions and limitations in 
      Section 13.1 and Section 13.2 do not apply to Provider’s obligations under
      Section 12 or liability for Provider’s gross negligence or willful
    </p>

	  <h3>14. Term and Termination.</h3>

    <p class="block">
        14.1 Initial Term. The initial term of this Agreement commences as of
        the Effective Date and, unless terminated earlier pursuant any of the
        Agreement’s express provisions, will continue in effect until one (1)
        year from such date (the “<span style="font-weight: bold;">Initial Term</span>”).
    </p>
    <p class="block">
      14.2 Renewal Term. This Agreement will automatically renew for up to
      three (3) additional successive one (1) year term unless earlier
      terminated pursuant to this Agreement’s express provisions or either
      party gives the other party written notice of non-renewal at least
      sixty (60) days prior to the expiration of the then-current term (each
      a “<span style="font-weight: bold;">Renewal Term</span>” and, collectively, together with the
      Initial Term, the “<span style="font-weight: bold;">Term</span>”).
	  </p>
    <p class="block">
        14.3 Termination. In addition to any other express termination right
        set forth elsewhere in this Agreement:
    </p>
    <p class="block">
        (a) Provider may terminate this Agreement, effective on written notice
        to Customer, if Customer: (i) fails to pay any amount when due
        hereunder, and such failure continues more than ten (10) days after
        Provider’s delivery of written notice thereof; or (ii) breaches any of
        its obligations under
        Section 3 , Section 7.2, or Section 9;
    </p>
    <p class="block">
        (b) either party may terminate this Agreement, effective on written
        notice to the other party, if the other party materially breaches this
        Agreement, and such breach: (i) is incapable of cure; or (ii) being
        capable of cure, remains uncured thirty (30) days after the
        non-breaching party provides the breaching party with written notice of
        such breach; and
    </p>
    <p class="block">
        (c) either party may terminate this Agreement, effective immediately
        upon written notice to the other party, if the other party: (i) becomes
        insolvent or is generally unable to pay, or fails to pay, its debts as
        they become due; (ii) files, or has filed against it, a petition for
        voluntary or involuntary bankruptcy or otherwise becomes subject,
        voluntarily or involuntarily, to any proceeding under any domestic or
        foreign bankruptcy or insolvency Law; (iii) makes or seeks to make a
        general assignment for the benefit of its creditors; or (iv) applies
        for or has appointed a
      receiver, trustee, custodian, or similar agent appointed by order of any
      court of competent jurisdiction to take charge of or sell any material
      portion of its property or business.
    </p>
    <p class="block">
        14.4 Effect of Termination or Expiration. Upon any expiration or
        termination of this Agreement, except as expressly otherwise provided
        in this Agreement:
    </p>
    <p class="block">
        (a) all rights, licenses, consents, and authorizations granted by
        either party to the other hereunder will immediately terminate;
    </p>
    <p class="block">
        (b) Provider will immediately cease all use of any Customer Data or
        Customer’s Confidential Information and (i) promptly return to
        Customer, or at Customer’s written request destroy, all documents and
        tangible materials containing, reflecting, incorporating, or based on
        Customer Data or Customer’s Confidential Information; and (ii)
        permanently erase all Customer Data and Customer’s Confidential
        Information from all systems Provider directly or indirectly controls,
        provided that, for clarity, Provider’s obligations under this Section
        14.4(b) do not apply to any Resultant Data;
    </p>
    <p class="block">
        (c) Customer will immediately cease all use of any Services or Provider
        Materials and (i) promptly return to Provider, or at Provider’s written
        request destroy, all documents and tangible materials containing,
        reflecting, incorporating, or based on any Provider Materials or
        Provider’s Confidential Information; (ii) permanently erase all
        Provider Materials and Provider’s Confidential Information from all
        systems Customer directly or indirectly controls; and (iii) certify to
        Provider in a signed written instrument that it has complied with the
        requirements of this Section 14.4(c);
    </p>
    <p class="block">
        (d) notwithstanding anything to the contrary in this Agreement, with
        respect to information and materials then in its possession or control:
        (i) the Receiving Party may retain the Disclosing Party’s Confidential
        Information; (ii) Provider may retain Customer Data; (iii) Customer may
        retain Provider Materials, in the case of each of subclause (i), (ii),
        and (iii), in its then current state and solely to the extent and for
        so long as required by applicable Law; (iv) Provider may also retain
        Customer Data in its backups, archives, and disaster recovery systems
        until such Customer Data is deleted in the ordinary course; and (v) all
        information and materials described in this Section 14.4(d) will remain
        subject to all confidentiality, security, and other applicable
        requirements of this Agreement;
    </p>
    <p class="block">
        (e) Provider may disable all Customer and Authorized User access to the
        Provider Materials;
    </p>
    <p class="block">
        (f) if Customer terminates this Agreement pursuant to
      Section 14.3(b) Customer will be relieved of any obligation to pay any
      Fees attributable to the period after the effective date of such
      termination and Provider will: (i) refund to Customer Fees paid in advance
      for Services that Provider has not performed as of the effective date of termination; and (ii) pay to Customer any
      unpaid Service Credits to which Customer is entitled; and
    </p>
    <p class="block">
      (g) if Provider terminates this Agreement pursuant to
      Section 14.3(a) or Section 14.3(b), all Fees that would have become payable
      had the Agreement remained in effect until expiration of the Term will
      become immediately due and payable, and Customer will pay such Fees,
      together with all previously-accrued but not yet paid Fees, on receipt of
      Provider’s invoice therefor.
    </p>
    <p class="block">
        14.5 Surviving Terms. The provisions set forth in the following
        sections, and any other right or obligation of the parties in this
        Agreement that, by its nature, should survive termination or expiration
        of this Agreement, will survive any expiration or termination of this
        Agreement:
        Section 3, Section 9, Section 11.4, Section 12, Section 13, Section 14.4,
        this Section 14.5, and Section 15.<u></u>
      </p>
      <h3>15. Miscellaneous.</h3>
      <p class="block">
          15.1 Further Assurances. On a party’s reasonable request, the other
          party will, at the requesting party’s sole cost and expense, execute
          and deliver all such documents and instruments, and take all such
          further actions, as may be necessary to give full effect to this
          Agreement.
      </p>
      <p class="block">
          15.2 Relationship of the Parties. The relationship between the parties
          is that of independent contractors. Nothing contained in this Agreement
          will be construed as creating any agency, partnership, joint venture,
          or other form of joint enterprise, employment, or fiduciary
          relationship between the parties, and neither party will have authority
          to contract for or bind the other party in any manner whatsoever.
      </p>
      <p class="block">
        15.3 Public Announcements. Neither party will issue or release any
        announcement, statement, press release, or other publicity or marketing
        materials relating to this Agreement or, unless expressly permitted
        under this Agreement, otherwise use the other party’s trademarks,
        service marks, trade names, logos, domain names, or other indicia of
        source, association, or sponsorship, in each case, without the prior
        written consent of the other party, which consent will not be
        unreasonably withheld, provided, however, that Provider may, without
        Customer’s consent, include Customer’s name and other indicia in its
        lists of Provider’s current or former customers of Provider in
        promotional and marketing materials.
      </p>
      <p class="block">
          15.4 Notices. Any notice, request, consent, claim, demand, waiver, or
          other communications under this Agreement have legal effect only if in
          writing and addressed to a party as follows (or to such other address
          or such other person that such party may designate from time to time in
          accordance with this Section 15.4):
      </p>

      <p class="block">
        If to Provider:
      </p>
    
      <p class="block">
        PO Box 981338
      </p>
      <p class="block">
        Ypsilanti, Michigan 48197-1338
      </p>
      <p class="block">
        Email:	<a href="mailto:privacy@reconn.ai">privacy@reconn.ai</a>
      </p>
      <p class="block">
        Attention: Adam O’Donnell
      </p>
    
      <p class="block">
        If to Customer:
      </p>
      <p class="block">
        At the address Customer provides upon Customer’s
        subscription to the Services
      </p>
			
      <p class="block">
          Notices sent in accordance with this Section 15.4 will be deemed
          effectively given: (a) when received, if delivered by hand, with signed
          confirmation of receipt; (b) when received, if sent by a nationally
          recognized overnight courier, signature required; (c) when sent, if by
          email with confirmation of transmission, if sent during the addressee’s
          normal business hours, and on the next business day, if sent after the
          addressee’s normal business hours; and (d) on the third (3<sup>rd</sup>
          ) day after the date mailed by certified or registered mail, return
          receipt requested, postage prepaid.
      </p>
      <p class="block">
          15.5 Interpretation. For purposes of this Agreement: (a) the words
          “include,” “includes,” and “including” are deemed to be followed by the
          words “without limitation”; (b) the word “or” is not exclusive; (c) the
          words “herein,” “hereof,” “hereby,” “hereto,” and “hereunder” refer to
          this Agreement as a whole; (d) words denoting the singular have a
          comparable meaning when used in the plural, and vice-versa; and (e)
          words denoting any gender include all genders. Unless the context
          otherwise requires, references in this Agreement: (x) to sections,
          exhibits, schedules, attachments, and appendices mean the sections of,
          and exhibits, schedules, attachments, and appendices attached to, this
          Agreement; (y) to an agreement, instrument, or other document means
          such agreement, instrument, or other document as amended, supplemented,
          and modified from time to time to the extent permitted by the
          provisions thereof; and (z) to a statute means such statute as amended
          from time to time and includes any successor legislation thereto and
          any regulations promulgated thereunder. The parties intend this
          Agreement to be construed without regard to any presumption or rule
          requiring construction or interpretation against the party drafting an
          instrument or causing any instrument to be drafted. The exhibits,
          schedules, attachments, and appendices referred to herein are an
          integral part of this Agreement to the same extent as if they were set
          forth verbatim herein.
      </p>
      <p class="block">
          15.6 Headings. The headings in this Agreement are for reference only
          and do not affect the interpretation of this Agreement.
      </p>
      <p class="block">
          15.7 Entire Agreement. This Agreement constitutes the sole and entire
          agreement of the parties with respect to the subject matter of this
          Agreement and supersedes all prior and contemporaneous understandings,
          agreements, representations, and warranties, both written and oral,
          with respect to such subject matter. In the event of any inconsistency
          between the statements made in the body of this Agreement, the related
          exhibits, schedules, attachments, and appendices and any other
          documents incorporated herein by reference, the following order of
          precedence governs: (a) first, this Agreement, excluding its exhibits,
          schedules, attachments, and appendices; (b) second, the exhibits,
          schedules, attachments, and appendices to this Agreement as of the
          Effective Date; and (c) third, any other documents incorporated herein
          by reference.
      </p>
      <p class="block">
          15.8 Assignment. Customer will not assign or otherwise transfer any of
          its rights, or delegate or otherwise transfer any of its obligations or
          performance under this Agreement, in
        each case whether voluntarily, involuntarily, by operation of law, or
        otherwise, without Provider’s prior written consent. No assignment,
        delegation, or transfer will relieve Customer of any of its obligations or
        performance under this Agreement. Any purported assignment, delegation, or
        transfer in violation of this Section 15.8 is void. This Agreement is
        binding upon and inures to the benefit of the parties hereto and their
        respective successors and permitted assigns.<u></u>
      </p>
        <p class="block">
	        15.9 Force Majeure. (a) No Breach or Default. In no event will either party be liable or
          responsible to the other party, or be deemed to have defaulted under or
          breached this Agreement, for any failure or delay in fulfilling or
          performing any term of this Agreement (except for any obligations to
          make payments), when and to the extent such failure or delay is caused
          by any circumstances beyond such party’s reasonable control (a “<span style="font-weight: bold;">Force Majeure Event</span>”), 
          including (i) acts of God; (ii)
          flood, fire, earthquake, epidemic, or explosion; (iii) war, invasion,
          hostilities (whether war is declared or not), terrorist threats or
          acts, riot or other civil unrest; (iv) government order, law, or
          actions; (v) embargoes or blockades in effect on or after the date of
          this Agreement; (vi) national or regional emergency; (vii) strikes,
          labor stoppages or slowdowns, or other industrial disturbances and
          (viii) shortage of adequate power or transportation facilities. Either
          party may terminate this Agreement if a Force Majeure Event affecting
          the other party continues substantially uninterrupted for a period of
          thirty (30) days or more.
      </p>
      <p class="block">
          (b) Affected Party Obligations. In the event of any failure or delay
          caused by a Force Majeure Event, the affected party will give prompt
          written notice to the other party stating the period of time the
          occurrence is expected to continue and use commercially reasonable
          efforts to end the failure or delay and minimize the effects of such
          Force Majeure Event.
      </p>
      <p class="block">
          15.10 No Third-Party Beneficiaries. This Agreement is for the sole
          benefit of the parties hereto and their respective successors and
          permitted assigns and nothing herein, express or implied, is intended
          to or will confer upon any other Person any legal or equitable right,
          benefit, or remedy of any nature whatsoever under or by reason of this
          Agreement.
      </p>
      <p class="block">
          15.11 Amendment and Modification; Waiver. No amendment to or
          modification of this Agreement is effective unless it is in writing and
          signed by an authorized representative of each party. No waiver by any
          party of any of the provisions hereof will be effective unless
          explicitly set forth in writing and signed by the party so waiving.
          Except as otherwise set forth in this Agreement, no failure to
          exercise, or delay in exercising, any rights, remedy, power, or
          privilege arising from this Agreement will operate or be construed as a
          waiver thereof; nor will any single or partial exercise of any right,
          remedy, power, or privilege hereunder preclude any other or further
          exercise thereof or the exercise of any other right, remedy, power, or
          privilege.
      </p>
      <p class="block">
        15.12 Severability. If any term or provision of this Agreement is invalid,
        illegal, or unenforceable in any jurisdiction, such invalidity, illegality,
        or unenforceability will not affect any other term or provision of this
        Agreement or invalidate or render unenforceable such term or provision in
        any other jurisdiction. Upon such determination that any term or other
        provision is invalid, illegal, or unenforceable, the parties hereto will
        negotiate in good faith to modify this Agreement so as to effect the
        original intent of the parties as closely as possible in a mutually
        acceptable manner in order that the transactions contemplated hereby be
        consummated as originally contemplated to the greatest extent possible.
      </p>
      <p class="block">
          15.13 Governing Law; Submission to Jurisdiction. This Agreement is
          governed by and construed in accordance with the internal laws of the
          State of Michigan without giving effect to any choice or conflict of
          law provision or rule that would require or permit the application of
          the laws of any jurisdiction other than those of the State of Michigan.
          Any legal suit, action, or proceeding arising out of or related to this
          Agreement or the licenses granted hereunder will be instituted
          exclusively in the federal courts of the United States or the courts of
          the State of Michigan in each case located in the city of Ypsilanti and
          County of Washtenaw, and each party irrevocably submits to the
          exclusive jurisdiction of such courts in any such suit, action, or
          proceeding. Service of process, summons, notice, or other document by
          mail to such party’s address set forth herein will be effective service
          of process for any suit, action, or other proceeding brought in any
          such court.
      </p>
      <p class="block">
          15.14 Waiver of Jury Trial. Each party irrevocably and unconditionally
          waives any right it may have to a trial by jury in respect of any legal
          action arising out of or relating to this Agreement or the transactions
          contemplated hereby.
      </p>
      <p class="block"> 
		    15.15 Equitable Relief. Customer acknowledges and agrees that a breach
		    or threatened breach by Customer of any of its obligations under
        Section 3, Section 4.3, Section 7.2, or Section 9 would cause Provider
        irreparable harm for which monetary damages would not be an adequate remedy
        and that, in the event of such breach or threatened breach, Provider will
        be entitled to equitable relief, including a restraining order, an
        injunction, specific performance, and any other relief that may be
        available from any court, without any requirement to post a bond or other
        security, or to prove actual damages or that monetary damages are not an
        adequate remedy. Such remedies are not exclusive and are in addition to all
        other remedies that may be available at law, in equity, or otherwise.	<u></u>
      </p>
      <p class="block">
          15.16 Attorneys’ Fees. If any action, suit, or other legal or
          administrative proceeding is instituted or commenced by either party
          against the other party arising out of or related to this Agreement,
          the prevailing party is entitled to recover its actual attorneys’ fees
          and court costs from the non-prevailing party.
      </p>
      <p class="block">
        15.17 Counterparts. This Agreement may be executed in counterparts,
        each of which is deemed an original, but all of which together are
        deemed to be one and the same agreement. A signed copy of this
        Agreement delivered by facsimile, email, or other means of electronic
        transmission is deemed to have the same legal effect as delivery of an
        original signed copy of this Agreement.
      </p>
      <p class="block">
        EXHIBIT A
      </p>
      <p class="block">
        SERVICES AND FEES
      </p>
      <p class="block">
        EXHIBIT B
      </p>
      <p class="block">
        SPECIFICATIONS
      </p>

      </section>
    </div>
    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";


export default {
    components: {
        NavBar,
        FooterBar
    },

  props: [],

  data() {
    return {
      
    };
  },

  watch: {},

  methods: {

    
    
  },

  computed: {

  },

  mounted() {


  },
};
</script>

<style lang="scss">
  .terms {
    padding: 25px 0;
    flex-grow: 1;
    &__card {
      background: $off-white;
      width: 80%;
      margin: 25px auto;
      padding: 2em 5em 2em 4em;
      border-radius: 7px;
      border: 1px solid;
      border-color: $border-colors;
      box-shadow: 0 0 10px $grey;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      @media only screen and (max-width: 767px) {
          width: 92%;
          height: auto;
          padding: 1em;
      }
      @media only screen and (max-width: 415px) {
          width: 90%;
      }

      .sub-title {
        font-size: 1.2rem;
        font-style: italic;
        text-transform: capitalize;
        font-weight: 700;
      }

      .block {
        padding: 10px 25px;
      }

      .block-list {
        padding: 10px 50px;
        list-style-type: disc;
      }

      .block-list-alt {
        padding: 10px 50px;
        list-style-type: circle;
      }

      .block-list-num {
        padding: 10px 50px;
        list-style-type: decimal;
      }
    }
  }

</style>