<template>
  <div class="page">
    <nav-bar></nav-bar>
    <div class="page--content column">
        <div class="checklist-card">
            <h2>QA Checklist</h2>
            <section class="checklist">

                <div v-for="list in stuff" :key="list.title" class="checklist__container">
                    <h3>{{list.title}}</h3>
                    <ul class="checklist__container__section">
                        <li class="checklist__container__section--item" v-for="item in list.checklist" :key="item.item">
                            <p class="name">{{item.item}}</p>
                            <input type="checkbox" v-model="item.isChecked" class="box" />
                        </li>
                    </ul>
               </div>
                
            </section>
        </div>
        
    </div>
    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";

export default {

    components: {
        NavBar,
        FooterBar
    },

    props: {
    },

    data() {
        return {
            stuff:  [
                {
                    title: "Home Page",
                    checklist: [
                        
                        {
                            item: "Getting started button -- sign up",
                            isChecked: false
                        },
                        
                    ]
                },
                {
                    title: "Nav Bar",
                    checklist: [
                        {
                            item: "Question mark opens help modal",
                            isChecked: false
                        },
                        {
                            item: "Dashboard button",
                            isChecked: false
                        },
                        {
                            item: "Diagnostics button",
                            isChecked: false
                        },
                        {
                            item: "User Menu opens when clicking profile pic",
                            isChecked: false
                        },
                        {
                            item: "Sign-up",
                            isChecked: false
                        },
                        {
                            item: "Sign-in",
                            isChecked: false
                        },
                        {
                            item: "Sign-out",
                            isChecked: false
                        },
                        {
                            item: "View profile",
                            isChecked: false
                        },
                        {
                            item: "Search",
                            isChecked: false
                        },
                    ]
                },
                {
                    title: "Footer",
                    checklist: [
                        {
                            item: "Change Log",
                            isChecked: false
                        },
                        {
                            item: "Diagnostics",
                            isChecked: false
                        },
                        {
                            item: "Help link to help modal",
                            isChecked: false
                        },
                    ]
                },
                {
                    title: "Sign-up/Sign-in",
                    checklist: [
                        {
                            item: "Remember me",
                            isChecked: false
                        },
                        {
                            item: "Sign-in/Sign-up button",
                            isChecked: false
                        },
                        {
                            item: "Link to sign-in or sign-up",
                            isChecked: false
                        },
                    ]
                },
                {
                    title: "Diagnostics",
                    checklist: [
                        {
                            item: "Dropdowns for species open and close",
                            isChecked: false
                        },
                        
                    ]
                },
                {
                    title: "Change Log",
                    checklist: [
                        {
                            item: "Contact form send",
                            isChecked: false
                        },
                        {
                            item: "Contact form clear",
                            isChecked: false
                        },
                        
                    ]
                },
                {
                    title: "Dashboard",
                    checklist: [
                        {
                            item: "Create new organization & create button in modal",
                            isChecked: false
                        },
                        {
                            item: "Cancel button in create organization modal",
                            isChecked: false
                        },
                        {
                            item: "Add new project & create button in modal",
                            isChecked: false
                        },
                        {
                            item: "Cancel button in add project modal",
                            isChecked: false
                        },
                        {
                            item: "Project carousel arrows and dots to see projects when more than one",
                            isChecked: false
                        },
                        {
                            item: "Add member to organization & send email",
                            isChecked: false
                        },
                        {
                            item: "Cancel button in add member modal",
                            isChecked: false
                        },
                        {
                            item: "Clicking a member pic/name goes to their profile",
                            isChecked: false
                        },
                        {
                            item: "Manage members button",
                            isChecked: false
                        },
                        {
                            item: "Delete members",
                            isChecked: false
                        },
                        {
                            item: "Permission dropdown",
                            isChecked: false
                        },
                        {
                            item: "Close manage members modal",
                            isChecked: false
                        },
                        {
                            item: "Clicking a project goes to project page",
                            isChecked: false
                        },
                    ]
                },
                {
                    title: "Project Details",
                    checklist: [
                        {
                            item: "Manage members button",
                            isChecked: false
                        },
                        {
                            item: "Delete members",
                            isChecked: false
                        },
                        {
                            item: "Permission dropdown",
                            isChecked: false
                        },
                        {
                            item: "Close manage members modal",
                            isChecked: false
                        },
                        {
                            item: "Add member to organization && send email",
                            isChecked: false
                        },
                        {
                            item: "Cancel button in add member modal",
                            isChecked: false
                        },
                        {
                            item: "Clicking a member pic/name goes to their profile",
                            isChecked: false
                        },
                        {
                            item: "Cancel button in create collection modal",
                            isChecked: false
                        },
                        {
                            item: "Delete collection button",
                            isChecked: false
                        },
                        {
                            item: "Upload more images button",
                            isChecked: false
                        },
                        {
                            item: "View collection level tags modal",
                            isChecked: false
                        },
                        {
                            item: "Add new collection tags",
                            isChecked: false
                        },
                        {
                            item: "Delete new collection tags",
                            isChecked: false
                        },
                        {
                            item: "Close collection tags modal",
                            isChecked: false
                        },
                    ]
                },
                {
                    title: "Upload Images Page",
                    checklist: [
                        {
                            item: "Upload local images (from current machine) by clicking ",
                            isChecked: false
                        },
                        {
                            item: "Delete images from queue",
                            isChecked: false
                        },
                        {
                            item: "Sign-in for OneDrive",
                            isChecked: false
                        },
                        {
                            item: "File and folder upload for OneDrive",
                            isChecked: false
                        },
                        {
                            item: "Images upload in order",
                            isChecked: false
                        },
                        {
                            item: "View collection button -- go to the annotations page",
                            isChecked: false
                        },
                    ]
                },
                {
                    title: "Annotaions Page",
                    checklist: [
                        {
                            item: "Select species dropdown",
                            isChecked: false
                        },
                        {
                            item: "Upload images button goes to upload page",
                            isChecked: false
                        },
                        {
                            item: "View project button goes to project details page",
                            isChecked: false
                        },
                        {
                            item: "Export button opens file explorer to save as CSV",
                            isChecked: false
                        },
                        {
                            item: "Delete species tag (overlapping with image only shown if annotated)",
                            isChecked: false
                        },
                        {
                            item: "Left sidebar scrolls and loads more images",
                            isChecked: false
                        },
                        {
                            item: "Delete an image",
                            isChecked: false
                        },
                        {
                            item: "Open tags modal and add new tag to image",
                            isChecked: false
                        },
                        {
                            item: "Tags modal cancel button",
                            isChecked: false
                        },
                        {
                            item: "Delete image level tags",
                            isChecked: false
                        },
                        {
                            item: "Make an annotation",
                            isChecked: false
                        },
                        {
                            item: "Confirm/Unconfirm annotation",
                            isChecked: false
                        },
                        {
                            item: "Delete annotation",
                            isChecked: false
                        },
                    ]
                },
            ]   
        }
    },

    watch: {},

    methods: {
       

    },
    

    computed: {

    },

};

</script>

<style lang="scss">
    .checklist-card {
        background: $off-white;
        width: 80%;
        margin: 25px auto;
        padding: 2em 5em 2em 4em;
        border-radius: 7px;
        border: 1px solid;
        border-color: $border-colors;
        box-shadow: 0 0 10px $grey;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        @media only screen and (max-width: 767px) {
            width: 92%;
            height: auto;
            padding: 1em;
        }
        @media only screen and (max-width: 415px) {
            width: 90%;
        }

        .checklist {
            width: 50%;
            margin: 15px auto;
            &__container {
                padding: 15px;
                margin: 10px;
                background: $white;
                border-radius: 7px;
                border: 1px solid;
                border-color: $border-colors;
                box-shadow: 0 0 10px $grey;
                width: 100%;
                &__section{
                    width: 100%;
                    &--item {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 10px 0;
                        border-bottom: 1px solid $grey;
                        padding-bottom: 5px;
                        .name {
                        
                        }
                        .box {
                            font: inherit;
                            color: $dark-grey;
                            width: 1.15em;
                            height: 1.15em;
                            border: 0.15em solid $dark-grey;
                            border-radius: 0.15em;
                        }
                    }
                }
            }
        }
    }    

</style>