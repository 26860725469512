<template>
    <div class="uni-card">
        <div class="uni-card__header">
            <div class="uni-card__header--info">
                <div v-if="group.image_count >= 1" class="icon-position">
                    <i v-if="processing == 0" class="fa-solid fa-arrow-rotate-right fa-spin"></i>
                    <i v-if="processing == 1" class="fa-solid fa-check"></i>
                </div>
                <div class="group-name" v-if="!editing" @click="enableEditing()">{{ group.name }}</div>
                <div v-else class="input-container-title">
                    <input class="input-container--box" v-model="tempValue" />
                </div>
            </div>
            <div class="uni-card__header--actions">
                <i class="fa-solid fa-circle-info" @click="collectionDetails(group, tag_list)"></i>
                <i class="fa-solid fa-upload blue-text" @click="upload(group.collection_id)"></i>
                <i class="fa-solid fa-trash red-text" @click="confirmGroup('removeCollection', group)"></i>
            </div>
            
        </div>
        <div class="uni-card__content" @click="viewImages">
            <img v-if="group.url && !is_error" @error="error" class="uni-card__content--img" :src="group.url" :alt="'first image in a series of images for collection' + group.name" />
        
            <div v-else class="no-image">
                <i class="fa-solid fa-otter"></i>
                <p>No image available.</p>
            </div>
        </div>
        <div class="uni-card__tag-container">
            <div class="list-wrapper">
                <ul class="list-wrapper__tag-list">
                    <li class="list-wrapper__tag-list--item" v-for="tag in tag_list" :key="tag.name" :class="{'collection-color': tag.collection_id}">
                        <p>{{tag.name}}</p>
                    </li>
                </ul>
                
            </div>
            <span @click="openTagsModal" class="uni-card__tag-more">more ></span>
        </div>
    </div>

</template>

<script>


export default {

    components: {
        
    },

    props: {
        group: {default:null}
    },
    data() {
        return {
            editing: false,
            tempValue: null,
            allTags: null,
            is_error: false,
            processing: null
        }
    },
    watch:{
        
    },
    methods: {
        error: function() {
            this.is_error = true;
        },
        viewImages: function() {
            this.$emit("viewImages");
        },
        upload: function(id){
            console.log(id);
            this.$router.push({
                name: 'AddImages',
                params: { collection_id:id }

            });
        },
        collectionDetails: function(collection, tags) {
            this.$emit("collection_details", collection, tags);
        },
        confirmGroup: function(type, group) {
            this.$emit("confirmModal", type, group);
        },
        enableEditing: function(){
            console.log(this.group);
            this.tempValue = this.group.name;
            this.editing = true;
            window.addEventListener("mousedown", this.saveEdit);

        },
        disableEditing: function(){
            this.tempValue = null;
            this.editing = false;
            console.log("disable editing");
        },
        async saveEdit(evt){

            if(evt.target.tagName == "INPUT") {
                return
            }
            console.log("SAVING TITLE");
            let newName = await state.update_collection_name(this.tempValue, this.group);
            
            if(newName) {
                this.group.name = newName;
            
                this.disableEditing();
                window.removeEventListener("mousedown", this.saveEdit);
            }
            

        },
        openTagsModal: function() {
            console.log("event fired card")
            this.$emit("openTags", this.tag_list, this.group.collection_id);
        },

        async images_processing() {
            let processed = await state.get_image_processing_status(this.group.collection_id)
            this.processing = processed;
        }
        
    },
    mounted(){
        this.is_error = false;
        if(this.group.image_count >= 1) {
            this.images_processing();
        }
        
    },

    computed: {
        tag_list() {
            
            let newList = this.group.collection_tags.concat(this.group.image_tags);
            
            return newList;
        },
        
        
    },



};
</script>

<style lang="scss">

    .uni-card {
        background: $off-white;
        width: 350px;
        height: 300px;
        border-radius: 5px;
        box-shadow: 0 0 10px $grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin: 15px 0;

        &__header {
            display: flex;
            width: 100%;
            position: absolute;
            background: rgba(108, 114, 127, 0.8);
            color: $white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            justify-content: space-between;

            .icon-position {
                position: absolute;
                left: -10px;
                top: -10px;
                font-size: 26px;

                .fa-arrow-rotate-right {
                    color: $yellow;
                }

                .fa-check {
                    color: $green;
                }
            }

            
            
            .group-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 98%;
            }

            &--actions {
                padding: 10px 0px;
                
                i {
                    padding-right: 10px;
                    font-size: 1.3rem;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &--info {
                width: 70%;
                padding: 10px 0px 10px 5px;
                .input-container-title {
                    margin-right: 10px;
                    width: 75%;

                    
                }

                span {
                    padding-right: 5px;
                }

            }
        }

        &__content {
            height: 100%;
            width: 100%;
            border-radius: 5px;
            overflow: hidden;

            &--img {
                object-fit: cover;
                height: 350px;
                width: 350px;
                border-radius: 5px;
            }

            .no-image {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: $grey;
                font-size: .9rem;
                align-items: center;
                .fa-otter {
                    font-size: 4rem;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        &__tag-container {
            width: 98%;
            display: flex;
            align-items: center;
            font-size: 16px;
            position: absolute;
            bottom: 0px;

            .list-wrapper {
                white-space: nowrap;
                overflow: hidden;
                width: 78%;
                &__tag-list {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-flow: row nowrap;
                    width: 100%;
                    font-weight: 500;

                    &--item {
                        background: $grey;
                        color: $white;
                        margin: 0 5px;
                        padding: 0 5px;
                        border-radius: 3px;
                        p {
                            padding: 3px 3px 3px 5px;
                        }

                        .count {
                            font-style: italic;
                        }
                    }

                    .collection-color{
                        background: $dark-grey;
                    }
                }
            }
        }

        &__tag-more {
            padding: 5px;
            color: $primary-color;
            text-decoration: underline;
            font-weight: 700;
            &:hover {
                color: darken($primary-color, 10%);
                cursor: pointer;
                transition: all .3s;
            }
        }
    }


</style>
