import {get, post} from "@/scripts/samsa"
import {State} from "@/scripts/state"
import { reactive,ref, onMounted, getCurrentInstance } from 'vue'

State.prototype.files = reactive({})
State.prototype.images = reactive({})

state.progress = reactive({'value':0, 'files_remaining':0, 'start_time':null})
state._total = 0


class ImageEventHandler{
  #count = 0;
  #file_list = [];
  #max_length = 0;



  uploadStarted(file){
    if(state.progress.start_time == null)
      state.progress.start_time = new Date()

    this.#file_list.push(file)
    if(this.#file_list.length > this.#max_length)
      this.#max_length = this.#file_list.length
      state.progress.files_remaining += 1

  }


  uploadComplete(file){
    this.#count += 1
    state.progress.files_remaining -= 1
    let i = this.#file_list.indexOf(file)
    this.#file_list.splice(i, 1)
    state.progress.value = Math.floor((this.#count / this.#max_length)*100)
    if(state.progress.value == 100){
      state.progress.start_time = null;
      this.#max_length = 0
      this.#count = 0
      state.progress.value = 100
    }
  }

  upload(){

  }
}


let imageEventHandler = new ImageEventHandler()

async function uploadFiles(file_list,collection_id){
  state._total += 1



  

  const sleep = t => new Promise(rs => setTimeout(rs, t))
  
  
  //let s3Data = ret.data[file.name].data;

  let list = []
  let info_list = []
  for(var file of file_list){
    imageEventHandler.uploadStarted(file.name)
    let info = {file_name:file.name,size:file.size/(1024*1000),file_type:file.type,collection_id:collection_id,last_modified:file.lastModified}
    info_list.push(info)
    list.push(file)
  }
  
  let s3_ret = await post("sign_s3",info_list);
  let start_time = new Date()
  s3_ret = s3_ret.data

  let count = 0

  async function f(file){
    let s3Data = s3_ret[file.name].data

    var xhr = new XMLHttpRequest();
    xhr.open("POST", s3Data.url);

    var postData = new FormData();
    for(var key in s3Data.fields){
      postData.append(key, s3Data.fields[key]);
    }

    postData.append('file', file);

    xhr.upload.onprogress = function(evt) {
    
      if(evt.lengthComputable) {
        state.images[file.name].progress = evt.loaded/(1024*1000)
    
      }
    }

    xhr.upload.onloadend = function() {
      if(state._progress == state._total)
      {


      }
    }
    xhr.onreadystatechange = function() {
      if(xhr.readyState === 4){
        if(xhr.status === 200 || xhr.status === 204){
          state.images[file.name].progress = file.size/(1024*1000)
          post("predict",s3_ret[file.name])
          $events.emit("ImageUploaded")
          imageEventHandler.uploadComplete(file)
          count -= 1
          return
        }
        else{
          count -= 1
          //Try Again...
          console.log("Error Uploading File !!!", xhr)
          f(file);
          
        }
    }
    };
    await xhr.send(postData);
  }

  while (list.length) {
    if(count < 10){
      count+=1
      await Promise.all( list.splice(0, 1).map(f) )
      let now = new Date()
      
      if(now - start_time > 60*1000*30)
      {
        start_time = now;
        console.log("Renew Cirtificates")
        let s3 = await post("sign_s3",info_list);
        s3_ret = s3.data
      }
    }
    else{
      await sleep(10)
    }
  }


  return;
  
}

async function upload(file, s3_data, s3Data){
  let url = s3_data.url
  //CML- Keep Trying until we succeed
  var xhr = new XMLHttpRequest();
  xhr.open("POST", s3Data.url);

  var postData = new FormData();
  for(var key in s3Data.fields){
    postData.append(key, s3Data.fields[key]);
  }

  postData.append('file', file);


  
  xhr.upload.onprogress = function(evt) {
  
    if(evt.lengthComputable) {
      state.images[file.name].progress = evt.loaded/(1024*1000)
  
    }
  }
  

  xhr.upload.onloadend = function() {


    if(state._progress == state._total)
    {


    }
  }
  xhr.onreadystatechange = function() {
    
    if(xhr.readyState === 4){
      if(xhr.status === 200 || xhr.status === 204){
        state.images[file.name].progress = file.size/(1024*1000)
        post("predict",s3_data)
        $events.emit("ImageUploaded")
        imageEventHandler.uploadComplete(file)
        return
      }
      else{
        //Try Again...
        console.log("Error Uploading File !!!")
        upload(file,s3_data, s3Data)
      }
   }
  };

  xhr.send(postData);

}

async function sendToAWS(file, s3_data){
  
  let s3Data = s3_data.data

  await upload(file, s3_data, s3Data) //CML... just... don't...


}


State.prototype.clear = function(){
  for (var prop in this.images) {
    if (this.images.hasOwnProperty(prop)) {
        delete this.images[prop];
    }
}
}

State.prototype.uploadFile = async function(files,user_data) {

    let file_list = []
    file_list.push({file_name:files[0].name,file_type:files[0].type,collection_id:null,last_modified:files[0].lastModified})
    let ret = await post("sign_s3",file_list);
    let s3Data = ret.data[files[0].name].data


    var xhr = new XMLHttpRequest();
    xhr.open("POST", s3Data.url);

    var postData = new FormData();
    for(var key in s3Data.fields){
      postData.append(key, s3Data.fields[key]);
    }
    let file = files[0]
    postData.append('file', file);


    xhr.onreadystatechange = function() {
      if(xhr.readyState === 4){
        if(xhr.status === 200 || xhr.status === 204){
          user_data.avatar = ret.data[files[0].name].url;
        }
        else{
          //Try Again...
          console.log("Error!!!")
        }
     }
    };
    await xhr.send(postData);
    return ret.data[files[0].name].url
  }

State.prototype.uploadFiles = async function(files, collection_id) {
  let file_list = []
  let file_dict = {}
  for(var idx=0;idx<files.length;idx++){
    file_dict[files[idx].name] = files[idx]
    this.images[files[idx].name] = {name:files[idx].name, size:files[idx].size/(1024*1000),progress:0 }
    file_list.push({file_name:files[idx].name,size:files[idx].size/(1024*1000),file_type:files[idx].type,collection_id:collection_id,last_modified:files[idx].lastModified})
    //this.files.push(files[idx])
  }

    uploadFiles(files,collection_id)
    
  
    // if(ret.data['limit']){
    //   delete ret.data['limit']
    //   uploadFiles(file_dict, ret.data)
    //   return true
    // }
    // else{
    //   uploadFiles(file_dict, ret.data)
    // }
    
    return false
  }


  State.prototype.getImages = async function(collection_id, project_id, target_id, tag_id, page, images_per_page) {
      let ret = await post("get_images",{},{collection_id: collection_id, project_id:project_id, target_id:target_id, tag_id:tag_id, page:page, images_per_page:images_per_page});
      return ret.data
  }



class ImageHandler{
  constructor() {


  }
}


const msalConfig = {
  auth: {
    clientId: '88308a3e-fd57-4506-a9f5-ff7bd2438fa2',
    redirectUri: 'https://reconn.netlify.app/add-images'//'http://localhost:8081/upload'//'
    //redirectUri: 'http://localhost:8081/upload'//'
  }
};

const msalRequest = {
  scopes: [
    'user.read',
    'files.read',
    'files.read.all'
  ]
}


let graphClient = undefined;

function initializeGraphClient(msalClient, account, scopes)
{
  // Create an authentication provider
  const authProvider = new MSGraphAuthCodeMSALBrowserAuthProvider
  .AuthCodeMSALBrowserAuthenticationProvider(msalClient, {
    account: account,
    scopes: scopes,
    interactionType: msal.InteractionType.PopUp
  });

  // Initialize the Graph client
  graphClient = MicrosoftGraph.Client.initWithMiddleware({authProvider});
}


class OneDrive{
  constructor() {
    this.event_handle = null;
    this.max_index = 0
    console.log("One Drive Handler Initialized")
  }

  async signOut(){
    const msalClient = new msal.PublicClientApplication(msalConfig);
    console.log("SignOut")
    sessionStorage.removeItem('oneDriveUser')
    sessionStorage.removeItem('oneDriveToken')
    msalClient.logout()

  }

  async signIn(){
    // Create the main MSAL instance
    // configuration parameters are located in config.js
    const msalClient = new msal.PublicClientApplication(msalConfig);

    var user = sessionStorage.getItem('oneDriveUser')
    var token = sessionStorage.getItem('oneDriveToken')

    user = undefined
    if(user != undefined){
      this.user = user;
      this.auth_token = token;
      msalClient.setActiveAccount(user);
    }
    else{
      // Use MSAL to login
      const authResult = await msalClient.loginPopup(msalRequest);
        msalClient.setActiveAccount(authResult.account);

        sessionStorage.setItem('oneDriveUser', authResult.account);
        sessionStorage.setItem('oneDriveToken', authResult.accessToken);
        this.user = authResult.account;
        this.auth_token = authResult.accessToken;
      }

    //const account = msalClient.getActiveAccount();
    if (this.user) {
      initializeGraphClient(msalClient, this.user, msalRequest.scopes);

    }
    else{
      console.log("Not logged In")
    }
  }

  async getFolders(path=null){

        console.log("Listing", path)

        let paths = []
        if(path==null)
          var results = await graphClient.api(`/me/drive/root/children`).get()
        else
          var results = await graphClient.api(`/me/drive/root:/${path}:/children`).get()

        for(var i in results.value){
          if(results.value[i].folder){
            console.log(results.value[i])
            paths.push(results.value[i].name)

            }
          }
          let ret = [];
          let full_path = null;
          for(var idx in paths){
            if(path == null)
              full_path = paths[idx]
            else
              full_path = path + "/" + paths[idx]
            ret.push({ name: paths[idx], path: full_path, children:[], selected:false, expanded:false  } )
          }
          return ret
  }
  async upload(paths, collection_id){
    let self = this;

    this.max_index = 0
    // console.log(this.auth_token)
    // console.log("UPLOADING:", paths)
    let ret = await post("upload_one_drive",{token:state.token,  auth_token:this.auth_token,collection_id:collection_id, folders:paths});
    console.log(ret)

    state.progress.start_time = new Date()
    state.progress.files_remaining = ret.data['image_count']
    this.total = ret.data['image_count']
    state.progress.value = 0

    if(this.event_handle!=null)
      window.clearTimeout(this.event_handle)

    this.event_handle = window.setTimeout(function(){self.upload_status(0,ret.data.upload_id);},1000);


    }

    async upload_status(max_index, upload_id){
      let self = this;

      console.log("Status:", max_index, upload_id)
      let ret = await post("check_upload_status",{max_index, upload_id});
      console.log("STATUS: ", ret.data)

      for(let idx=0;idx<ret.data.length;idx++){
        let item = ret.data[idx]
        console.log("ITEM")
        state.images[item.file_name] = {name:item.file_name, size:0,  progress:1}
        state.progress.files_remaining -= 1;
        if(item.index>max_index)
          max_index = item.index
      }

      if(this.event_handle!=null)
        window.clearTimeout(this.event_handle)

      if(state.progress.files_remaining>0){
        state.progress.value = Math.floor(max_index/this.total*100);
        this.event_handle = window.setTimeout(function(){self.upload_status(max_index,upload_id);},1000);
      }
      else{
          state.progress.start_time = null;
          state.progress.value = 100;
      }

    }
  }



state.imageHandler = new ImageHandler()
state.oneDrive = new OneDrive()

console.log("Exporting: ",ImageEventHandler)
export {ImageEventHandler}
