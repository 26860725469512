<template>
  <div class="page">
    <nav-bar></nav-bar>
    <header-bar
        @open="showModal"
        :user="user"
        :showButton="true"
        :addButtonText="'New Organization'"  />
    <section class="orgs">
        <orgs-card
            v-for="(org, index) in orgs"
            :key="index"
            :company="org" />
    </section>
    <modal
      v-show="isModalVisible">
      <template v-slot:header>
        <h2>Create New Organization</h2>
        <p>Lets get started by filling in the information about the new organization.</p>
        </template>

        <template v-slot:body>
            <div class="input-container">
                <label class="input-container--label">Name of Organization</label>
                <input class="input-container--box" type="text"/>
                <p class="input-container--subtext">**The entered name is processed to match the required format.</p>
            </div>
            <div class="input-container">
                <label class="input-container--label">Summary</label>
                <textarea class="input-container--area"></textarea>
            </div>
        </template>

        <template v-slot:footer>
            <button
                type="submit"
                class="button"
                @click="closeModal">
                Create
            </button>
            <a @click="cancelModal" class="btn-close">Cancel</a>
        </template>
    </modal>
    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import HeaderBar from "@/components/HeaderBar.vue";
import Modal from '@/components/Modal.vue';
import OrgsCard from '@/components/OrgsCard.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {

    components: {
        NavBar,
        OrgsCard,
        HeaderBar,
        Modal,
        FooterBar
    },

    props: {
    },

    data() {
        return {
            user: {
                username: "User Name",
                email: "email@email.com",
                website: "http://www.mywebsite.com",
                orgs: ["Example Corp", "Company INC"],
                bio: "",
                avatar: "https://via.placeholder.com/100x100",
                location: "Ypsilanti, MI"
            },

            orgs: [
                {
                    name: "Compnay Name One LLC",
                    avatar: "https://via.placeholder.com/100x100",
                    projects: [
                        {
                            name: "Project One",
                            date: "04/18/2022",
                            collections: ["one", "two", "three"]
                        },
                        {
                            name: "Project Two",
                            date: "04/18/2022",
                            collections: ["one", "two", "three"]
                        },

                    ],
                },
                {
                    name: "Compnay Name Two CORP",
                    avatar: "https://via.placeholder.com/100x100",
                    projects: []
                },

            ],
            isModalVisible: false,

        }
    },

    watch: {},

    methods: {
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        cancelModal() {
            this.isModalVisible = false;
        },

    },

    computed: {

    },

};

</script>

<style lang="scss">
    .orgs {
        width: 60%;
        margin: 25px auto;
        flex-grow: 1;
    }
</style>
