<template>
    <div @click="view" class="orgs__card">
        <div class="orgs__card__section" >
            <img
                :src="company.avatar"
                :alt="company.name"
                :title="company.name"
                class="avatar"/>
            <h3>{{company.name}}</h3>
        </div>
        <span>{{company.projects.length}} Projects</span>
    </div>

</template>

<script>


export default {

    components: {
        
    },

    props: {
        company: Object,

    },
    data() {
        return {


        }
    },
    watch:{

    },
    methods: {
      view:function(){

      }
    },

  computed: {
  },



};
</script>

<style lang="scss">
    .orgs {
        &__card {
            background: $off-white;
            width: 100%;
            padding: 15px 25px;
            margin: 10px;
            border-radius: 5px;
            border: 1px solid;
            border-color: $border-colors;
            box-shadow: 0 0 10px $grey;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__section {
                display: flex;
                align-items: center;

                &--avatar {
                    border-radius: 50%;
                }

                &--name {
                    font-weight: 600;
                    font-size: 1.2rem;
                    margin-left: 10px;
                }
            }

        }
    }

</style>
