<template>

    <div class="context-modal" :class="{'left-arrow': !arrowRight, 'right-arrow': arrowRight}" :style="{'top': top + 'px', 'left': left + 'px', 'bottom': bottom + 'px', 'right': right + 'px'}">
      <header class="context-modal--header">
      <slot name="header">

      </slot>

      </header>

      <section class="context-modal--body">
        <slot name="body">

        </slot>
       </section>
       <footer class="context-modal--footer">
        <slot name="footer">

        </slot>
      </footer>
    </div>

</template>

<script>
  export default {
    name: 'ContextMenu',
    props: {
        top: null,
        left: null,
        bottom: null,
        right: null,
        arrowRight: false
    },
    data() {
      return {

      }
    },
    methods: {

    },
  };
</script>

<style lang="scss">
.context-modal {
    position: fixed;
    background: $off-white;
    border-radius: 3px;
    box-shadow: 0 0 10px $black;
    width: 150px;
    height: 100px;
    display: flex;
    flex-direction: column;
    z-index: 10;

    &--header {
        padding: 5px;
        text-align: center;
        position: relative;
        color: $white;
        background-color: $primary-color;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        font-weight: 500;
        font-size: .9rem;
        z-index: 20;
        height: 30px;

        .small-text {
          font-size: .7rem;

        }
    }

    &--body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        z-index: 20;
        background: $off-white;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        .context-list-item {
            padding: 5px;
            width: calc(100% - 10px);
            text-align: left;
            display: flex;
            justify-content: space-between;
            font-size: .8rem;

            &:hover {
                background: darken($off-white, 10%);
            }
        }
    }
}

.left-arrow::after {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      top: 8px;
      left: -10px;
      background-color: $primary-color;
      transform: rotate(45deg);    
      box-shadow: 0 0 10px $black;
      z-index: 0;
    }

    .right-arrow::after {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      top: 8px;
      right: -10px;
      background-color: $primary-color;
      transform: rotate(45deg);    
      box-shadow: 0 0 10px $black;
      z-index: 0;

    }

</style>
