<template>
  <div class="page">
    <nav-bar></nav-bar>
    <div class="contact">

        <div class="contact__card">
            <h2>Send Feedback</h2>
            <form @submit.prevent="submitContact" @reset="onReset" class="contact-form">
                <div class="input-container">
                    <label class="input-container--label">Email</label>
                    <input class="input-container--box" type="email" v-model="email" />
                </div>
                <div class="input-container">
                    <label class="input-container--label">Subject</label>
                    <input class="input-container--box" type="text" v-model="subject" />
                </div>
                <div class="input-container">
                    <label class="input-container--label">Message</label>
                    <textarea class="input-container--area" v-model="message"></textarea>
                </div>
                <div class="input-container">
                    <Transition>
                        <p class="success-message" v-show="feedbackSent">
                            Thank you for your feedback. Your message was sent!
                        </p>
                    </Transition>
                    <Transition>
                        <p class="fail-message" v-show="errorSent">
                            Something went wrong. Please try again.
                        </p>
                    </Transition>
                </div>
                <div class="button-container">
                    <button class="button" type="submit">submit</button>

                    <a class="btn-clear" @click="onReset" type="reset">Reset</a>
                </div>
            </form>
        </div>
    </div>
    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import {get, post} from "@/scripts/samsa";

export default {

    components: {
        NavBar,
        FooterBar
    },

    props: {
    },

    data() {
        return {
            message: "",
            email: "",
            subject: "",
            feedbackSent: false,
            errorSent: false
        }
    },

    watch: {},

    methods: {

        async submitContact() {
          let email_sent = await post("feedback", {}, {subject:this.subject, email:this.email, message:this.message});
          console.log(email_sent.status);
          if(email_sent.status == 200) {
            this.feedbackSent = true;
            this.onReset();
          }else {
            this.errorSent = true;
          }
          
        },
        onReset() {
            this.subject = "";
            this.email = "";
            this.message = "";
        },

    },


    computed: {

    },

};

</script>

<style lang="scss">
    .contact {
        padding: 25px 0;
        flex-grow: 1;
        &__card {
            background: $off-white;
            width: 80%;
            margin: 25px auto;
            padding: 2em 5em 2em 4em;
            border-radius: 7px;
            border: 1px solid;
            border-color: $border-colors;
            box-shadow: 0 0 10px $grey;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            @media only screen and (max-width: 767px) {
                width: 92%;
                height: auto;
                padding: 1em;
            }
            @media only screen and (max-width: 415px) {
                width: 90%;
            }

            &__header {
                border-bottom: 1px solid;
                border-color: $border-colors;
            }

            .contact-form {
                width: 60%;
                margin: 0 auto;
                padding: 15px;

                .button-container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .success-message {
                    color: $green;
                    font-weight: 500;
                    font-style: italic;
                }

                .fail-message {
                    color: $error-red;
                    font-weight: 500;
                    font-style: italic;
                }

                .v-enter-active,
                .v-leave-active {
                    transition: opacity 0.5s ease;
                }

                .v-enter-from,
                .v-leave-to {
                    opacity: 0;
                }
            }

        }


    }

</style>
