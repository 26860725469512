<template>
  <div class="page">
    <nav-bar></nav-bar>
    <div class="changelog">
        <div class="changelog__card">
            
            <h2 class="changelog__card__header">Release Notes</h2>

            <div class="changelog__card__container">
                
                <h3>Update Version 1.02</h3>
            
                <ul class="changelog__card__container">
                    <li v-for="item in updatesList" :key="item" class="changelog__card__container--item">
                        <i class="fa-solid fa-circle-check"></i> {{item}}
                    </li>
                </ul>
            </div>
          
        </div>
    </div>
    <footer-bar></footer-bar>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";

export default {

    components: {
        NavBar,
        FooterBar
    },

    props: {
    },

    data() {
        return {
            updatesList: [
                "Site wide search.",
                "Feedback form.",
                "Secondary prediction check.",
                "Project and Organization member management.",
                "Help pop-up"
            ]
            
        }
    },

    watch: {},

    methods: {
       
    },
    

    computed: {

    },

};

</script>

<style lang="scss">
    .changelog {
        padding: 25px 0;
        flex-grow: 1;
        &__card {
            background: $white;
            width: 80%;
            margin: 25px auto;
            padding: 2em 5em 2em 4em;
            border-radius: 7px;
            border: 1px solid;
            border-color: $border-colors;
            box-shadow: 0 0 10px $grey;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            @media only screen and (max-width: 767px) {
                width: 92%;
                height: auto;
                padding: 1em;
            }
            @media only screen and (max-width: 415px) {
                width: 90%;
            }

            &__header {
                border-bottom: 1px solid;
                border-color: $border-colors;
            }

            &__container {
                padding: 25px;
                &--item {
                    margin: 5px 0;
                    i {
                        color: $green;
                    }
                }
                
        
            }

        }


    }

</style>
