<template>
  <div>
    <div v-if="user"  class="navbar">
      <div class="navbar--links">
        <span class="help" @click="open_help"><i class="fa-solid fa-circle-question"></i></span>
        <router-link class="link-button" :to="{path: '/dashboard' }">Dashboard</router-link>
        <router-link class="link-button" :to="{path: '/diagnostics' }">Diagnostics</router-link>
      </div>
      <router-link :to="{path: '/' }"><img class="navbar--logo" src="@/assets/images/logo-full.png"/></router-link>
      <div class="navbar--actions">
        <div class="navbar--actions--search">
          <i class="fa-solid fa-magnifying-glass search-icon"></i>
          <input @keypress="keypress" v-model="search" class="search-bar" placeholder="Search"/>
          <i class="fa-solid fa-xmark clear-icon" @click="clear_search" title="clear search"></i>
        </div>
        <img v-if="user.avatar" :src="user.avatar"
          alt="user name"
          class="user-avatar"
          @click="toggleUserMenu()"
          />
          <img v-else src="@/assets/images/no_image.png"
            alt="user name"
            class="user-avatar"
            @click="toggleUserMenu()"
            />
      </div>
      <div class="navbar--usermenu" v-if="activeMenu">
        <div class="navbar--usermenu__list">
          <router-link class="navbar--usermenu__list__item" :to="{ name: 'Profile', params: {user: ''} }">Profile</router-link>
          <a class="navbar--usermenu__list__item" @click="signout" >Sign Out</a>
        </div>

      </div>
    </div>
    <div v-else class="navbar">
      <div class="navbar--links">
        <router-link :to="{path: '/' }"><img class="navbar--logo" src="@/assets/images/logo-full.png"/></router-link>
      </div>
      <div class="navbar--actions">
        <router-link class="link-button" :to="{ path: '/sign-in' }">Sign In</router-link>
      </div>
    </div>
    <modal
      v-if="isModalVisible == 'signOutConfirm'">
      <template v-slot:header >
          <h2>Success!</h2>
      </template>

      <template v-slot:body>
          <p>You have successfully logged out!</p>
      </template>

      <template v-slot:footer>
          <a @click="closeSignOutModal" class="btn-close">Close</a>
      </template>
    </modal>
    <modal
      v-if="isModalVisible == 'helpModal'"
      :isBig="true">
      <template v-slot:header >
          <h2>Help & Quick Guide</h2>
      </template>

      <template v-slot:body>
        <help-content class="help-modal">

        </help-content>
      </template>

      <template v-slot:footer>
          <a @click="closeModal" class="btn-close">Close</a>
      </template>
    </modal>
  </div>

</template>

<script>
import Modal from "@/components/Modal.vue";
import HelpContent from "@/components/HelpContent.vue";
export default {

  components: {
    Modal,
    HelpContent
  },
  props: {},
  computed:{
    user: function(){
      console.log(state.user)
      return state.user
    },
    state:function(){return state}
  },
  data() {
    return {
      activeMenu: false,
      isModalVisible: "",
      search:""

    };
  },
  methods:{
    keypress:function(e){
      if(e.keyCode==13){
        this.$router.push({"name":"Search",params:{"search_string":this.search}});
        this.search = "";
      }
    },
    signout: function(){
      state.sign_out();
      this.isModalVisible = 'signOutConfirm';
    },
    clear_search() {
      this.search = "";
    },
    closeSignOutModal() {
      this.isModalVisible = "";
      this.$router.push("/");
    },
    open_help() {
      this.isModalVisible = "helpModal";
    },

    closeModal() {
      this.isModalVisible = "";
    },
    toggleUserMenu: function() {
      this.activeMenu = !this.activeMenu;
      window.addEventListener("mousedown", this.closeUserMenu);
    },
    closeUserMenu: function(evt) {

      if(evt.target.className == "navbar--usermenu__list__item" || evt.target.className == "user-avatar") {
        return
      }
      this.activeMenu = false;
      window.removeEventListener("mousedown", this.closeUserMenu);
    }

  },

  mounted() {

  },
};
</script>

<style lang="scss">

.navbar {
  padding: 0 25px;
  align-items: center;
  background-color: $almost-black;
  height: 64px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  &--links{
    display: flex;
    align-items: center;
    
  }

  &--actions {
    display: flex;
    align-items: center;

    &--search {
      position: relative;
      display: flex;
      align-items: center;
      .search-bar {
        width: 24rem;
        height:24px;
        background-color: rgb(17,24,39);
        border-radius: .375rem;
        border: 2px solid rgba(41,51,65);
        padding: 10px;
        color: $white;
        font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
      }

      .search-icon {
        color: $white; 
        font-size: 1.5rem; 
        padding-right: 10px;
      }

      .clear-icon {
        color: $white; 
        font-size: 1.5rem; 
        padding-right: 10px;
        position: absolute;
        right: 2px;

        &:hover {
          cursor: pointer;
          transition: all .2s;
        }
      }
    }

    
    .user-avatar {
      border-radius: 50%;
      margin-left: 10px;
      width: 50px;
      height: 50px;

      &:hover {
        cursor: pointer;
        transition: all .2s;
      }
    }


    @media only screen and (max-width: 767px) {
      width: 40%;
      text-align: right;
    }
  }

  &--usermenu {
    position: absolute;
    top: 3.8em;
    right: 2em;
    background: rgb(31,41,55);
    width: 150px;
    padding: 15px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 3;

    &__list {
      display: flex;
      flex-direction: column;

      &__item {
        text-decoration: none;
        color: $white;
        padding: 10px;
        text-align: left;

        &:hover {
          background-color: rgba(55,65,81);
          cursor: pointer;
          transition: all .2s;
        }
      }
    }

  }

  &--logo{
    height: 32px;
    width: auto;
    margin: 0 5px; 
  }

  .link-button {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    border-radius: .375rem;
    font-size: .95rem;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 12px;
    tab-size: 4;
    text-decoration: none;
    background-color: rgb(17,24,39);
    color: $white;
    margin: 0 5px;

    &:hover {
      background-color: rgba(55,65,81);
      cursor: pointer;
      transition: all .2s;
    }
  }

  .help {
    color: $white;
    font-size: 1.5rem;
    margin-right: 5px;

    &:hover {
      color: $light-grey;
      cursor: pointer;
      transition: all .2s;
    }
  }
}


</style>
