<template>
  <div class="scroll-sidebar">

    <multiselect class="select" v-model="value" valueProp="target_id" mode="single" @search-change="search"
      :options="options" :searchable="true" placeholder="Select Species or Search by Name" label="common_name"
      track-by="common_name">
      <template v-slot:option="{ option }">
        <!-- <img class="character-option-icon" :src="option.icon" /> -->
        <span>{{ option.common_name }}</span>

      </template>

    </multiselect>

    <div class="container" :ref="el => { container_element = el }" draggable="false">

      <div class="scroll_container" :ref="el => { scroll_element = el }" @scroll="scroll">

        <div :style="{ height: height }" class="image_list">
          <div class="image-preview" v-for="item, idx in Array(5)" :style="{ top: top(idx) }" :key="item">
          
            <image-preview @click="select(idx)" :url="url(idx)" :name="get_name(idx)" :realIndex="image_index(idx) + 1" @open_tags="openTags"
              @delete_image="deleteImage" :selectedItem="selected_item">
            </image-preview>
          </div>
        </div>

      </div>
      <svg class="scrollbar" @mousedown="mousedown" width="20px" draggable="false">
        <line x1=9 x2=9 y1=0 :y2="h" stroke="#95cae5" stroke-width=2 draggable="false" />
        <circle cx=12 :cy="y + 3" r=8 stroke-width=2 stroke="#84b9d433" fill="#FFF" draggable="false" />
        <circle cx=9 :cy=y r=8 stroke-width=2 stroke="#95cae5" fill="#FFF" draggable="false" />
      </svg>

      <!--CML- Removing until we can implement properly -->
    </div>

  </div>
</template>

<script>
import ImagePreview from "@/components/ImagePreview";
import Multiselect from '@vueform/multiselect';
import { ref, onMounted, getCurrentInstance } from 'vue';
import { get, post } from "@/scripts/samsa";
import "@vueform/multiselect/themes/default.scss";
import { thisExpression } from "@babel/types";

export default {

  components: { ImagePreview, Multiselect },
  props: { collection_id: undefined, project_id: undefined, target_id: undefined, tag_id: undefined },

  data() {
    return {
      pages: [],
      value: null,
      searching: false,
      scrollTop: 0,
      options: [],
      common_names: [],
      species_dictionary: {},
      number_of_previews: 4,
      view_max: 0,
      view_min: 0,
      height: "0px",
      max_height: 0,
      scroll_element: null,
      container_element: null,
      current_index: null,
      image_list: [],
      real_list_length: 0,
      selected_index: 0,
      selected_item: {},
      mouse_down: false,
      allow_keys: true,
      h: "100%",
      y: 30
    };
  },
  watch: {
    value: function (a, b) {
      let ret = this.species_dictionary[this.value]
      this.$emit("species_selected", ret)
    },
    project_id: function (a, b) {

    }
  },
  computed: {
    url() {
      let self = this;
      let i = this.scrollTop;

      return function (idx) {
        let t = idx
        if (self.scroll_element) {
          let i = Math.floor(self.scrollTop / 210);
          t = idx + i
        }

        if (self.image_list.length > t && self.image_list[t]) {
          return self.image_list[t].url
        }
        else
          return null

      }
    },
    get_name() {
      let self = this;
      let i = this.scrollTop;

      return function (idx) {
        let t = idx
        if (self.scroll_element) {
          let i = Math.floor(self.scrollTop / 210);
          t = idx + i
        }

        if (self.image_list.length > t && self.image_list[t]) {
          return self.image_list[t].file_name
        }
        else
          return null

      }
    },
    top() {
      let i = this.scrollTop;
      let self = this;
      return function (idx) {
        let t = idx
        if (self.scroll_element) {
          let i = Math.floor(self.scrollTop / 210);
          let j = self.scroll_element.scrollHeight / 210
          if (idx == 0) {
          }
          t = idx + i

          if (210 * t >= self.scroll_element.scrollHeight)
            return "0px"
        }

        return (210 * t) + "px";
      }
    },
    image_index(idx) {
      let self = this;
      return function (idx) {
        let t = idx
        if (self.scroll_element) {
          let i = Math.floor(self.scrollTop / 210);
          let j = self.scroll_element.scrollHeight / 210
          t = idx + i
        }

        return t;
      }
    }
  },
  methods: {
    show: function () {
      for (let i in this.options) {
        //console.log(this.options[i].common_name)
      }
    },
    search: async function (value) {
      //Require at least four characters before searching...

      if (value.length >= 4 && !this.searching) {
        this.searching = true;
        let ret = await post('search_species', {}, { name: value })
        if (ret.data.length > 0) {
          for (var idx in ret.data) {
            if (!this.common_names.includes(ret.data[idx].common_name)) {
              this.options.push(ret.data[idx])
              this.species_dictionary[ret.data[idx].target_id] = ret.data[idx]
              this.common_names.push(ret.data[idx].common_name)
            }

          }
        }
        this.searching = false
      }
    },
    select: function (idx) {
      let t = idx
      let i = Math.floor(this.scrollTop / 210);
      t = idx + i
      this.selected_index = t;
      if (this.image_list[t]) {
        this.$emit("selected", this.selected_index, this.image_list[t]);
        this.selected_item = this.image_list[t];
      }
    },

    openTags(image) {
      this.$emit("openTagsModal", image);
    },

    async deleteImage(index) {
      let t = index - 1;
      if (this.image_list[t]) {

        let delete_img = await state.delete_image(this.image_list[t]);
        this.image_list.splice(t, 1);
      }
      this.$emit("selected", this.image_list[t]);
      this.selected_item = this.image_list[t];

      let items_per_page = Math.ceil(this.scroll_element.clientHeight / 210);
      let page = Math.floor(this.scrollTop / (items_per_page * 210))

      let idx = this.pages.indexOf(page)
      this.pages.splice(idx, 1)

      this.scroll()
    },

    keydown: async function (evt) {

      if (evt.srcElement.tagName == "BODY") {
        if (evt.keyCode == 40) {
          evt.preventDefault();
          console.log("NEXT ", this.selected_index, this.real_list_length)
          if (this.selected_index + 1 < this.real_list_length && this.allow_keys) {
            this.allow_keys = false;
            this.selected_index += 1;
            this.selected_item = this.image_list[this.selected_index];
            this.$emit("selected", this.selected_index, this.image_list[this.selected_index]);
            this.scroll_element.scrollTop = this.selected_index * 210;

          }

        } else if (evt.keyCode == 38) {

          evt.preventDefault();
          if (this.selected_index > 0 && this.allow_keys) {
            this.allow_keys = false;
            this.selected_index -= 1;

            // If the bookmark is on a page greater than 0, we need to load the images above before selecting them  
            let items_per_page = Math.ceil(this.scroll_element.clientHeight / 210);
            let page = Math.floor(this.selected_index / items_per_page)
            this.pages.push(page)
            let image_data = await state.getImages(this.collection_id, this.project_id, this.target_id, this.tag_id, page, items_per_page);

            this.real_list_length = image_data.count
            for (let idx = 0; idx < items_per_page; idx++) {
              let i = items_per_page * page + idx
              this.image_list[i] = image_data["images"][idx]
            }

            this.selected_item = this.image_list[this.selected_index];
            this.$emit("selected", this.selected_index, this.image_list[this.selected_index]);
            this.scroll_element.scrollTop = this.selected_index * 210;
          }


        } else if (evt.keyCode == 66) {
          evt.preventDefault();
          this.$emit("blank")
        } else if (evt.keyCode == 67) {
          evt.preventDefault();
          this.$emit("confirm")
        } else if (evt.keyCode == 86) {
          evt.preventDefault();
          this.$emit("validate")
        } else if (evt.keyCode == 68) {
          evt.preventDefault();
          this.$emit("delete")
        } else if (evt.keyCode == 72) {
          evt.preventDefault();
          this.$emit("hotkeysModal");
        } else if (evt.keyCode == 84) {
          evt.preventDefault();
          this.$emit("openTagsModal", this.image_list[this.selected_index]);
        }
      }

    },

    mousedown: function (e) {
      this.mouse_down = true;
      this.mousemove(e)
      e.preventDefault()
      return false;
    },
    mouseup: function (e) {
      this.mouse_down = false;
    },
    mousemove: function (e) {
      if (this.mouse_down) {
        let pct = (e.pageY - 140) / this.container_element.clientHeight

        let scroll_pct = pct
        if (pct > .935) {
          pct = .935
          scroll_pct = 1
        }
        if (pct < .017) {
          pct = .017
          scroll_pct = 0
        }
        this.scroll_element.scrollTop = (this.image_list.length * 210 - this.container_element.clientHeight) * scroll_pct;
        this.y = pct * (this.container_element.clientHeight)
      }
      return;
      if (this.mouse_down && e.buttons == 1) {
        let pct = (e.pageY - 74) / this.container_element.clientHeight
        if (pct < 0)
          pct = 0;
        else if (pct > 1)
          pct = 1
        this.y = e.pageY - 64;
        if (this.y < 10)
          this.y = 5
        else if (this.y > this.scroll_element.clientHeight - 20)
          this.y = this.scroll_element.clientHeight - 20;
        this.scroll_element.scrollTop = this.height * pct;
      }
      else {
        this.mouse_down = false;
      }
    },
    scroll: async function (e) {
      console.log("SCROLL");
      window.clearTimeout(this.update_images)

      // disable scrolling when at the bottom
      if (this.scroll_element.scrollTop > this.max_height - this.container_element.clientHeight) {
        console.log("SCROLLED TO BOTTOM");
        this.scroll_element.scrollTop = this.max_height - this.container_element.clientHeight;
        return
      }

      this.scrollTop = this.scroll_element.scrollTop;

      let self = this;
      async function update_images() {
        console.log("UPDATE IMAGES");
        let items_per_page = Math.ceil(self.scroll_element.clientHeight / 210);
        let page = Math.floor(self.scrollTop / (items_per_page * 210))

        if (self.pages.indexOf(page) < 0) {
          self.pages.push(page)
          let image_data = await state.getImages(self.collection_id, self.project_id, self.target_id, self.tag_id, page, items_per_page)
          for (let idx = 0; idx < items_per_page; idx++) {
            let i = items_per_page * page + idx
            if (image_data["images"][idx] != undefined)
              self.image_list[i] = image_data["images"][idx]
          }
        }

        page = Math.ceil(self.scrollTop / (items_per_page * 210))
        if (self.pages.indexOf(page) < 0) {
          self.pages.push(page)
          let image_data = await state.getImages(self.collection_id, self.project_id, self.target_id, self.tag_id, page, items_per_page)
          for (let idx = 0; idx < items_per_page; idx++) {
            let i = items_per_page * page + idx
            if (image_data["images"][idx] != undefined)
              self.image_list[i] = image_data["images"][idx]
          }
        }

        self.allow_keys = true;
      }

      this.update_images = window.setTimeout(update_images, 100)

      // move scroll bar
      let pct = (this.scroll_element.scrollTop) / (this.image_list.length * 210 - this.container_element.clientHeight)
      if (pct > .935) {
        pct = .935
      }
      if (pct < .017) {
        pct = .017
      }
      this.y = pct * (this.container_element.clientHeight)

      return

    },
    resize: function (e) {

      let client_height = window.innerHeight;
      let image_height = 200 //380 + 10 for margin
      let number_of_previews = Math.ceil(client_height / image_height)
    }

  },
  async mounted() {

    //window.onresize = this.resize
    //window.onmousemove = this.mousemove;
    //let idx = 0;
    //this.h = this.scroll_element.clientHeight - 20 + "px"
    //this.view_min = this.scroll_element.scrollTop;

    window.onkeydown = this.keydown;
    window.onmousemove = this.mousemove;
    window.onmouseup = this.mouseup
    this.update_images = null

    let items_per_page = Math.ceil(this.scroll_element.clientHeight / 210);
    let page = 0;
    this.image_list = []

    // CML -10-27-22 HOLD OFF FOR THE MOMENT...
    let ret = await post('collection_species', {}, { collection_id: this.collection_id, project_id: this.project_id, target_id: this.target_id, tag_id: this.tag_id })
    if (ret.data.length > 0) {
      for (var idx in ret.data) {
        if (!this.common_names.includes(ret.data[idx].common_name)) {
          this.options.push(ret.data[idx])
          this.species_dictionary[ret.data[idx].target_id] = ret.data[idx]
          this.common_names.push(ret.data[idx].common_name)
        }
      }
    }

    
    const currentUrl = window.location.href;

    if (currentUrl.includes("searchresults")) {
      console.log("The current URL contains 'searchresults'");
      let image_data = await state.getImages(this.collection_id, this.project_id,this.target_id,this.tag_id,  0, items_per_page)

      this.real_list_length = image_data.count
      for(let idx = 0; idx<items_per_page; idx++){
        let i = items_per_page*page + idx
        this.image_list[i] = image_data["images"][i]
      }
      this.image_list.length = image_data.count;

      this.height = this.image_list.length*210 + "px"
      this.max_height = this.image_list.length*210
      this.$emit("selected", this.selected_index, this.image_list[0])
      this.selected_item = this.image_list[0];
      this.selected_index = 0;
    } else {
      // Note: there are two API calls to get_bookmark, one here and one in the Image.vue component...
      // there should probably only be one, and that data should be shared between parent/child componets
      let bookmark_index = await get("get_bookmark", { collection_id: this.collection_id });
      bookmark_index = bookmark_index.data;
      this.selected_index = bookmark_index;    

      page = Math.floor(bookmark_index / items_per_page)
      console.log("PAGE:",bookmark_index,items_per_page, page)

      this.pages.push(page)
      let image_data = await state.getImages(this.collection_id, this.project_id, this.target_id, this.tag_id, page, items_per_page);

      this.real_list_length = image_data.count
      for (let idx = 0; idx < items_per_page; idx++) {
        let i = items_per_page * page + idx
        this.image_list[i] = image_data["images"][idx]
      }
      
      let self = this;
      window.setTimeout(function () {
        self.scroll_element.scrollTop = self.selected_index * 210;
        self.scrollTop = self.selected_index * 210;
        self.selected_item = self.image_list[self.selected_index];
        self.$emit("selected", self.selected_index, self.image_list[self.selected_index]);
      
      }, 100)
      
      this.image_list.length = image_data.count;
    }

    this.height = this.image_list.length * 210 + "px";
    this.max_height = this.image_list.length * 210;
  },
};
</script>

<style lang="scss">
.scroll-sidebar {
  background: $white;

  .scrollbar {
    position: absolute;
    left: 370px;
    top: 130px;
  }

  .select {
    margin: 10px;
  }

  svg {
    height: calc(100% - 150px);
    width: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .image-preview {
    position: absolute;
    height: 210px;
    width: 350px;
  }

  .scroll_container {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    pointer-events: all;
  }

  .scroll_container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .container {
    width: 400px;
    background-color: $white;
    height: calc(100% - 61px);
    overflow-y: hidden;

    flex-direction: column;
    align-items: center;
    pointer-events: scroll;
  }

  .image_list {
    margin: 0;
    width: 350px;
    overflow-y: hidden;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
  }

  .image_list::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .multiselect {
    color: $white;
    border-radius: 5px;
    background-color: $dark-grey;
  }

  .multiselect-placeholder,
  .multiselect-caret {
    color: $white;
  }

  a.multiselect-clear:hover::after {
    background-color: $error-red;
  }

  a.multiselect-clear:hover::before {
    background-color: $error-red;
  }

  a.multiselect-clear {
    background-color: $dark-grey !important;
  }
}
</style>
