// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

import Vue from "vue";
import {get, post} from "@/scripts/samsa"
import {State} from "@/scripts/state"

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

State.prototype.create_organization = async function(organization_name) {
  let ret = await post("create_organization",{}, {organization_name:organization_name});

  return ret.data
}

State.prototype.get_organizations = async function() {
  console.log("Getting Organizations")
  console.log(state.token)
  let ret = await post("get_organizations");

  return ret.data
}


State.prototype.create_project = async function(project_name, organization_id) {

  console.log("Creating Project", project_name, organization_id)

  let ret = await post("create_project",{}, {project_name:project_name, organization_id:organization_id});

  console.log("RET", ret.data)
  return ret.data 
}

State.prototype.get_image_processing_status = async function(collection_id) {
  let ret = await post("get_image_processing_status",{},{collection_id:collection_id});

  return ret.data
}

State.prototype.get_project = async function(project_id) {
  let ret = await post("get_project",{},{project_id:project_id});

  return ret.data
}

State.prototype.create_collection = async function(project_id, collection_name, summary){

  if(summary == null)
    summary = ""
  let ret = await post("create_collection",{}, {project_id:project_id, collection_name:collection_name, summary:summary});
  return ret.data
}

State.prototype.delete_collection = async function(collection){
  let ret = await post("delete_collection",collection,{});

  return ret.data
}

State.prototype.delete_image = async function(image){
  let ret = await post("delete_image",image,{});

  return ret.data
}

State.prototype.delete_organization = async function(organization){
  let ret = await post("delete_organization",organization,{});

  return ret.data
}

State.prototype.delete_project = async function(project){
  let ret = await post("delete_project",project,{});

  return ret.data
}

State.prototype.create_tags = async function(image,tag){
  console.log("-------------")
  console.log(JSON.stringify(image))
  let ret = await post("create_tags",image,{tag:tag});
  if(ret.data != null)
    image.image_tags = ret.data.image_tags
  console.log("Create Tags:", image.image_tags)
  return ret.data
}

State.prototype.delete_tags = async function(img, tag){
  let ret = await post("delete_tags",img,{tag:tag});

  return ret.data
}

State.prototype.add_tag_to_collection = async function(collection_id, tag_name){
  let ret = await post("add_tag_to_collection",{}, {collection_id:collection_id, tag_name:tag_name});

  return ret.data
}

State.prototype.delete_collection_tag = async function(tag){
  console.log("script_page", tag)
  let ret = await post("delete_collection_tag", tag, {});

  return ret.data
}

State.prototype.get_tags = async function(collection_id){
  let ret = await post("get_tags",{},{collection_id:collection_id});

  return ret.data
}

State.prototype.update_collection_name = async function(new_name, collection){
  let ret = await post("update_collection_name", collection, {new_name:new_name});

  return ret.data
}

State.prototype.update_collection_summary = async function(new_summary, collection) {
  let ret = await post("update_collection_summary", collection, {new_summary:new_summary})

  return ret.data
}

State.prototype.update_project_name = async function(new_name, project) {
  console.log("UPDATE: ", project.project_id)
  let ret = await post("update_project_name", {}, {new_name:new_name,project_id:project.project_id})

  return ret.data
}

State.prototype.get_user = async function(user_id) {
  console.log("getting user: ", user_id)
  let ret = await get("get_user", {username:user_id} )

  return ret.data

}

State.prototype.update_user = async function(user_data, user_id) {
  let ret = await post("update_user", user_data, {} )

  return ret.data

}
