function dummy(args) {}

const APIPREFIX = process.env.VUE_APP_APIPREFIX;
console.log("APIPREFIX: ", APIPREFIX)
axios.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('springmatter-token');
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});

function get(url, params)
{
  url = APIPREFIX+`/${url}`;
  return axios.get(url,{ headers:{auth:window.token}, // important}, {
    params:params});
}

function get_blob(url, params)
{
    url = APIPREFIX+`/${url}`;
    return axios.get(url,{responseType: 'blob', headers:{auth:window.token},
    params:params});
}


function post(url, body={}, params={}){
  if(params == undefined)
    return axios.post(APIPREFIX+`/${url}`,body, {headers:{auth:window.token}});
  else {
    return axios.post(APIPREFIX+`/${url}`,body, {params:params, headers:{auth:window.token}});
  }
}

export {get, get_blob, post};
