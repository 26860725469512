<template>

    <section class="profile">
        <div class="profile--heading">
            <section class="profile__form__container">
                <img v-if="user.avatar" :src="user.avatar"
                    :alt="user.username"
                    :title="user.username"
                    class="profile__form__container--img" />
                  <img v-else src="@/assets/images/no_image.png"
                    alt="user name"
                      class="profile__form__container--img" style="object-fit:cover"
                    />
            </section>
            <section class="profile__form__contact">
                <div class="profile-display">
                    <label class="profile-display--label"><i class="fa-solid fa-user"></i> Username</label>
                    <p class="indent">
                        {{user.username}}
                    </p>
                </div>
                <div class="profile-display">
                    <label class="profile-display--label"><i class="fa-solid fa-envelope"></i> Email</label>
                    <a class="indent" href="mailto:{{user.email}}">{{user.email}}</a>
                </div>
                <div class="profile-display">
                    <label class="profile-display--label"><i class="fa-solid fa-phone"></i> Phone</label>
                    <p class="indent">
                        {{user.phone}}
                    </p>
                </div>
            </section>
        </div>
        <div class="profile--body">
            <section class="profile--body__section profile-display-border">
                <div class="profile-display">
                    <label class="profile-display--label"> <i class="fa-solid fa-location-dot"></i> Location</label>
                    <p class="indent">
                        {{user.location}}
                    </p>
                </div>
                <div class="profile-display">
                    <label class="profile-display--label"><i class="fa-solid fa-link"></i> Website</label>
                    <a class="indent" :href="user.website">{{user.website}}</a>
                </div>
            </section>
            <section class="profile--body__section">
                <div class="profile-display">
                    <label class="profile-display--label"><i class="fa-solid fa-briefcase"></i> Employer</label>
                    <p class="indent">
                        {{user.employer}}
                    </p>
                </div>
                <div class="profile-display">
                    <label class="profile-display--label"><i class="fa-solid fa-star"></i> Interests (studies, species etc..)</label>
                    <p class="indent">
                        {{user.interests}}
                    </p>
                </div>
            </section>
        </div>
        <div class="profile--body-single">
            <div class="profile-display">
                <label class="profile-display--label"><i class="fa-solid fa-building"></i> Organizations</label>
                <ul class="org-list">
                    <li v-for="(org, index) in user.orgs" :key="index">
                        <img src="https://via.placeholder.com/100x100" :alt="org.name" class="org-list--avatar" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="profile--body-single">
            <div class="profile-display">
                <label class="profile-display--label"><i class="fa-solid fa-file-pen"></i> Bio</label>
                <p class="indent">
                    {{user.bio}}
                </p>
            </div>
        </div>
    </section>

</template>

<script>

export default {

    components: {

    },

    props: {
       user: Object
    },

    data() {
        return {

        }
    },

    watch: {},

    methods: {


    },

    computed: {

    },

};

</script>

<style lang="scss">

</style>
